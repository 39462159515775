<template>
  <div class="container">
    <ActionsMenu />
    <div class="row" v-if="offer !== undefined">
      <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ $t("strOfferNo") }} {{ offer.id }}
        </div>
        <div>
          <VButton
            v-if="offer.idOrderStatus < 4"
            class="btn-green"
            icon="+"
            @click="addNewVariant"
            strKey="strAddVariant"
            roleKey="offerAddVariant"
          />
        </div>
      </div>
      <div class="col-12 offer__content">
        <div class="row">
          <div class="col-md-11">
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="nameOrder">{{
                $t("strOfferName")
              }}</label>
              <InputText
                :disabled="isEditing === false"
                id="nameOrder"
                class="offer--input"
                type="text"
                v-model="offer.nameOrder"
                @keyup="validName"
                maxlength="40"
              />
              <div class="invalid-feedback">
                <span v-if="errorName" style="font-size:1rem;">{{
                  $t("strOfferNameValid")
                }}</span>
              </div>
            </div>
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="dateOfCreation">{{
                $t("strOfferDate")
              }}</label>
              <InputText
                disabled="true"
                id="dateOfCreation"
                class="offer--input"
                type="text"
                :value="offer.dateOfCreation | formatDate"
              />
              <div class="invalid-feedback">
                <span v-if="errorName" style="font-size:1rem;opacity:0">O</span>
              </div>
            </div>
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="dateOfDelivery">{{
                $t("strOfferDeliveryDate")
              }}</label>
              <InputText
                disabled="true"
                id="dateOfDelivery"
                class="offer--input"
                :value="offer.dateOfDelivery | formatDate"
                formatDate="dd.mm.yy"
                :minDate="currentDay"
              />
              <div class="invalid-feedback">
                <span v-if="errorName" style="font-size:1rem;opacity:0">O</span>
              </div>
            </div>
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="trader">{{
                $t("strTrader")
              }}</label>
              <InputText
                :disabled="isEditing === false"
                id="trader"
                class="offer--input"
                type="text"
                v-model="offer.trader"
              />
              <div class="invalid-feedback">
                <span v-if="errorName" style="font-size:1rem;opacity:0">O</span>
              </div>
            </div>
            <div class="col-md-3 my-2" v-if="!isEditing">
              <label class="offer--input-label" for="idCustomer">{{
                $t("strCustomer")
              }}</label>
              <Dropdown
                :disabled="isEditing === false"
                id="idCustomer"
                class="offer--input"
                v-model="offer.idCustomer"
                :options="customers"
                optionLabel="alias"
                optionValue="id"
              />
            </div>
            <div class="col-md-6 my-2" v-if="isEditing">
              <div class="d-flex">
                <div style="width: 75%">
                  <label class="offer--input-label" for="idCustomer">{{
                    $t("strCustomer")
                  }}</label>
                  <Dropdown
                    :disabled="isEditing === false"
                    id="idCustomer"
                    class="offer--input"
                    v-model="offer.idCustomer"
                    :options="customers"
                    optionLabel="alias"
                    optionValue="id"
                  />
                </div>
                <div class="d-flex to-bottom ml-2">
                  <VButton
                    v-if="isEditing === true"
                    class="btn-outline-red"
                    style="height: 36px; min-width: 70px"
                    @click="showAddNewCustomer"
                    icon="+"
                    strKey="strAdd"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="idOrderStatus">{{
                $t("strOrderStatus")
              }}</label>
              <Dropdown
                :disabled="isEditing === false || offer.isEditable !== 1"
                id="idOrderStatus"
                class="offer--input"
                optionDisabled="disabled"
                v-model="offer.idOrderStatus"
                :options="statusses"
                optionLabel="nameStatus"
                optionValue="id"
              />
            </div>
            <div class="col-md-3 my-2">
              <label class="offer--input-label" for="promoCode">{{
                $t("strPromoCode")
              }}</label>
              <InputText
                :disabled="isEditing === false"
                id="promoCode"
                class="offer--input"
                type="text"
                v-model="offer.promoCode"
              />
            </div>
          </div>
          <div class="col-md-1">
            <div style="position: absolute; right: 10px; z-index: 99">
              <img
                v-if="
                  isEditing === false &&
                    Number(offer.idOrderStatus < 4) &&
                    kindRole > 0
                "
                src="@/assets/icons/vetrex-ikony/trash_outlined_32.svg"
                alt="delete"
                style="cursor: pointer; margin-right: 1.75rem; width: 22px"
                @click="displayDeleteDialog = true"
              />
              <VRole roleKey="offerEdit">
                <img
                  v-if="isEditing === false"
                  src="@/assets/icons/edycja.svg"
                  alt=""
                  style="cursor: pointer; width: 22px"
                  @click="showEdit"
                />
              </VRole>
              <img
                v-if="isEditing === true"
                src="@/assets/icons/vetrex-ikony/save_outlined_32.svg"
                alt=""
                style="cursor: pointer; width: 22px"
                @click="saveOffer"
              />
            </div>
          </div>
          <div
            class="col-md-12 offer-footer toRight d-flex mt-2"
            v-if="isEditing"
          >
            <VButton
              class="btn-outline-red"
              @click="closeEdit"
              strKey="strCancel"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Variant
        v-for="variant in variants"
        :key="variant.idVariant"
        :variantOrg="variant"
        :active="variant.idVariant === 3"
        :canEdit="offer.idOrderStatus < 4"
        :idOrderStatus="offer.idOrderStatus"
      >
      </Variant>
    </div>

    <Dialog
      header="Nowy klient"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayNewCustomer"
      :style="{ width: '70%' }"
      :modal="true"
      @show="newCustomerOpened"
    >
      <div class="row">
        <!-- <h3>customer</h3> -->
      </div>
      <customer-edit
        ref="customerEdit"
        :closeAddNewCustomer="closeAddNewCustomer"
      ></customer-edit>
    </Dialog>

    <Dialog
      header="Nowy wariant"
      :visible.sync="displayNewVariant"
      :style="{ width: '70%' }"
      :modal="true"
      @show="newVariantOpened"
    >
      <variant-new ref="variantNew"></variant-new>
    </Dialog>

    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("qnOfferDelete") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />
        <VButton
          class="btn-outline-red"
          @click="deleteOffer"
          strKey="strDelete"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
import { customerService } from "../../services/customer.service.js";
import { dictionariesService } from "../../services/dictionaries.service.js";
import ActionsMenu from "@/components/ActionsMenu";
import Variant from "@/components/offers/Variant";
import CustomerEdit from "@/components/customers/CustomerEdit";
import VariantNew from "@/components/offers/VariantNew";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";
import { mapState } from "vuex";

export default {
  name: "OfferEdit",
  props: {
    editMode: Boolean,
  },
  computed: {
    ...mapState(["kindRole"]),
  },
  data() {
    return {
      orderId: "",
      response: {},
      offer: {},
      customers: [],
      statusses: [],
      variants: [],
      displayNewCustomer: false,
      displayNewVariant: false,
      isEditing: false,
      currentDay: new Date(),
      displayDeleteDialog: false,
      errorName: false,
    };
  },
  methods: {
    loadCustomers: function() {
      const thisVue = this;
      customerService.getCustomers().then((response) => {
        // console.log('customers:');
        // console.log(response);
        thisVue.customers = response.data;
        //console.log(thisVue.customers);
      });
    },
    loadStatusses() {
      const thisVue = this;
      dictionariesService.getOrderStatusses().then((response) => {
        thisVue.statusses = response.data;
        thisVue.statusses.forEach((i) => (i.disabled = i.ofrZlec >= 0));
      });
    },
    customerAdded(customer) {
      this.displayNewCustomer = false;
      this.customers.push(customer);
      this.offer.idCustomer = customer.id;
    },
    showAddNewCustomer() {
      this.displayNewCustomer = true;
    },
    closeAddNewCustomer() {
      this.displayNewCustomer = false;
    },
    newCustomerOpened() {
      this.$refs.customerEdit.addNewCustomer();
    },
    showEdit() {
      this.isEditing = true;
    },
    closeEdit() {
      this.isEditing = false;
      this.errorName = false;
    },
    validName() {
      this.errorName = this.offer.nameOrder.length > 40;
      return !this.errorName;
    },
    saveOffer() {
      if (!this.validName) return;
      orderService.updateOrder(this.offer).then((response) => {
        console.log(response);
        this.isEditing = false;
      });
    },
    addNewVariant() {
      orderService.addVariant(this.offer.id).then((response) => {
        if (response.isSuccess && response.statusCode < 300) {
          this.variants.push(response.data);
          this.getVariants();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
      });
    },

    variantAdded(variant) {
      console.log(variant);
      //this.variants.push(variant);
      this.getVariants();
    },
    variantDeleted(idVariant) {
      let index = this.variants.findIndex((i) => i.idVariant === idVariant);
      if (index > -1) {
        this.variants.splice(index, 1);
      }
    },
    variantUpdated(variant) {
      let index = this.variants.findIndex(
        (i) => i.idVariant === variant.idVariant
      );
      this.variants.splice(index, 1, variant);
      this.$toast.add({
        severity: "success",
        summary: this.$t("strOffer"),
        detail: this.$t("strVariantUpdated"),
        life: 3000,
      });
    },
    showAddNewVariant() {
      this.displayNewVariant = true;
    },
    newVariantOpened() {
      this.$refs.variantNew.addNewVariant(this.offer);
    },
    getVariants() {
      orderService.getOrderVariants(this.orderId).then((response) => {
        this.variants = response.data || [];
      });
    },
    refreshVariants() {
      orderService.getOrderVariants(this.orderId).then((response) => {
        this.variants = response.data || [];
      });
    },
    loadOrder() {
      var thisVue = this;
      orderService.getOrder(this.orderId).then((response) => {
        thisVue.response = response;
        thisVue.offer = response.data;
        orderService.getOrderVariants(thisVue.orderId).then((response) => {
          thisVue.variants = response.data || [];
        });
      });
    },
    deleteOffer() {
      const thisVue = this;
      orderService.deleteOrder(this.orderId).then((response) => {
        if (response.isSuccess === true) {
          thisVue.displayDeleteDialog = false;
          thisVue.$router.push(`/`);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
      });
    },
  },
  mounted: function mounted() {
    this.orderId = this.$route.params.offerId;
    if (this.$route.query.editMode) {
      this.isEditing = true;
    }

    this.loadOrder();

    //  this.offer = { id : 1};
    //  thisVue.variants = [ { idVariant : 3}, {idVariant : 4}];

    this.loadCustomers();
    this.loadStatusses();
    //  return;
  },
  components: {
    Variant,
    ActionsMenu,
    CustomerEdit,
    VariantNew,
    VButton,
    VRole
  },
};
</script>

<style>
.p-button {
  margin: 0 0.5rem 0 0;
  min-width: 10rem;
}

p {
  margin: 0;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-dialog .p-button {
  min-width: 6rem;
}
</style>
