<template>
  <button v-if="display" :id="id ? id : ''" class="btn" v-bind="{ ...$attrs }" v-on="$listeners">
    <span v-if="icon">{{ icon }} </span>{{ $t(strKey) }}
  </button>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VButton",
  props: {
    strKey: String,
    roleKey: String,
    icon: String,
    id: String
  },
  data() {
    return {
      display: true,
    };
  },
  computed: {
    ...mapState(["roles"]),
  },
  mounted() {
    this.checkRole();
  },
  methods: {
    checkRole: function() {
      if (this.roles) {
        let role = this.roles.find((e) => e.key === this.roleKey);
        if (role) {
          if (role.value === 1) this.display = true;
          else this.display = false;
        }
      }
    },
  },
  watch: {
    roles: function() {
      this.checkRole();
    },
  },
};
</script>
