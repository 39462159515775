<template>
  <div class="col-xs-12" style="margin-top:20px;padding-right:7%">
    <div class="col-md-1"></div>
    <div
      class="col-md-11"
      style="box-shadow: 0px 3px 13px #00000022;min-height:175px;display:flex;align-items:center"
    >
      <div style="display:flex;width:100%">
        <div style="width:140px">
          <img
            :id="'ExtraProductsItem_' + accessory.id"
            :src="accessory.graphicURL"
            alt=""
            style="width:100%;height:140px;"
            @click="toggleImg($event, accessory.id)"
          />
          <OverlayPanel
            :ref="'accesoryItem_' + accessory.id"
            :appendTo="'#ExtraProductsItem_' + accessory.id"
            :showCloseIcon="true"
            id="overlay_panel"
            style="width: 350px; height: auto;background: #fff;z-index: 1000;"
          >
            <img
              :src="accessory.graphicURL + '?' + Date.now()"
              style="width: 100%; height: auto"
            />
          </OverlayPanel>
        </div>
        <div
          style="margin-left:19px;flex:1;display: flex;flex-flow: column;justify-content: space-between;"
        >
          <div>
            <div class="col-md-11;padding-left:0">
              <div>
                <span style="font-size:12px;font-weight:bold">{{
                  accessory.nameAccessory
                }}</span>
                <div style="margin-top:9px">{{ accessory.description }}</div>
              </div>
            </div>
            <!-- <div class="col-xs-6">
                             <span style="font-size:16px;font-weight:bold"></span>
                        </div> -->
          </div>
          <div class="row">
            <div
              class="col-xs-6"
              style="height:100%;display:flex;justify-content:flex-end;flex-flow:column"
            >
              <div v-if="isCountable">
                <div style="display:flex;flex-flow: column">
                  <label style="font-size: 11px;font-weight: 500;">{{
                    $t("strSize")
                  }}</label>
                  <input
                    type="number"
                    class="offer--input"
                    style="width:95px;padding:.5rem .5rem;font-size:12px"
                    :value="accessory.size"
                    disabled
                  />
                </div>
                <!-- <div style="background:#212429;border-radius:4px;color:#fff;font-size:16px;text-align:center;height:31px;line-height:31px;width:95px;margin-top:.5rem;">
                                    {{ accessory.priceNet}}
                                </div> -->
              </div>
            </div>
            <div
              class="col-xs-6"
              style="height:100%;display:flex;justify-content:flex-end;flex-flow:column;text-align:right"
            >
              <div>
                <div>
                  <label
                    style="font-size: 11px;font-weight: 500;width: 95px;text-align: left;"
                    >{{ $t("strAccessoryQuantity") }}</label
                  >
                  <input
                    type="number"
                    class="offer--input"
                    style="width:95px;padding:.5rem .5rem;font-size:12px"
                    :value="accessory.amount"
                    disabled
                  />
                </div>
                <VButton
                  style="border: 2px solid #212429;border-radius: 4px;background:#fff;font-size:16px;width:95px;margin-top:.5rem"
                  @click="displayDeleteDialog = true"
                  strKey="strDelete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t("strAccessoryDelete") }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />
        <VButton
          class="btn-outline-red"
          @click="deleteAccessory"
          strKey="strDelete"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
import VButton from "../global/VButton.vue";

export default {
  name: "ExtraProductsItem",
  props: {
    accessory: {},
  },
  data() {
    return {
      displayDeleteDialog: false,
    };
  },
  computed: {
    isCountable() {
      return this.$parent.checkCountable(this.accessory.idElement) === 0;
    },
  },
  methods: {
    deleteAccessory() {
      // if(confirm(this.$t('strAccessoryDelete'))){
      const id = this.accessory.id;
      orderService
        .deleteAccessory(id)
        .then(() => {
          this.$toast.add({
            severity: "success",
            summary: this.$t("strAccessories"),
            detail: this.$t("strAccessoryDeleted"),
            life: 3000,
          });
          this.$emit("accessoryDeleted", { id: id });
        })
        .catch((response) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strAccessories"),
            detail: response.statusMessage,
          });
        });
      this.displayDeleteDialog = false;
      // }
    },
    toggleImg(event, id) {
      const el = this.$refs["accesoryItem_" + id];
      el.toggle(event);
    },
    // check() {
    //   const e = this.$parent.checkCountable(this.accessory.idElement);
    //   console.log(e);
    // },
  },
  components: {
    VButton,
  },
};
</script>

<style></style>
