<template>
  <div id="app">
    <div
      id="vetrexLoader"
      class="vetrex-loader-container"
      style="z-index: 1000;width:100vw;height:100vh;display:none"
    >
      <div class="vetrex-loader"></div>
    </div>
    <Toast
      position="bottom-right"
      style="right:1rem;bottom:1rem;font-size:1.5rem;width: 400px;"
    />
    <Menu :languageChanged="languageChanged" />
    <router-view></router-view>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="logOutDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("strLogOutMessage") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: end;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <button
          class="btn btn-outline-red"
          @click="
            logOutDialog = false;
            toMyAccount();
          "
        >
          {{ $t("strOK") }}
        </button>
      </div>
    </Dialog>
    <Footer />
  </div>
</template>

<script>
import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";

import "bootstrap/dist/css/bootstrap.min.css";
import "primevue/resources/primevue.min.css";
import "@/assets/theme.css";
import "@/assets/primeicons.css";
import "@/css/style.css";

import { userService } from "./services/user.service.js";
import { translationsService } from "./services/translations.service.js";

export default {
  name: "App",
  components: {
    Menu,
    Footer,
  },
  data() {
    return {
      currentLang: "",
      loadedLang: false,
      isInactive: false,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null,
      logOutDialog: false,
      logLastTime: null,
    };
  },
  beforeMount() {
    this.activateActivityTracker();
    this.getRolesList();
  },
  mounted: function() {
    const thisVue = thisVue;
    //userService.setLogin("s.patyk@aden.pl");
    //this.$i18n.missing = this.handleMissingTranslation;
    const session = userService.getSession();
    if (session?.lang?.length === 2) {
      this.loadTranslations(session.lang);
    }
    this.getRolesList();
    this.$store.commit("setKindRole", +session.kindRole);
  },
  methods: {
    getRolesList() {
      userService.getRole().then((response) => {
        this.$store.commit("setRoles", response.data);
      });
    },
    loadTranslations(langId) {
      const thisVue = this;
      translationsService.getTranslations(langId).then((items) => {
        thisVue.currentLang = langId;
        let obj = {};
        items.forEach((item) => {
          obj[item.key] = item.value;
        });
        thisVue.$i18n.setLocaleMessage(langId, obj);
        thisVue.$i18n.locale = langId;
        thisVue.loadedLang = true;
      });
    },
    // handleMissingTranslation(locale, key, vm, values) {
    //   vm.IsRevoked = true;
    //   if (this.loadedLang === false) return;
    //   //console.log('missing:');
    //   //console.log(vm);
    //   //console.log(values);

    //   //this.$i18n.messages[locale][key] = 'test';
    //   //console.log(this.$i18n.messages[locale]);
    //   // console.log(
    //   //   `missing locale: ${locale}, key: ${key}, vm: ${vm}, values: ${values}`
    //   // );
    //   //this.$i18n.setLocaleMessage(this.currentLang, { strOrderId: 'test'});
    // },
    languageChanged(newLang) {
      this.currentLang = newLang.value;
      this.$store.commit("setLang", newLang.value);
      userService.setLang(newLang.value);
      //this.$i18n.locale = this.currentLang;
      this.loadTranslations(newLang.value);
    },
    activateActivityTracker() {
      window.addEventListener("mousemove", this.resetUserActivityTimeout);
      window.addEventListener("scroll", this.resetUserActivityTimeout);
      window.addEventListener("keydown", this.resetUserActivityTimeout);
      window.addEventListener("resize", this.resetUserActivityTimeout);
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout);
      clearTimeout(this.logLastTime);
      this.logLastTime = setTimeout(() => {
        console.log("last activity", new Date().toUTCString());
      }, 900);
      this.userActivityTimeout = setTimeout(() => {
        this.inactiveUserAction();
      }, 15 * 60 * 1000);
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout();
          clearTimeout(this.userActivityThrottlerTimeout);
          this.userActivityThrottlerTimeout = null;
        }, 15 * 60 * 1000);
      }
    },
    inactiveUserAction() {
      this.logOut();
    },
    toMyAccount() {
      window.location.href = "https://portal.vetrex.eu/my-account/";
    },
    logOut() {
      let thisVue = this;
      userService.logOut().then((response) => {
        if (response.isSuccess) {
          thisVue.logOutDialog = true;
          //thisVue.toMyAccount();
        } else
          this.$toast.add({
            severity: "error",
            summary: response.statusMessage,
            detail: "",
          });
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.userActivityThrottler);
    window.removeEventListener("scroll", this.userActivityThrottler);
    window.removeEventListener("keydown", this.userActivityThrottler);
    window.removeEventListener("resize", this.userActivityThrottler);

    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
};
</script>
