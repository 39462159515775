import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const productSlidersService = {
    getSlidersList,
    getItems,
    getSliderRequest,
    canSliderItemBeChanged,
    getSliderFittingGroups,
    getInterframesList,
    getSliderGlassGroups,
    getSliderGlassKinds,
    getSliderDependencies,
    getSashpostList,
};

function getItems(name, request, kindItem, idSash=0) {
    if(name === 'rollerShutter')
        return getRollersList()
    if (name.toLowerCase() === 'handle'){
        name = name + kindItem;
        if(!Object.hasOwn(request, 'isHandleA')){
            request.isHandleA = 0
        }
    }
    return new Promise((resolve) => {
        getSession().then(async session => {
            request.guid = session.guid;
            request.language = session.lang;
            request.idSash = idSash;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(request)
            };
            return fetch(getAPIUrl(getSliderMethodName(name)), requestOptions)
                .then(handleResponse)
                .then(response => {
                    // if(name === 'Service'){
                    //     response.data.push({
                    //         id: 13,
                    //         name: 'PRZYKRĘCENIE LISTW PODPARAPETOWYCH'
                    //     })
                    // }
                    resolve(response);
                });
        });
    });
}

function getSashpostList(idTech){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, idType: 0, idTech })
            };
            return fetch(getAPIUrl('get_sashpostlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
} 

function getRollersList(){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
            return fetch(getAPIUrl('get_rollerkindlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSliderGlassKinds() {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
            return fetch(getAPIUrl('get_glasskind'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getInterframesList(idTechnology) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, idTechnology: idTechnology })
            };
            return fetch(getAPIUrl('get_interframelist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSlidersList(idTechnology) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idTechnology: idTechnology, language: session.lang })
            };

            return fetch(getAPIUrl('get_sldrmainlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSliderFittingGroups(idTechnology, idPosition, idSash, idFitting) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idTechnology, idPosition, idSash, idFitting, language: session.lang })
            };

            return fetch(getAPIUrl('get_sldrfittinggroup'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSliderGlassGroups() {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };

            return fetch(getAPIUrl('get_sldrglassgroup'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}



function getSliderMethodName(name) {
    switch (name.toLowerCase()) {
        case 'colorin': return `get_sldrcolorin`;
        case 'colorex': return `get_sldrcolorex`;
        case 'colorc': return `get_sldrcolorcore`;
        case 'sashposts': return 'get_sldrsashpost';
        case 'sashpost': return 'get_sldrsashpost';
        default:
            return `get_sldr${name}list`;
    }
}


function canSliderItemBeChanged(name, basket, zone, kindItem) {

    //alert(name);
    switch (name.toLowerCase()) {
        case 'glass':
        case 'handle':
        case 'cylinder':
        case 'airinlet':
        case 'cap':
        case 'wing':
        case 'glassdoor':
            if (zone === '' || zone === 'Z0') {
                return 'errSliderZoneNotSelected';
            }
            break;
        default:
            break;
    }

    if (kindItem === "B") {
        name = name + "B";
    }

    switch (name.toLowerCase()) {
        //case 'ColorIn': return 'strTest';
        case 'colorex': return basket.idColorI > 0 ? '' : 'errSliderColorInNotSelected';
        case 'colorc': return basket.idColorI > 0 ? (basket.idColorEx > 0 ? '' : 'errSliderColorExNotSelected') : 'errSliderColorInNotSelected';
        case 'cylinder': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Handle')?.id ?? 0) > 0 ? '' : 'errSliderHandleNotSelected';
        case 'handle': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Fitting')?.id ?? 0) > 0 ? '' : 'errSliderFittingNotSelected';
        case 'handlea': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Fitting')?.id ?? 0) > 0 ? '' : 'errSliderFittingNotSelected';
        case 'handleb': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Fitting')?.id ?? 0) > 0 ? (
            (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Handle' && i.kindItem === "A")?.id ?? 0) > 0 ? '' : 'errSliderHandleANotSelected'
        ) : 'errSliderFittingNotSelected';
        case 'cap': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Fitting')?.id ?? 0) > 0 ? '' : 'errSliderFittingNotSelected';
        case 'wing': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Sash')?.id ?? 0) > 0 ? '' : 'errSliderSashNotSelected';
        case 'glassdoor': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Sash')?.id ?? 0) > 0 ? '' : 'errSliderSashNotSelected';
        case 'decor': return (basket.zone?.find(i => i.zoneName === zone)?.zoneItems?.find(i => i.itemName === 'Sash')?.id ?? 0) > 0 ? '' : 'errSliderSashNotSelected';
        default:
            return '';
    }
}

function getSliderRequest(name, basket, idTechnology, zone, kindItem) {
    let request = { idTechnology: idTechnology };

    switch (name.toLowerCase()) {
        case 'colorex':
            request.idColorIn = basket.idColorI;
            break;
        case 'colorc':
            request.idColorIn = basket.idColorI;
            request.idColorEx = basket.idColorEx;
            break;
        case 'gasket':
            request.idColorIn = basket.idColorI;
            request.idColorEx = basket.idColorEx;
            request.idColorCore = basket.idColorC;
            break;
        case 'glass':
            request.idCount = 3;
            break;
        case 'cylinder':
            request.idHandle = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Handle' && i.kindItem === 'A').id;
            break;
        case 'cylinderb':
            var cid = 0;
            if( basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Handle' && i.kindItem === 'B'))
                cid = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Handle' && i.kindItem === 'B').id;
            request.idHandle = cid;
            break;
        case 'handle':
            if (kindItem === "B") {
                request.idHandleA = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Handle' && i.kindItem === "A").id;
            }
            request.idFitting = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Fitting').id;
            break;
        case 'cap':
            request.idFitting = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Fitting').id;
            break;
        case 'wing':
            request.idDoor = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Sash').id;
            break;
        case 'glassdoor':
            request.idDoor = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Sash').id;
            break;
        case 'decor':
            request.idDoor = basket.zone.find(i => i.zoneName === zone).zoneItems.find(i => i.itemName === 'Sash').id;
            break;
        default:
            break;
    }

    //, idFitting: 260};
    return request;
}

function getSliderDependencies(name) {
    switch (name.toLowerCase()) {
        case 'colorin': return ['colorex', 'colorc', 'gasket'];
        case 'colorex': return ['colorc', 'gasket'];
        case 'colorc': return ['gasket'];
        // case 'fitting': return ['handle', 'cap'];
        // case 'handle': return ['cylinder'];
        // case 'sash': return ['wind', 'glassdoor', 'decor', 'fitting'];
        default: return [];
    }
}






