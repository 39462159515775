<template>
    <div class="container">
        <div class="row" style="margin-top:68px">
            <configurator-item v-for="item in technologies" :key="item.id" :item="item" :level=1 :selected="selected1.id === item.id" :notSelected="Object.keys(selected1).length === 0"></configurator-item>
        </div>
        <div class="row" v-if="level > 1 && selected1 !== undefined && selected1.technology !== undefined">
            <div v-if="showPrev()" style="position: absolute; left: 2%">
                <img
                    src="@/assets/left-arrow.svg"
                    alt="prev"
                    @click="prevPage"
                    :style="arrowCenter" 
                />
            </div>
            <div v-if="showNext()" style="position: absolute; right: 2%">
                <img
                    src="@/assets/right-arrow.svg"
                    alt="next"
                    @click="nextPage"
                    :style="arrowCenter"
                />
            </div>
            <configurator-item  v-for="item in itemsToDisplay" :key="item.id" :item="item" :level=2 :selected="selected2.id === item.id" :notSelected="Object.keys(selected2).length === 0"></configurator-item>
        </div>
        <div class="row" v-if="level > 2">
            <div v-if="showPrev3()" style="position: absolute; left: 2%">
                <img
                    src="@/assets/left-arrow.svg"
                    alt="prev"
                    @click="prevPageP3"
                    :style="arrowCenter"
                />
            </div>
            <div v-if="showNext3()" style="position: absolute; right: 2%">
                <img
                    src="@/assets/right-arrow.svg"
                    alt="next"
                    @click="nextPageP3"
                    :style="arrowCenter"
                />
            </div>
            <configurator-item v-for="item in itemsToDisplay3" :key="item.id" :item="item" :level=3 :selected="selected3.id === item.id" :notSelected="Object.keys(selected3).length === 0"></configurator-item>
        </div>
        <div class="row" v-if="level > 3">
            <!-- <configurator-item v-for="item in selected3.ssopen" :key="item.id" :item="item" :level=4 :selected="selected4 === item.id"></configurator-item> -->
            <configurator-slider :data="selected3.ssopen"></configurator-slider>
        </div>
    </div>
</template>

<script>
import ConfiguratorItem from './ConfiguratorItem.vue';
import ConfiguratorSlider from './ConfiguratorSlider.vue';
import { configuratorService } from '../../services/configurator.service.js';

export default {
    name: "Configurator",
    data() {
        return {
            technologies: [],
            data: [],
            level: 1,
            selected1: {},
            selected2: {},
            selected3: {},
            page:1,
            page3:1,
            itemsToDisplay: [],
            itemsToDisplay3: [],
            lev: 1
        }
    },
    methods: {
        nextLevel: function(lv){
            if(this.level < 4){
                this.level = lv + 1;
            }
            setTimeout(() => {
                window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
            }, 150);
        },
        selectItem: function(lv, item){
            this.lev = lv
            this.level = lv;
            if(lv === 1)
            {
                this.selected1 = item;
                this.selected2 = {}
                this.selected3 = {}
                this.nextLevel(1);
                this.setPage();
            }
            if(lv === 2){
                this.selected2 = item;
                this.selected3 = {}
                if(item.items === undefined || item.items === null)
                    this.loadItems(item);
                else{
                    this.nextLevel(2);
                    this.setPageP3();
                }
            }
            if(lv === 3){
                this.selected3 = item;
                this.nextLevel(3);
                // if(item.items === undefined || item.items === null)
                //     this.loadItems(item);
                // else{
                //     this.nextLevel(3);
                // }
            }
        },
        loadTechnologies(){
            const thisVue = this;
            configuratorService.getTechnologies().then(response => {
                thisVue.technologies = response.data;
            });
        },
        loadItems(item){
            configuratorService.getSchema(item.id).then(response => {
                item.items = response.data;
                this.nextLevel(2);
                if(this.lev == 2)
                this.setPageP3();
            });
        },
        prevPage(){            
            let pages = Math.ceil(Number(this.selected1.technology.length)/4);
            if(this.page - 1 > 0)
                this.page = this.page - 1;
            else
                this.page = pages;
            this.setPage()
        },
        nextPage(){
            let pages = Math.ceil(Number(this.selected1.technology.length)/4);
            if(pages >= this.page + 1)
                this.page = this.page + 1;
            else
                this.page = 1;

            this.setPage()
        },
        showPrev(){
            return this.page > 1;   
        },
        showNext(){
            let pages = Math.ceil(Number(this.selected1.technology.length)/4);
            return pages > this.page;   
        },
        setPage(){
            this.itemsToDisplay = [];
            let a = this.page - 1;
            if(a>0)
                a = a * 4;
            let b = a + 4;
            let data = this.selected1.technology.slice(a, b);
            this.itemsToDisplay = [...data];
        },
        prevPageP3(){            
            let pages = Math.ceil(Number(this.selected2.items.length)/4);
            if(this.page3 - 1 > 0)
                this.page3 = this.page3 - 1;
            else
                this.page3 = pages;
            this.setPageP3()
        },
        nextPageP3(){
            let pages = Math.ceil(Number(this.selected2.items.length)/4);
            if(pages >= this.page3 + 1)
                this.page3 = this.page3 + 1;
            else
                this.page3 = 1;

            this.setPageP3()
        },
        setPageP3(){
            this.itemsToDisplay3 = [];
            let a = this.page3 - 1;
            if(a>0)
                a = a * 4;
            let b = a + 4;
            let data = this.selected2.items.slice(a, b);
            this.itemsToDisplay3 = [...data];
        },
        showPrev3(){
            return this.page3 > 1;   
        },
        showNext3(){
            let pages = Math.ceil(Number(this.selected2.items.length)/4);
            return pages > this.page3;   
        },
    },
    created() {
        this.loadTechnologies();
    },
    computed: {
        arrowCenter() {
        let mt = 10;
            return {
                "margin-top": `${mt}rem`,
                width: "30px",
                cursor: "pointer",
            };
        },
    },
    components: {
        ConfiguratorItem,
        ConfiguratorSlider
    }
};
</script>

