<template>
    <div class="row" style="padding: 1rem;">
        <div class="col-xs-6 mb-1">
            <div>{{ $t("strBuyNetto") }}</div>
            <div>
                <InputNumber showButtons style="width: 100%" v-model="pPriceBuy" />
            </div>
        </div>
        <div class="col-xs-6 mb-1">
            <div>{{ $t("strSellNetto") }}</div>
            <div>
                <InputNumber showButtons style="width: 100%" v-model="pFPriceSell" />
            </div>
        </div>
        <div class="col-xs-12 mb-1">
            <div>{{ $t("strCustomerNotes") }}</div>
            <div>
                <Textarea v-model="pNotes" rows="5" style="width: 100%;" />
            </div>
        </div>
        <div class="col-xs-12 my-1">
            <VButton class="select-button" @click="submitForm()" :style="selectButton" strKey="strSelect" />
        </div>
    </div>
  </template>
  
  <script>
  import { orderService } from "../../services/order.service.js";
  import VButton from "../global/VButton.vue";
  
  export default {
    components: { VButton },
    props: {
      variantId: Number,
      item: {},
      editMode: Boolean
    },
    name: "EmptyPositionForm",
    data() {
      return {
        pQuantity: 1,
        pPriceBuy: 1,
        pFPriceSell: 1,
        pNotes: '',
      };
    },
    methods: {
        submitForm() {
            if(this.editMode) this.update()
            else this.add()
        },
        add() {
            const {variantId, pQuantity, pPriceBuy, pFPriceSell, pNotes} = this;
            const {id} = this.item;
            orderService.addEmptyPositionToVariant(
                variantId,
                id,
                pPriceBuy,
                pFPriceSell,
                pQuantity,
                pNotes
            ).then((response) => {
                if(Number(response.statusCode) === 200) {
                    this.$emit("onSubmitForm", id);
                    this.$toast.add({
                        severity: "success",
                        summary: this.$t("strEmptyPositionAdded"),
                        life: 3000,
                    });
                }else{
                    this.$toast.add({
                        severity: "error",
                        summary: response.statusMessage,
                        detail: "",
                    });
                }
            });
        },
        update() {
            const {variantId, pQuantity, pPriceBuy, pFPriceSell, pNotes} = this;
            const {idPosition} = this.item;
            const id = idPosition;
            const idFPosition = 1;
            orderService.updateEmptyPositionVariant(
                id,
                variantId,
                idFPosition,
                pPriceBuy,
                pFPriceSell,
                pQuantity,
                pNotes
            ).then((response) => {
                if(Number(response.statusCode) === 200) {
                    this.$emit("onSubmitForm", id);
                    this.$toast.add({
                        severity: "success",
                        summary: this.$t("strEmptyPositionUpdated"),
                        life: 3000,
                    });
                }else{
                    this.$toast.add({
                        severity: "error",
                        summary: response.statusMessage,
                        detail: "",
                    });
                }
            });
        }
    },
    mounted: function mounted() {
        if(!this.editMode) {
            this.pQuantity = 1;
            this.pPriceBuy = this.item.priceBuy;
            this.pFPriceSell = this.item.priceSell;
            this.pNotes = '';      
        }else{
            this.pQuantity = 1;
            this.pPriceBuy = this.item.finalPriceDealer;
            this.pFPriceSell = this.item.finalPriceClient;
            this.pNotes = this.item.orderNotes;   
        }
    },
  };
  </script>
  
  <style>
  .select-button {
      background: #c61e1e;
      color: #fff;
      border: none;
      font-weight: normal;
      font-size: 14px;
      padding: .5rem 1rem;
      border-radius: .5rem;
  }
  </style>
  