<template>
  <div
    class="row offer__content my-2 p-0 mx-0"
    style="width:80%;min-height: 228px;"
    v-if="item !== undefined"
  >
    <div style="position: absolute;right: 24px;z-index:99">
      <VRole roleKey="variantItemDuplicate">
        <img
          src="@/assets/icons/vetrex-ikony/copy_outlined_32.svg"
          alt=""
          v-if="canEdit"
          @click="item.idTechnology > 0 ? duplicatePosition() : duplicateEmptyPosition()"
          style="cursor:pointer;width: 22px;margin-right:1.75rem"
        />
      </VRole>
      <VRole roleKey="variantItemDelete">
        <img
          src="@/assets/icons/vetrex-ikony/trash_outlined_32.svg"
          alt=""
          style="cursor:pointer;width: 22px;margin-right:1.75rem"
          v-if="canEdit"
          @click="displayDeleteDialog = true"
        />
      </VRole>
      <VRole roleKey="variantItemEdit">
        <img
          src="@/assets/icons/edycja.svg"
          alt=""
          style="cursor:pointer;width: 22px;"
          v-if="canEdit"
          @click="item.idTechnology > 0 ? editPosition() : toggleForm($event, item.idPosition)"
        />
      </VRole>
    </div>

    <div
      style="position: absolute;right: 24px;margin-top:12rem;z-index:99;color:#495057"
    >
      <VRole roleKey="variantItemFinalPriceDealer">
        <div>
          {{ $t("strfinalPriceDealer") }}:
          <span style="font-size:18px;"
            >{{ item.finalPriceDealer }} {{ currencyName }}</span
          >
        </div>
      </VRole>
      <VRole roleKey="variantItemFinalPriceClient">
        <div>
          {{ $t("strFinalPriceClient") }}:
          <span style="font-size:18px;"
            >{{ item.finalPriceClient }} {{ currencyName }}</span
          >
        </div>
      </VRole>
    </div>

    <div class="col-md-12 d-flex">
      <div>
        <img
          :src="item.graphicURL + '?' + Date.now()"
          alt=""
          @click="toggleImg($event, item.idPosition)"
          style="max-width: 200px; max-height: 200px;"
        />
        <OverlayPanel
          :ref="'opi_' + item.idPosition"
          appendTo="body"
          :showCloseIcon="true"
          id="overlay_panel"
          style="width: 350px; height: auto"
        >
          <img
            :src="item.graphicURL + '?' + Date.now()"
            style="width: 100%; height: auto"
          />
        </OverlayPanel>
      </div>
      <div
        class="d-flex"
        style="flex-wrap:wrap;flex-flow:column;justify-content:center;margin-left:1.5rem"
      >
        <div style="font-size:20px">
          {{ item.namePosition || $t("strPositionEmptyName") }}
        </div>
        <template v-if="item.idTechnology > 0">
          <div class="my-1">
            {{ $t("strTechnology") }}: <span>{{ item.technology }}</span>
          </div>
          <div class="my-1">
            {{ $t("strColor") }}: <span>{{ item.color }}</span>
          </div>
          <div class="my-1">
            {{ $t("strSize") }}: <span>{{ item.sizeX }}x{{ item.sizeY }}</span>
          </div>
        </template>
        <template v-else>
          <div class="my-1">
            {{ $t("strCustomerNotes") }}:
          </div>
          <div class="my-1">
            {{ item.orderNotes }}
          </div>
        </template>
        <!-- strCustomerNotes -->
        <div class="my-1">
          {{ $t("strPosition") }}: <span> {{ item.noPosition }}</span>
        </div>
        <div class="my-1">
          {{ $t("strAccessoryQuantity") }}: <span> {{ item.quantity }}</span>
        </div>
      </div>
    </div>
    <OverlayPanel 
        :ref="'op_' + item.idPosition"                
        appendTo="body" 
        :showCloseIcon="true"
        id="overlay_panel"
        style="width: 350px; height: auto">
        <EmptyPositionForm :variantId="item.idVariant" :item="item" @onSubmitForm="onSubmitForm" :editMode="true" />
    </OverlayPanel>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t("qnPositionDelete") }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />
        <VButton class="btn-red" @click="item.idTechnology > 0 ? deletePosition() : deleteEmptyPosition()" strKey="strDelete" />
      </div>
    </Dialog>

    <Dialog
      :header="$t('strPositionEditHeader')"
      id="process_msg"
      :visible.sync="displayEditPosition"
      :style="{ width: '90%' }"
      :modal="true"
      @show="editPositionOpened"
    >
      <div class="row">
        <p>test</p>
      </div>
      <!-- <ProductEd ref="productEdit"> </ProductEd> -->
    </Dialog>

    <!-- <ProductEd ref="productEdit"> </ProductEd> -->
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
//import { productService } from '../../services/product.service.js';

//import { Product } from "@/components/configurator/Product.vue";
// import Product from "@/components/configurator/Product";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";
import EmptyPositionForm from "@/components/offers/EmptyPositionForm";

export default {
  name: "VariantItem",
  props: {
    item: {},
    orderId: Number,
    canEdit: Boolean,
    currencyName: String,
  },
  data() {
    return {
      response: {},
      positions: [],
      technologies: "",
      displayDeleteDialog: false,
      displayEditPosition: false,
    };
  },
  // mounted(){
  //   console.log(this.item)
  // },
  methods: {
    duplicatePosition: function() {
      orderService
        .duplicatePosition(this.item.idPosition, this.item.idVariant)
        .then((response) => {
          this.handleDuplicateResponse(response)
        });
    },
    duplicateEmptyPosition: function() {
      orderService
        .duplicateEmptyPosition(this.item.idPosition, this.item.idVariant)
        .then((response) => {
          this.handleDuplicateResponse(response, true)
        });
    },
    handleDuplicateResponse(response, isEmpty=false){
          if (response.isSuccess) {
            if(isEmpty)
              this.$parent.productUpdated();
            else
              this.$parent.productAdded(response.data[0]);

            this.$toast.add({
              severity: "success",
              summary: this.$t("strPosition"),
              detail: this.$t("strPositionDuplicated"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strPosition"),
              detail: response.statusMessage,
            });
          }
    },
    deletePosition() {
      let idPosition = this.item.idPosition;
      orderService.deletePosition(idPosition).then((response) => {
        this.handleDeleteResponse(response)
      });
      this.displayDeleteDialog = false;
    },
    deleteEmptyPosition() {
      let idPosition = this.item.idPosition;
      orderService.deleteEmptyPositionVariant(idPosition).then((response) => {
        this.handleDeleteResponse(response)
      });
      this.displayDeleteDialog = false;
    },
    handleDeleteResponse(response, idPosition){
      if (response.isSuccess) {
          //console.log(response);
          this.$toast.add({
            severity: "success",
            summary: this.$t("strPosition"),
            detail: this.$t("strPositionDeleted"),
            life: 3000,
          });
          //this.$parent.variantAdded(response.data);
          this.$parent.productDeleted(idPosition);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strPosition"),
            detail: response.statusMessage,
          });
        }
    },
    editPosition() {
      //const idPosition = this.item.idPosition;
      //alert(idPosition);
      //this.displayEditPosition = true;
      this.$router.push(
        `/configurator/product/${this.item.idTechnology},${this.item.idPosition},${this.item.idVariant},${this.orderId},true`
      );
      // productService.getPositionDetail(idPosition).then(response => {
      //   productService.getSchema(idPosition).then(schemaResponse => {
      //     alert('schema loaded');
      //     console.log(schemaResponse);
      //   });
      //   console.log(response);
      //   alert('loaded');
      // });
    },
    editPositionOpened() {
      // console.log(this.$refs.productEdit);
      //this.$refs.productEdit.loadPosition(this.item.idPosition);
    },
    toggleImg(event, id) {
      const el = this.$refs["opi_" + id];
      el.toggle(event);
    },
    toggleForm(event, id) {
        const el = this.$refs["op_" + id];
        el.toggle(event);
    },
    onSubmitForm(id) {
      const el = this.$refs["op_" + id];
      el.toggle();
      this.$parent.productUpdated();
    }
  },
  // watch: {
  //   $props: {
  //     handler() {
  //       console.log(`variant: ${this.variant.idVariant}`);
  //       this.loadVariantPositions();
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  components: { VButton, VRole, EmptyPositionForm },
};
</script>
