<template>
  <div class="row actionsMenu">
    <div class="col-xs-6 d-flex">
      <div>
        <img
          src="@/assets/icons/vetrex-ikony/list_outlined_32.svg"
          alt=""
          class="listIcon"
          @click="openDialog('')"
        />
      </div>
      <div>
        <VButton
          class="btn-red-outline"
          @click="openDialog('new-offer')"
          strKey="strOfferNe"
        />
      </div>
      <div style="margin-left: 1rem">
        <VButton
          class="btn-red-menu"
          @click="openPage('edit-offer')"
          strKey="strCloseConfi"
        />
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("strSaveProject") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-red"
          @click="displayDialog = false"
          strKey="strCancel"
        />
        <div>
          <VButton
            class="btn-red"
            style="margin-right: 1rem"
            @click="goToPage()"
            strKey="strDontSave"
          />
          <VButton class="btn-outline-red" @click="save()" strKey="strSave" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script>
import VButton from "./global/VButton.vue";
import { productService } from "@/services/product.service.js";

export default {
  name: "ActionsMenuProduct",
  props: {
    basket: {},
    basketOrg: String,
    startPrice: Number,
  },
  data() {
    return {
      displayDialog: false,
      page: "",
    };
  },
  methods: {
    openDialog(page) {
      this.page = page;
      if (
        this.startPrice !== this.basket.price ||
        JSON.stringify(this.basket) !== this.basketOrg
      )
        this.displayDialog = true;
      else this.goToPage();
    },
    openPage(page) {
      this.page = page;
      this.goToPage();
    },
    goToPage() {
      if (this.page === "edit-offer") {
        if (this.$route.params.idOffer) {
          this.$router.push(
            `/edit-offer/${Number(this.$route.params.idOffer)}`
          );
        } else {
          this.$router.push(`/`);
        }
      } else if (this.page === "new-offer") {
        this.$router.push(`/new-offer`);
      } else {
        this.$router.push(`/`);
      }
    },
    save() {
      this.displayDialog = false;

      productService
        .setBasket(
          Number(this.$route.params.idPosition),
          this.basket,
          this.$t("strSetBasketError")
        )
        .then((response) => {
          if (response.isSuccess) {
            this.goToPage();
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strOffer"),
              detail: response.statusMessage,
            });
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: error,
          });
        });
    },
  },
  components: {
    VButton,
  },
};
</script>
