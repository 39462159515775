import { handleResponse, getAPIUrl, getSessionGuid } from '../helpers';

export const translationsService = {
    // initTranslations,
     getTranslations,
     addTranslation,
     updateTranslation
};

function getTranslations(lang) {
    return new Promise((resolve) => {
        getSessionGuid().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: `${session}` })
            };
            //console.log('get_translations:');
            //console.log(requestOptions);
            return fetch(getAPIUrl('get_translator'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response.data.filter(i => i.lang === lang));
                });
        });
    });
}

function addTranslation(lang, key, value) {
    return new Promise((resolve) => {
      
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ lang, key, value })
        };
       
        return fetch(getAPIUrl('set_translator'), requestOptions)
            .then(handleResponse)
            .then(response => {
                resolve(response.data);
            });
    });
}

function updateTranslation(lang, key, value) {
    return new Promise((resolve) => {
        getSessionGuid().then(async session => {
            console.log(session);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ lang, key, value })
            };
            console.log('updateTranslation:');
            console.log(requestOptions);
            return fetch(getAPIUrl('update_translator'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    console.log(response);
                    resolve(response.data);
                });
        });
    });
}


