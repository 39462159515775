<template>
  <div class="container" style="margin-bottom: 3rem">
    <ActionsMenuProduct
      :basket="basket"
      :basketOrg="basketOrg"
      :startPrice="startPrice"
    />
    <div
      class="row"
      style="
        background: #fff;
        box-shadow: -2px -2px 6px #0000001a;
        padding-bottom: 3rem;
      "
    >
      <div
        class="col-md-12"
        style="
          background: #212429;
          color: #fff;
          font-size: 24px;
          padding: 2rem 3rem;
        "
      >
        <span class="link-white" @click="back()">&lt;</span>
        {{ $t("strConfiguratorHeader") }}
      </div>
      <div class="col-md-8" style="padding: 2rem 3rem; min-height: 70vh">
        <div class="row">
          <div class="col-md-12" style="padding: 2rem 3rem">
            <product-header
              :idPosition="idPosition"
              :basket="basket"
              :basketOrg="basketOrg"
              :priceDetails="priceDetails"
              :isEdit="isEdit"
            />
          </div>
          <div class="col-md-3" style="margin-top: 2.5rem">
            <div>
              <div
                style="
                  font-size: 18px;
                  font-weight: bold;
                  border-bottom: 1px solid black;
                "
              >
                <input
                  type="text"
                  v-model="basket.namePosition"
                  maxlength="50"
                  style="
                    background: #f7f7f7;
                    border: none;
                    text-align: center;
                    width: 100%;
                  "
                />
              </div>
              <div
                style="
                  border-bottom: 1px solid black;
                  padding-top: 2rem;
                  padding-bottom: 1rem;
                "
              >
                {{ $t("strDescription") }}
              </div>
              <product-description :basket="basket" />
              <div
                style="
                  display: flex;
                  font-size: 16px;
                  justify-content: flex-end;
                  margin-top: 2.5rem;
                "
              >
                <div style="margin-right: 1rem">
                  <VButton
                    v-if="!isEdit"
                    style="
                        border: 2px solid #68bab5;
                        border-radius: 4px;
                        color: #68bab5;
                        background: #fff;
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                      "
                    @click="reset"
                    strKey="strReset"
                  />
                </div>
                <div>
                  <VButton
                    v-if="!isEdit"
                    class="btn-green-confi"
                    @click="calculate(false)"
                    strKey="strCalculate"
                  />
                  <VButton
                    v-if="isEdit"
                    class="btn-green-confi"
                    @click="displayDialog = true"
                    strKey="strCalculateSave"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-9" style="display: flex;justify-content: center;">
            <div
              style="display: flex; justify-content: center;max-width: 500px;margin-left: 1rem;margin-right: 1rem"
            >
              <div>
                <img
                  :src="graphicUrl"
                  style="width: 100%; height: auto; margin-top: 0.75rem"
                />
                <div style="display: flex; justify-content: space-around">
                  <div>
                    <!-- <SelectButton
                      v-model="selectedZone"
                      :options="zones"
                      optionLabel="name"
                      optionValue="id"
                    /> -->
                    <div
                      style="
                        display: flex;
                        margin-top: 1rem;
                        margin-bottom: 1rem;
                      "
                      v-if="
                        zones !== undefined &&
                          zones.length > 2 &&
                          displayZones()
                      "
                    >
                      <div
                        v-for="item in zones"
                        :key="item.id"
                        @click="changeZone(item.id)"
                        :class="[
                          selectedZone === item.id
                            ? 'productTabSelected'
                            : 'productTab',
                        ]"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <label class="offer--input-label" for="inputtext1">{{
                    $t("strPositionNotes")
                  }}</label>
                  <Textarea
                    :autoResize="true"
                    rows="5"
                    cols="20"
                    class="offer--input"
                    v-model="orderNotes"
                  />
                </div>
                <div
                  v-if="
                    $t('strFrAlert') &&
                      $t('strFrAlert') !== '' &&
                      $t('strFrAlert') !== 'strFrAlert' &&
                      $t('strFrAlert') === 'true'
                  "
                  style="margin-top:2rem;color:#c61e1e"
                >
                  {{
                    "En application de l’art. L.541-10 du Code de l’environnement, VETREX adhère à l’éco-organisme VALOBAT, agréé par les pouvoirs publics. Toutes les commandes se verront appliquer, en sus du prix indiqué ci-dessous, l’éco-contribution selon le barème publié par VALOBAT et disponible sur le site de VALOBAT ("
                  }}
                  <a href="https://www.valobat.fr/bareme-moteur-de-recherche" target="_blank">www.valobat.fr/bareme-moteur-de-recherche</a>
                  {{ ")." }}
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-1">
                    <div style="display:flex;align-items:flex-end;">
                        <div class="row">
                            <div class="col-12">
                                <img @click="changeColor('ColorIn')" :src="basket.graphicURLI" style="width:32px; height:32px;border: 1px solid black;" />
                            </div>
                            <div class="col-12">
                                <img @click="changeColor('ColorEx')" :src="basket.graphicURLEx" style="width:32px; height:32px;border: 1px solid black;" />
                            </div>
                            <div class="col-12">
                                <img @click="changeColor('ColorC')" :src="basket.graphicURLC" style="width:32px; height:32px;border: 1px solid black;" />
                            </div>
                        </div>
                    </div>
                </div> -->
        </div>
      </div>
      <div class="col-md-4" style="padding: 0">
        <product-elements
          ref="productElements"
          :basket="basket"
          :changeZone="changeZone"
          :idTechnology="idTechnology"
          :idPosition="idPosition"
          :positionDetails="details"
          :selectedZone="selectedZone"
        />
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("strAskCalculate1") }}{{ $t("strAskCalculate2") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-outline-red"
          @click="displayDialog = false"
          strKey="strNot"
        />
        <VButton class="btn-red" @click="calculate(false)" strKey="strYes" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import ActionsMenuProduct from "@/components/ActionsMenuProduct";
import ProductElements from "./ProductElements.vue";
import ProductHeader from "./ProductHeader.vue";
import ProductDescription from "./ProductDescription.vue";
import VButton from "../global/VButton.vue";
import { productService } from "../../services/product.service.js";
import { orderService } from "../../services/order.service.js";

export default {
  name: "Product",
  data() {
    return {
      basket: {},
      basketOrg: "",
      canSetStartPrice: true,
      startPrice: 0,
      name: "Nazwa pozycji 03",
      details: {},
      zones: [],
      selectedZone: "",
      idPosition: 0,
      idVariant: 0,
      idOffer: 0,
      idTechnology: 0,
      isEdit: false,
      priceDetails: [],
      graphicUrl: "",
      afterMount: false,
      displayDialog: false,
      orderNotes: "",
    };
  },
  mounted: function() {
    this.idPosition = Number(this.$route.params.idPosition);
    this.idTechnology = Number(this.$route.params.idTechnology);
    this.idVariant = Number(this.$route.params.idVariant);
    this.idOffer = Number(this.$route.params.idOffer);
    this.isEdit = this.$route.params.isEdit === "true";
    this.loadPosition(this.idPosition);
    orderService
      .getVariantPositions(this.$route.params.idVariant)
      .then((response) => {
        let r = response.data;
        let position = r.find((i) => i.idPosition === this.idPosition);
        this.orderNotes = position.orderNotes;
      });
  },
  methods: {
    back() {
      if (this.isEdit) {
        this.$router.push(`/edit-offer/${this.idOffer}`);
      } else {
        this.$router.push(`/configurator/${this.idVariant},${this.idOffer}`);
      }
    },
    changeZone(zone) {
      this.selectedZone = zone;
    },
    reset() {
      const thisVue = this;
      productService
        .getBasketReset(this.idPosition, this.idVariant)
        .then((response) => {
          if (response.isSuccess) {
            thisVue.setBasket(response.data);
            this.$toast.add({
              severity: "success",
              summary: this.$t("strOffer"),
              detail: this.$t("strProductUpdated"),
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strOffer"),
              detail: response.statusMessage,
            });
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: error,
          });
        });
    },
    calculate(goToOffer = false, loadSliders = false) {
      const thisVue = this;
      thisVue.displayDialog = false;
      productService
        .setBasket(this.idPosition, this.basket, this.$t("strSetBasketError"))
        .then((response) => {
          if (response.isSuccess) {
            thisVue.loadPosition(thisVue.idPosition);
            this.$toast.add({
              severity: "success",
              summary: this.$t("strOffer"),
              detail: this.$t("strProductUpdated"),
              life: 3000,
            });
            productService
              .updatePosition(thisVue.idPosition, thisVue.orderNotes)
              .then((response) => {
                if (response.isSuccess) {
                  console.log("update position success");
                }
              });
            if (goToOffer) {
              this.$router.push(`/edit-offer/${thisVue.idOffer}`);
            }
            if (loadSliders) {
              this.$refs.productElements.loadSlidersList();
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strOffer"),
              detail: response.statusMessage,
            });
          }
        })
        .catch((error) => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: error,
          });
        });
    },
    loadPosition(idPosition) {
      const thisVue = this;
      productService.getBasket(idPosition, 0).then((response) => {
        thisVue.setBasket(response.data);
      });
    },
    setBasket(basket) {
      this.basket = basket;
      if (this.basket.namePosition === null) this.basket.namePosition = "";
      if (this.canSetStartPrice) {
        this.startPrice = this.basket.price;
        this.canSetStartPrice = false;
      }
      this.basketOrg = JSON.stringify(this.basket);

      //thisVue.basket = response.data;
      if (basket && basket.graphicURLWindow)
        this.graphicUrl = basket.graphicURLWindow + `?rand=${Date.now()}`;
      else this.graphicUrl = "";
      this.loadZones(basket);
      productService.getPriceDetails(this.idPosition).then((response) => {
        this.priceDetails = response.data;
        if (this.afterMount)
          this.$toast.add({
            severity: "success",
            summary: this.$t("strOffer"),
            detail: this.$t("strProductUpdated"),
            life: 3000,
          });
        else this.afterMount = true;
      });
    },
    loadZones(basket) {
      this.zones = [];
      this.zones.push({ name: this.$t("strZoneAll"), id: "Z0" });
      [...new Set(basket.zone.map((x) => x.zoneName))].forEach((x) => {
        this.zones.push({ name: x, id: x });
      });

      if (this.zones.length === 2) this.selectedZone = this.zones[1].id;
    },
    displayZones() {
      return this.$refs.productElements.mainTab > 0;
    },
  },
  components: {
    ActionsMenuProduct,
    ProductElements,
    ProductHeader,
    ProductDescription,
    VButton,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
