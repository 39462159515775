import Vue from 'vue'
import Router from 'vue-router'
import OffersList from '@/components/offers/OffersList'
import OfferEdit from '@/components/offers/OfferEdit'
import OfferNew from '@/components/offers/OfferNew'
import Configurator from '@/components/configurator/Configurator'
import Product from '@/components/configurator/Product'
import TranslationsEditor from '@/components/admin/TranslationsEditor'
import Login from '@/components/user/Login'
import AssembliesList from '@/components/admin/AssembliesList'
import CustomersList from '@/components/customers/CustomersList'
import Company from '@/components/admin/Company'
import User from '@/components/admin/User'
import Pricing from '@/components/admin/Pricing'
import EmptyPositionAdmin from '@/components/admin/EmptyPosition'
import NoAccess from '@/components/NoAccess'
import { userService } from "../services/user.service.js";

Vue.use(Router)


function guardMyroute(to, from, next)
{
 var isAuthenticated= true;
// if(localStorage.getItem('session'))
//   isAuthenticated = true;
//  else
//   isAuthenticated= false;

//alert(to.meta.roles.length);
//alert('auth...');
 if(isAuthenticated) 
 {
  next(); // allow to enter route
 } 
 else
 {
  next('/noaccess'); // go to '/login';
 }
}

function authSession(to, from, next){
  const session = userService.getSession();
  if(!session)
    window.location.href = "https://portal.vetrex.eu/my-account/";
  next();
}

export default new Router({
  routes: [
    {
      path: '/edit-offer/:offerId',
      name: 'OfferEdit',
      beforeEnter : authSession,
      component: OfferEdit
    },
    {
      path: '/new-offer',
      name: 'OfferNew',
      beforeEnter : authSession,
      component: OfferNew
    },
    {
      path: '/configurator/:idVariant,:idOffer',
      name: 'Configurator',
      beforeEnter : authSession,
      component: Configurator
    },
    {
      path: '/configurator/product/:idTechnology,:idPosition,:idVariant,:idOffer,:isEdit',
      name: 'Product',
      beforeEnter : authSession,
      component: Product
    },
    {
      path: '/admin/translations',
      name: 'TranslationsEditor',
      beforeEnter : authSession,
      component: TranslationsEditor
    },
    {
      path: '/user/login/:email/:idAddress',
      name: 'Login',
      component: Login
    },
    {
      path: '/admin/assemblies',
      name: 'AssembliesList',
      beforeEnter : authSession,
      component: AssembliesList
    },
    {
      path: '/admin/customers',
      name: 'CustomersList',
      beforeEnter : authSession,
      component: CustomersList
    },
    {
      path: '/admin/company',
      name: 'Company',
      beforeEnter : authSession,
      component: Company
    },
    {
      path: '/admin/user',
      name: 'User',
      beforeEnter : guardMyroute,
      meta: {title: 'Usr', roles: [0,2]},
      component: User
    },
    {
      path: '/admin/pricing',
      name: 'Pricing',
      beforeEnter : authSession,
      component: Pricing
    },
    {
      path: '/admin/empty-position',
      name: 'EmptyPositionAdmin',
      beforeEnter : authSession,
      component: EmptyPositionAdmin
    },
    {
      path: '/noaccess',
      name: 'NoAccess',
      component: NoAccess
    },
    {
      path: '/',
      name: 'OffersList',
      beforeEnter : authSession,
      component: OffersList
    },
  ]
})