import { handleResponse, getAPIUrl, getSessionGuid } from '../helpers';

export const productHierarchyService = {
    getLevelItems
};

function getLevelItems(parentId = null) {
    return new Promise((resolve) => {
        getSessionGuid().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: `${session}`, language: `pl`, parentId: parentId })
            };

            //console.log(requestOptions);

            return fetch(getAPIUrl('get_product_tree'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

