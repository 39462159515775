<template>
  <div style="padding:1rem 2.5rem;display:flex;flex-flow:column">
    <form>
      <div class="row" style="display:flex;flex-flow:column">
        <div class="col-lg-9" style="padding:0">
          <div class="row" style="margin:0">
            <div class="col-md-6">
              <div class="my-2">
                <label class="offer--input-label" for="firstName">
                  {{ $t("strCustomerFirstName") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="firstName"
                  class="offer--input"
                  type="text"
                  v-model="customer.firstName"
                />
                <div
                  v-if="submitted && $v.customer.firstName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.firstName.required">{{
                    $t("strCustomerFirstNameRequired")
                  }}</span>
                  <span v-if="!$v.customer.firstName.minLength">{{
                    $t("strCustomerFirstNameMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="my-2">
                <label class="offer--input-label" for="secondName">
                  {{ $t("strCustomerSecondName") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="secondName"
                  class="offer--input"
                  type="text"
                  v-model="customer.secondName"
                />
                <div
                  v-if="submitted && $v.customer.secondName.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.secondName.required">{{
                    $t("strCustomerSecondNameRequired")
                  }}</span>
                  <span v-if="!$v.customer.secondName.minLength">{{
                    $t("strCustomerSecondNameMinLength")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="margin-top:1rem;margin-bottom:1rem">
            <div class="p-field-radiobutton">
              <label
                for="isCompany"
                style="margin:0;font-weight: 500;font-size: 12px;margin-right:1rem;"
                >{{ $t("strCustomerCompany") }}</label
              >
              <RadioButton
                name="isCompany"
                value="isCompany"
                v-model="selectedCompany"
                @click="uncheckCompany()"
              />
            </div>
          </div>
          <div v-if="selectedCompany === 'isCompany'">
            <div class="col-md-6">
              <div class="my-2">
                <label class="offer--input-label" for="name">
                  {{ $t("strCustomerCompanyName") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="name"
                  class="offer--input"
                  type="text"
                  v-model="customer.name"
                />
                <span v-if="nameRequired">{{
                  $t("strCustomerNameRequired")
                }}</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="my-2">
                <label class="offer--input-label" for="secondName">
                  {{ $t("strCustomerTaxId") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="secondName"
                  class="offer--input"
                  type="text"
                  v-model="customer.nip"
                />
                <span v-if="nipRequired">{{
                  $t("strCustomerTaxIdRequired")
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-11"
          style="border-bottom:1px solid #707070;padding:0;margin:15px"
        ></div>
      </div>

      <div class="row">
        <div class="col-lg-9" style="padding:0">
          <div class="row" style="margin:0">
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="street">
                  {{ $t("strCustomerStreet") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="street"
                  class="offer--input"
                  type="text"
                  v-model="customer.street"
                />
                <div
                  v-if="submitted && $v.customer.street.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.street.required">{{
                    $t("strCustomerStreetRequired")
                  }}</span>
                  <span v-if="!$v.customer.street.minLength">{{
                    $t("strCustomerStreetMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="city">
                  {{ $t("strCustomerCity") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="city"
                  class="offer--input"
                  type="text"
                  v-model="customer.place"
                />
                <div
                  v-if="submitted && $v.customer.place.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.place.required">{{
                    $t("strCustomerPlaceRequired")
                  }}</span>
                  <span v-if="!$v.customer.place.minLength">{{
                    $t("strCustomerPlaceMinLength")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin:0">
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="zipCode">
                  {{ $t("strCustomerZipCode") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="zipCode"
                  class="offer--input"
                  type="text"
                  v-model="customer.zipCode"
                  @keypress="validateNumber(event)"
                />
                <div
                  v-if="submitted && $v.customer.zipCode.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.zipCode.required">{{
                    $t("strCustomerCodeRequired")
                  }}</span>
                  <span v-if="!$v.customer.zipCode.minLength">{{
                    $t("strCustomerCodeMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="country">
                  {{ $t("strCustomerCountry") }}
                  <span style="color:red">*</span>
                </label>
                <!-- <InputText id="country" class="offer--input" type="text" v-model="customer.country" required /> -->

                <Dropdown
                  id="country"
                  class="offer--input"
                  v-model="customer.country"
                  :options="countries"
                  optionLabel="countryName"
                  optionValue="id"
                />

                <div
                  v-if="submitted && $v.customer.country.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.country.required">{{
                    $t("strCustomerCountryRequired")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-11"
          style="border-bottom:1px solid #707070;padding:0;margin:15px"
        ></div>
      </div>

      <div class="row">
        <div class="col-lg-9" style="padding:0">
          <div class="row" style="margin:0">
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="city">
                  {{ $t("strCustomerPhone1") }}
                  <span style="color:red">*</span>
                </label>
                <InputText
                  id="city"
                  class="offer--input"
                  type="tel"
                  v-model="customer.phone1"
                  @keypress="validateNumber(event)"
                />
                <div
                  v-if="submitted && $v.customer.phone1.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.customer.phone1.required">{{
                    $t("strCustomerPhoneRequired")
                  }}</span>
                  <span v-if="!$v.customer.phone1.minLength">{{
                    $t("strCustomerPhoneMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="code">{{
                  $t("strCustomerPhone2")
                }}</label>
                <InputText
                  id="code"
                  class="offer--input"
                  type="tel"
                  v-model="customer.phone2"
                  @keypress="validateNumber(event)"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="my-2">
              <label class="offer--input-label" for="code">
                {{ $t("strCustomerEmail") }}
                <span style="color:red">*</span>
              </label>
              <InputText
                id="code"
                class="offer--input"
                type="text"
                v-model="customer.email"
              />
              <div
                v-if="submitted && $v.customer.email.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.customer.email.required">{{
                  $t("strCustomerEmailRequired")
                }}</span>
                <span v-if="!$v.customer.email.email">{{
                  $t("strCustomerEmailFormat")
                }}</span>
              </div>
            </div>
          </div>
          <div class="col-lg-12" style="margin-top:1rem">
            <label class="offer--input-label" for="notes">{{
              $t("strCustomerNotes")
            }}</label>
            <Textarea
              id="notes"
              :autoResize="true"
              rows="5"
              cols="30"
              class="offer--input"
              v-model="customer.notes"
            />
          </div>
          <div class="col-md-12" style="margin-top:1.5rem">
            <div class="p-field-radiobutton">
              <label
                for="-"
                style="margin:0;font-weight: 500;font-size: 12px;margin-right:1rem;text-decoration:underline"
              >
                {{ $t("strCustomerGdpr") }}
                <span style="color:red">*</span>
              </label>
              <RadioButton
                id="customerRodoTrue"
                name="rodo"
                :value="1"
                v-model="customer.rodo"
                @click.prevent="uncheckRodo()"
              />
              <!-- <RadioButton v-else id='customerRodoFalse' name="rodo" :value="false" v-model="customer.rodo" @click="uncheckRodo()"  /> -->
              <div v-if="submitted && !customer.rodo" class="invalid-feedback">
                <span>{{ $t("strRodoIsRequired") }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-12" style="margin-top:2.5rem">
            <span style="color:red">*</span> - {{ $t("strFieldIsRequired") }}
          </div>
        </div>
      </div>

      <div class="col-md-12 offer-footer toRight d-flex mt-2">
        <VButton
          type="button"
          class="btn btn-outline-red mr-2"
          style="border:2px solid #68BAB5;color:#68BAB5"
          @click="closeAddNewCustomer"
          strKey="strCancel"
        />
        <VButton
          type="button"
          class="btn btn-red"
          style="background:#68BAB5;border:2px solid #68BAB5"
          @click="saveCustomer"
          strKey="strSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { customerService } from "../../services/customer.service";
import { required, minLength, email } from "vuelidate/lib/validators";
import VButton from "../global/VButton.vue";
// import { orderService } from "../../services/order.service.js";
// import { productService } from "../../services/product.service.js";
// import VariantItem from "@/components/offers/VariantItem";
// import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";
// import ProductEdit from "@/components/product/ProductEdit";

export default {
  name: "CustomerEdit",
  props: ["closeAddNewCustomer"],
  data() {
    return {
      newCustomer: false,
      submitted: false,
      customer: {
        firstName: "",
        secondName: "",
        alias: "",
        street: "",
        zipCode: "",
        place: "",
        phone: "",
        phone2: "",
        fax: "",
        name: "",
        nip: "",
        discount: 0,
        regon: "",
        note: "",
        email: "",
        company: "",
        country: "",
        rodo: 0,
      },
      selectedRodo: "",
      previouslySelectedRodo: 0,
      selectedCompany: "",
      previouslySelectedCompany: "",
      nameRequired: false,
      nipRequired: false,
      countries: [],
    };
  },
  validations: {
    customer: {
      firstName: { required, minLength: minLength(3) },
      secondName: { required, minLength: minLength(3) },
      street: { required, minLength: minLength(2) },
      zipCode: { required, minLength: minLength(5) },
      place: { required, minLength: minLength(2) },
      phone1: { required, minLength: minLength(9) },
      email: { required, email },
      country: { required },
    },
  },
  methods: {
    addNewCustomer: function() {
      this.loadCountries();
      this.newCustomer = true;
      this.customer = {
        firstName: "",
        secondName: "",
        alias: "",
        street: "",
        zipCode: "",
        place: "",
        phone: "",
        phone2: "",
        fax: "",
        name: "",
        nip: "",
        discount: 0,
        regon: "",
        note: "",
        email: "",
        company: "",
        country: 0,
        rodo: 0,
      };
    },
    editCustomer: function(item) {
      this.newCustomer = false;
      this.customer = item;
      const thisVue = this;
      customerService.getCustomer(item.id).then((response) => {
        thisVue.customer = response.data[0];
        thisVue.customer.country = thisVue.customer.idCountry;
      });
      this.loadCountries();
    },
    loadCountries() {
      const thisVue = this;
      customerService.getCountries().then((response) => {
        thisVue.countries = response.data;
      });
    },
    saveCustomer() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid || this.customer.rodo === 0) {
        return;
      }
      if (this.selectedCompany === "isCompany") {
        let companyValid = this.validCompany();
        if (!companyValid) return;
      }
      if (this.newCustomer === true) {
        this.customer.rodo = 1;
        customerService.saveNewCustomer(this.customer).then((response) => {
          if (response.isSuccess === true) {
            this.$parent.$parent.customerAdded(response.data);
            //this.$emit('customer-added', response.data);
          }
        });
      } else {
        customerService.updateCustomer(this.customer).then((response) => {
          if (response.isSuccess === true) {
            this.$emit("customer-updated", response.data);
          }
        });
      }
    },
    validCompany: function() {
      let nameEmpty = this.customer.name === "";
      let nipEmpty = this.customer.nip === "";
      let valid = !nameEmpty && !nipEmpty;
      if (!valid) {
        if (nameEmpty) this.nameRequired = true;
        if (nipEmpty) this.nipRequired = true;
      }
      return valid;
    },
    uncheckRodo: function() {
      this.previouslySelectedRodo = this.customer.rodo;
      setTimeout(() => {
        if (this.previouslySelectedRodo === 0) this.customer.rodo = 1;
        else if (this.previouslySelectedRodo === 1) this.customer.rodo = 0;
      }, 50);
    },
    uncheckCompany: function() {
      setTimeout(() => {
        let val = "isCompany";
        if (val === this.previouslySelectedCompany)
          this.selectedCompany = false;
        this.previouslySelectedCompany = this.selectedCompany;
        if (document.activeElement instanceof HTMLElement)
          document.activeElement.blur();
      }, 50);
      this.nameRequired = false;
      this.nipRequired = false;
    },
    validateNumber: function(evt) {
      let theEvent = evt || window.event;
      let key;
      if (theEvent.type === "paste") {
        key = event.clipboardData.getData("text/plain");
      } else {
        key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
      }
      let regex = /[0-9]|\./;
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    },
  },
  watch: {},
  components: { VButton },
};
</script>
