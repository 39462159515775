import { handleResponse, getAPIUrl } from "../helpers";

export const userService = {
  initSession,
  //setLogin,
  getSession,
  getLang,
  setLang,
  signIn,
  getCurrentUserRoleId,
  logOut,
  getRole,
};

function signIn(login, idAdres) {
  return new Promise((resolve) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ login, idAdres }),
    };

    return fetch(getAPIUrl("get_access"), requestOptions)
      .then(handleResponse)
      .then((response) => {
        if (response.data !== null && response.data.guid) {
          setLang(response.data.language.toLowerCase(), false);
          const user = {
            login: login,
            date: new Date(),
            guid: response.data.guid,
            idUser: response.data.idTrader,
            idMarket: response.data.idMarket,
            idCurrency: response.data.idCurrency,
            lang: getLang(),
            idRole: response.data.idRole,
            kindRole: response.data.kindRole,
          }; //lang: response.data.language.toLowerCase(),
          localStorage.setItem("userInfo", JSON.stringify({ login, idAdres }));
          localStorage.setItem("session", JSON.stringify(user));
          resolve(user);
        } else {
          resolve(response);
        }
      });
  });
}

function initSession(reload = false) {
  return new Promise((resolve) => {
    var session = localStorage.getItem("session");
    if (
      session === undefined ||
      session === null ||
      session.guid === undefined
    ) {
      const loginInfoStr = localStorage.getItem("userInfo");
      let loginInfo = {};
      if (loginInfoStr?.length > 1) {
        loginInfo = JSON.parse(loginInfoStr);
      } else {
        resolve({ statusMessage: "Unknown user" });
        return;
      }
      const login = loginInfo.login;
      const idAdres = loginInfo.idAdres;
      // const idAdres = 6317;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ login: `${login}`, idAdres }),
      };

      return fetch(getAPIUrl("get_access"), requestOptions)
        .then(handleResponse)
        .then((response) => {
          if (response.data.guid) {
            setLang(response.data.language.toLowerCase(), false);
            const user = {
              login: login,
              date: new Date(),
              guid: response.data.guid,
              idUser: response.data.idTrader,
              idMarket: response.data.idMarket,
              idCurrency: response.data.idCurrency,
              lang: getLang(),
              idRole: response.data.idRole,
              kindRole: response.data.kindRole,
            }; //lang: response.data.language.toLowerCase(),
            localStorage.setItem(
              "userInfo",
              JSON.stringify({ login, idAdres })
            );
            localStorage.setItem("session", JSON.stringify(user));
            if (reload) {
              location.reload(true);
            }
            resolve(user);
          } else {
            resolve(response);
          }
        });
    } else {
      return resolve(session);
    }
  });
}

function getLang() {
  const langId = localStorage.getItem("langId");
  if (langId === undefined || langId === null || langId === "") {
    localStorage.setItem("langId", "en");
    return "en";
  }
  return langId;
}

function setLang(newValue, refresh = true) {
  localStorage.setItem("langId", newValue);
  if (refresh) initSession();
}

// function setLogin(login) {
//     localStorage.removeItem('session');
//     localStorage.setItem('login', login);
//     // return new Promise((resolve) => {

//     //     initSession(false).then(response => {
//     //         resolve(response);
//     //     });
//     // });
// }

function getSession() {
  const session = localStorage.getItem("session");
  // console.log('session:');
  // console.log(session);
  return JSON.parse(session);
}

function getCurrentUserRoleId() {
  const sessionStr = localStorage.getItem("session");
  // console.log('session:');
  // console.log(session);
  const session = JSON.parse(sessionStr);
  return session.idRole;
}

function logOut() {
  const sessionStr = localStorage.getItem("session");
  const session = JSON.parse(sessionStr);
  return new Promise((resolve) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ guid: session.guid }),
    };
    return fetch(getAPIUrl("del_access"), requestOptions)
      .then(handleResponse)
      .then((response) => {
        if (response.data !== null && response.isSuccess) {
          localStorage.clear();
          resolve(response);
        } else resolve(response);
      });
  });
}

function getRole() {
  const sessionStr = localStorage.getItem("session");
  const session = JSON.parse(sessionStr);
  return new Promise((resolve) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ guid: session.guid, idRole: session.idRole }),
    };
    return fetch(getAPIUrl("get_role"), requestOptions)
      .then(handleResponse)
      .then((response) => {
        if (response.data !== null && response.isSuccess) {
          resolve(response);
        } else resolve(response);
      });
  });
}
