<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div class="col-12">
        <VButton
          type="button"
          class="btn-outline-red mr-2"
          style="border: 2px solid #68bab5; color: #68bab5"
          @click="add"
          strKey="strAddCustomer"
        />
      </div>
      <div class="col-12">
        <DataTable
          :value="items"
          :paginator="true"
          :rows="50"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          dataKey="id"
          class="p-datatable-responsive-demo"
          sortField="lastUpdate"
          :sortOrder="-1"
        >
          <template #empty> {{ $t("strCustomersNotFound") }} </template>
          <template #loading> {{ $t("strCustomersLoading") }} </template>
          <Column
            field="firstName"
            :header="$t('strCustomerFirstName')"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{
                $t("strCustomerFirstName")
              }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.firstName }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['firstName']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            field="secondName"
            :header="$t('strCustomerSecondName')"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{
                $t("strCustomerSecondName")
              }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.secondName }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['secondName']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="name" :header="$t('strCustomerCompanyName')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{
                $t("strCustomerCompanyName")
              }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.name }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['name']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="nip" :header="$t('strCustomerTaxId')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strCustomerTaxId") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.nip }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['nip']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="place" :header="$t('strCustomerCity')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strCustomerCity") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.place }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['place']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="phone1" :header="$t('strCustomerPhone1')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strCustomerPhone1") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.phone1 }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['phone1']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <!-- <Column
            field="active"
            :header="$t('strActive')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strActive") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{
                  slotProps.data.active ? $t("strActiveYes") : $t("strActiveNo")
                }}
              </div>
            </template>
          </Column> -->
          <Column header="Akcja">
            <template #filter>
              <img
                src="@/assets/icons/vetrex-ikony/no_filter_outlined_32.svg"
                alt="clear"
                @click="clearFilters()"
                style="max-width: 24px; cursor: pointer"
              />
            </template>
            <template #body="row">
              <img
                src="@/assets/icons/edycja.svg"
                alt=""
                style="cursor: pointer; width: 20px; margin-right: 1.25rem"
                @click="edit(row.data)"
              />
              <!-- <router-link :to="{name: 'OfferEdit', params: { offerId: row.data.id }, query: { editMode: true } }">
                <img src="@/assets/icons/edycja.svg" alt="" style="cursor:pointer;width:20px"  />
              </router-link> -->
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      :header="customerEditHeader"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayEditCustomer"
      :style="{ width: '70%' }"
      :modal="true"
      @show="editCustomerOpened"
    >
      <customer-edit
        ref="customerEdit"
        v-on:customer-added="customerAdded"
        v-on:customer-updated="customerUpdated"
        :closeAddNewCustomer="closeEditCustomer"
      ></customer-edit>
    </Dialog>
  </div>
</template>

<script>
import { customerService } from "../../services/customer.service.js";
import { required, minLength } from "vuelidate/lib/validators";
import CustomerEdit from "@/components/customers/CustomerEdit";
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";

export default {
  name: "CustomersList",
  data() {
    return {
      items: [],
      types: [],
      loading: true,
      filters: {},
      displayEditDialog: false,
      editingItem: {},
      editingItemOrg: {},
      submitted: false,
      customerEditHeader: "",
      displayEditCustomer: false,
    };
  },
  validations: {
    editingItem: {
      name: { required, minLength: minLength(3) },
      // secondName: { required, minLength: minLength(3) },
      // street: {required, minLength: minLength(2)},
      // zipCode: {required, minLength: minLength(5)},
      // place: {required, minLength: minLength(2)},
      // phone1: {required, minLength: minLength(9)},
      // email: {required, email},
      // country: {required},
    },
  },
  methods: {
    loadItems: function() {
      //alert('loading');
      //this.child = null;
      var thisVue = this;
      thisVue.loading = true;
      customerService.getCustomers().then((response) => {
        thisVue.items = response.data;
        thisVue.loading = false;
        //thisVue.loadAssemblyTypes();
      });
    },
    // loadAssemblyTypes() {
    //   const thisVue = this;
    //   adminService.getAssemblyTypes().then((response) => {
    //     thisVue.types = response.data;
    //   });
    // },
    clearFilters() {
      this.filters = {};
    },
    edit(item) {
      //alert(item.name);
      //this.currentData = { ...this.basket };
      this.editingItem = { ...item };
      this.editingItemOrg = item;
      this.displayEditCustomer = true;
    },
    add() {
      this.editingItem = { id: 0 };
      //this.editingItem = { ...item, isActiveBoolean: ( this.editingItem.isActive === 1 ? true : false )};
      //this.editingItem.isActiveBoolean = this.editingItem.isActive === 1 ? true : false;
      this.displayEditCustomer = true;
    },
    closeEditCustomer() {
      this.displayEditCustomer = false;
    },
    saveEditAssembly() {
      this.displayEditCustomer = false;
    },
    editCustomerOpened() {
      if (this.editingItem.id === 0) this.$refs.customerEdit.addNewCustomer();
      else this.$refs.customerEdit.editCustomer(this.editingItem);
    },
    customerAdded(item) {
      this.items.push(item);
      this.displayEditCustomer = false;
      this.$toast.add({
        severity: "success",
        summary: this.$t("strCustomer"),
        detail: this.$t("strCustomerAdded"),
        life: 3000,
      });
    },
    customerUpdated(item) {
      Object.assign(this.editingItemOrg, item);
      this.displayEditCustomer = false;
      this.$toast.add({
        severity: "success",
        summary: this.$t("strCustomer"),
        detail: this.$t("strCustomerUpdated"),
        life: 3000,
      });
    },
  },
  mounted: function mounted() {
    this.loadItems();
  },
  components: {
    ActionsMenu,
    CustomerEdit,
    VButton,
  },
};
</script>
