<template>
  <div>
    <div v-if="data.length>8" style="position: absolute; left: 2%">
      <img
        src="@/assets/left-arrow.svg"
        alt="prev"
        @click="prevPage"
        :style="arrowCenter"
      />
    </div>
    <div v-if="data.length>8" style="position: absolute; right: 2%">
      <img
        src="@/assets/right-arrow.svg"
        alt="next"
        @click="nextPage"
        :style="arrowCenter"
      />
    </div>
    <!-- <configurator-item v-for="item in data" :key="item.id" :item="item" :level="4"></configurator-item> -->
    <div
      class="carousel slide"
      id="myCarousel"
      data-ride="carousel"
      data-interval="2000"
      data-pause="hover"
    >
      <div class="carousel-inner">
        <div
          v-for="(page, index) in pages"
          :key="index"
          class="item"
          :class="{
            active: currentPage === page && !isToggle,
            'active left': pageLeft === page,
            'next left': nextLeft === page,
            next: currentPage + 1 === page,
          }"
        >
          <configurator-item
            v-for="item in currentData[index]"
            :key="item.id"
            :item="item"
            :level="4"
          ></configurator-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfiguratorItem from "./ConfiguratorItem.vue";
export default {
  name: "ConfiguratorSlider",
  props: {
    data: Array,
  },
  data() {
    return {
      pages: 1,
      currentPage: 1,
      currentData: [],
      pageLeft: null,
      nextLeft: null,
      isToggle: false,
    };
  },
  methods: {
    nextPage: function () {
      if (this.currentPage < this.pages) {
        this.currentPage++;
      }
      this.isToggle = true;
      this.pageLeft = this.currentPage - 1;
      this.nextLeft = this.currentPage;
      setTimeout(() => {
        this.pageLeft = 9999;
        this.nextLeft = 9999;
        this.isToggle = false;
      }, 900);
    },
    prevPage: function () {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    setPage: function () {
      let a = this.currentPage * 8 - 8;
      let b = a + 8;
      this.currentData = this.data.slice(a, b);
    },
    loadData() {
      console.log(this.data)
      this.pages = Math.ceil(this.data.length / 8);
      // this.currentData = this.data.slice(0, 8);
      let index = 0;
      this.data.map((v, i) => {
        if (i % 8 == 0) {
          if (i > 0) index++;
          this.currentData[index] = [];
        }
        this.currentData[index].push(v);
      });
    },
  },

  mounted() {
    this.loadData();
  },
  computed: {
    arrowCenter() {
      let lastIndex = this.pages - 1;
      let mt = 32;
      if (this.currentData.length > 0)
        mt =
          this.currentPage === this.pages &&
          this.currentData[lastIndex].length < 5
            ? 10
            : 32;
      return {
        "margin-top": `${mt}rem`,
        width: "30px",
        cursor: "pointer",
      };
    },
  },
  components: {
    ConfiguratorItem,
  },
  watch: {
    data: {
      handler(newVal) {
        this.loadData(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script> 