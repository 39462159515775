<template>
  <div class="container">
      <div class="row">
          <div class="col-12">
              <h2>No access... </h2>
          </div>
      </div>
  </div>
</template>

<script>

export default {
  name: 'NoAccess',
}
</script>
