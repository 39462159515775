<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div class="col-12">
        <DataTable
          ref="dtOffersList"
          :value="offers"
          :paginator="true"
          :rows="50"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          dataKey="id"
          class="p-datatable-responsive-demo"
          sortField="lastUpdate"
          :sortOrder="-1"
          :rowClass="rowClass"
        >
          <template #empty> {{ $t("strEmptyOffers") }} </template>
          <template #loading> Loading offers data. Please wait. </template>
          <template #row="slotProps">
            <tr :style="{ backgroundColor: 'red'}">
              <td>
                <slot name="id" :data="slotProps.data"></slot>
              </td>
            </tr>
          </template>
          <Column
            v-if="checkRole('offerslistTableColOfferid')"
            field="id"
            :header="$t('strOrderId')"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strOrderId") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.id }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['id']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColName')"
            field="nameOrder"
            :header="$t('strNameOrder')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strNameOrder") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.nameOrder }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['nameOrder']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColClient')"
            field="customerAlias"
            :header="$t('strCustomer')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strCustomer") }}</span>
              <div
                style="cursor: default"
                @click="editCustomer(slotProps.data)"
                @dblclick="rowClick(slotProps.data)"
              >
                {{ slotProps.data.customerAlias }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['customerAlias']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColTrader')"
            field="trader"
            :header="$t('strTrader')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strTrader") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.trader }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['trader']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColDateofcreation')"
            field="dateOfCreation"
            :header="$t('strCreatedDate')"
            filterMatchMode="custom"
            :filterFunction="filterDate"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strCreatedDate") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.dateOfCreation | formatDate }}
              </div>
            </template>
            <template #filter>
              <Calendar
                v-model="filters['dateOfCreation']"
                class="p-column-filter calendar200"
                selectionMode="range"
                dateFormat="dd.mm.yy"
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColLastupdate')"
            field="lastUpdate"
            :header="$t('strLastUpdate')"
            filterMatchMode="custom"
            :filterFunction="filterDate"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strLastUpdate") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.lastUpdate | formatDate }}
              </div>
            </template>
            <template #filter>
              <Calendar
                id="offerListCalendar"
                v-model="filters['lastUpdate']"
                class="p-column-filter calendar200"
                selectionMode="range"
                dateFormat="dd.mm.yy"
              />
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColStatus')"
            field="orderStatus"
            :header="$t('strOrderStatus')"
            sortable
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strOrderStatus") }}</span>
              <div>
                <div
                  style="cursor: default"
                  @dblclick="rowClick(slotProps.data)"
                  :class="orderStatusClass(slotProps.data)"
                >
                  {{ slotProps.data.orderStatus }}
                </div>
              </div>
            </template>
            <template #filter>
              <Dropdown
                v-model="filters['orderStatus']"
                :options="statuses"
                placeholder=""
                class="p-column-filter"
                :showClear="true"
              >
                <template #option="slotProps">
                  <div>
                    <div style="cursor: default">{{ slotProps["option"] }}</div>
                  </div>
                </template>
              </Dropdown>
            </template>
          </Column>
          <Column
            v-if="checkRole('offerslistTableColActions')"
            :header="$t('strAction')"
          >
            <template #filter>
              <img
                src="@/assets/icons/vetrex-ikony/no_filter_outlined_32.svg"
                alt="clear"
                @click="clearFilters()"
                style="max-width:24px;cursor:pointer"
              />
            </template>
            <template #body="row">
              <span v-if="Number(row.data.value_k) !== 0">
              <VRole roleKey="offerslistTableBtnCopy">
                <img
                  src="@/assets/icons/vetrex-ikony/copy_outlined_32.svg"
                  alt=""
                  style="cursor:pointer;width:20px;margin-right:1.25rem"
                  @click="copyItem(row.data)"
                />
              </VRole>
            </span>
            <span v-if="Number(row.data.value_u) !== 0">
              <VRole roleKey="offerslistTableBtnDelete">
                <img
                  src="@/assets/icons/vetrex-ikony/trash_outlined_32.svg"
                  alt=""
                  style="cursor:pointer;width:20px;margin-right:1.25rem"
                  v-if="row.data.id_order_status < 4"
                  @click="setDialogDelete(row.data)"
                />
              </VRole>
              </span>
              <span v-if="Number(row.data.value_e) !== 0">
              <VRole roleKey="offerslistTableBtnEdit">
                <router-link
                  :to="{
                    name: 'OfferEdit',
                    params: { offerId: row.data.id },
                    query: { editMode: true },
                  }"
                >
                  <img
                    src="@/assets/icons/edycja.svg"
                    alt=""
                    style="cursor:pointer;width:20px"
                  />
                </router-link>
              </VRole>
              </span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t("qnOfferDelete") }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn-red"
          @click="
            displayDeleteDialog = false;
            dataToDelete = null;
          "
          strKey="strCancel"
        />
        <VButton
          class="btn-outline-red"
          @click="deleteOffer()"
          strKey="strDelete"
        />
      </div>
    </Dialog>
    <Dialog
      :header="$t('strCustomerEdit')"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayEditCustomer"
      :style="{ width: '70%' }"
      :modal="true"
      @show="editCustomerOpened"
    >
      <customer-edit
        ref="customerEdit"
        v-on:customer-updated="customerUpdated"
        :closeAddNewCustomer="closeEditCustomer"
      ></customer-edit>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { orderService } from "../../services/order.service.js";
import moment from "moment";
import ActionsMenu from "@/components/ActionsMenu";
import CustomerEdit from "@/components/customers/CustomerEdit";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";
export default {
  name: "OffersList",
  data() {
    return {
      offers: [],
      filters: {},
      loading: true,
      statuses: [],
      statusses: [],
      displayDeleteDialog: false,
      displayEditCustomer: false,
      dataToDelete: null,
      editingCustomer: {},
    };
  },
  computed: {
    ...mapState(["roles"]),
  },
  mounted: function() {
    this.getOrdersList();
  },
  methods: {
    rowClass(data) {
      console.log('data', data)
      const a = true;
        return [{ 'bg-primary': a }];
    },
    getOrdersList() {
      orderService.getOrders().then((response) => {
        if (response.data) this.offers = response.data;
        else this.offers = [];
        if (this.offers.length === 0) {
          this.$toast.add({
            severity: "warn",
            summary: this.$t("strEmptyOffers"),
            detail: "",
            life: 3000,
          });
        }
        this.offers.forEach((el) => {
          el.dateOfCreation = new Date(el.dateOfCreation);
          el.lastUpdate = new Date(el.lastUpdate);
        });
        this.loadStatusses();
        this.loading = false;
      });
    },
    rowClick: function() {
      //this.$router.push({ name: "OfferEdit", params: { offerId: offer.id } }); add offer as param to func
    },
    setDialogDelete: function(data) {
      this.dataToDelete = data;
      this.displayDeleteDialog = true;
    },
    deleteOffer: function() {
      let offer = this.dataToDelete;
      orderService.deleteOrder(offer.id).then((response) => {
        if (response.isSuccess === true) {
          let ind = this.offers.findIndex((i) => i.id === offer.id);
          this.offers.splice(ind, 1);
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
        }
        this.displayDeleteDialog = false;
      });
    },
    loadStatusses() {
      this.statuses = [...new Set(this.offers.map((obj) => obj.orderStatus))];
    },
    editOffer: function(offer) {
      //console.log(offer);
      //this.$router.push('OfferEdit', )
      this.$router.push({ name: "OfferEdit", params: { offerId: offer.id } });
      //alert(offer.id);
    },
    // loadStatusses(){
    //   dictionariesService.getOrderStatusses().then( response => {
    //     this.statuses= [];
    //     this.statusses = response.data;
    //     this.statusses.map(status => this.statuses.push(status.nameStatus))
    //   });
    // },
    filterDate(value, filter) {
      if (
        filter === undefined ||
        filter === null ||
        (typeof filter === "string" && filter.trim() === "")
      ) {
        return true;
      }

      if (value === undefined || value === null) {
        return false;
      }

      const myDate = moment(value, "DD-MM-YYYY");
      if (filter.length === 1 || filter[1] === null) {
        return myDate.isSameOrAfter(moment(filter[0]), "day");
      }
      if (filter.length === 2) {
        var valueMin = moment(filter[0]).add(-1, "d");
        var valueMax = moment(filter[1]).add(1, "d");
        return myDate.isBetween(valueMin, valueMax);
      }
      return false;
    },
    clearFilters() {
      this.filters = {};
    },
    orderStatusClass(data) {
      let _orderStatus = data.id_order_status;
      switch (_orderStatus) {
        case 2:
          return "badge blueBadge";
        case 5:
          return "badge greenBadge";
        case 4:
          return "badge azureBadge";
        case 1:
          return "badge yellowBadge";
        case 3:
          return "badge redBadge";
        default:
          return "badge fs10";
      }
      // return [
      //   {
      //     "badge yellowBadge": data.OrderStatus.toLowerCase() === 'wersja robocza',
      //     "badge blueBadge": data.OrderStatus.toLowerCase() === 'klient',
      //     "badge greenBadge": data.OrderStatus.toLowerCase() === 'gotowe',
      //     "badge redBadge": data.OrderStatus.toLowerCase() === 'wersja roboczaaaa',
      //   },
      // ];
    },
    editCustomer(item) {
      this.editingCustomer = item;
      this.displayEditCustomer = true;
    },
    editCustomerOpened() {
      this.$refs.customerEdit.editCustomer({
        id: this.editingCustomer.idCustomer,
      });
    },
    customerUpdated(item) {
      this.editingCustomer.customerAlias = item.alias;
      this.displayEditCustomer = false;
      this.$toast.add({
        severity: "success",
        summary: this.$t("strCustomer"),
        detail: this.$t("strCustomerUpdated"),
        life: 3000,
      });
    },
    closeEditCustomer() {
      this.displayEditCustomer = false;
    },
    checkRole(roleKey) {
      if (this.roles) {
        let role = this.roles.find((e) => e.key === roleKey);
        if (role) {
          return role.value === 1;
        } else return false;
      } else return false;
    },
    copyItem(item) {
      this.loading = true;
      orderService.duplicateOrder(item.id).then((response) => {
        if (response.isSuccess === true) {
          this.$toast.add({
            severity: "success",
            summary: this.$t("strPositionDuplicated"),
            detail: response.statusMessage,
            life: 3000,
          });
          this.offers = response.data;
          this.offers.forEach((el) => {
            el.dateOfCreation = new Date(el.dateOfCreation);
            el.lastUpdate = new Date(el.lastUpdate);
          });
          this.loading = false;
          this.$refs.dtOffersList.resetPage();
        } else {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: response.statusMessage,
          });
          this.loading = false;
        }
      });
    },
  },
  components: {
    ActionsMenu,
    CustomerEdit,
    VButton,
    VRole,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fs10 {
  font-size: 10px;
}
.badge {
  height: 30px;
  border-radius: 4px;
  opacity: 1;
  padding: 0;
  line-height: 30px;
  width: 100%;
}
.yellowBadge {
  background: #f4d03d 0% 0% no-repeat padding-box;
}
.azureBadge {
  background: #22bfb9;
}
.blueBadge {
  background: #4e4aad;
}
.greenBadge {
  background: #2ebf22;
}
.redBadge {
  background: #c61e1e;
}
@media screen and (max-width: 40em) {
  .badge {
    width: 60%;
  }
}
/* h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
} */
</style>
