<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ $t("strSettingsPrivateUser") }}
        </div>
      </div>
      <div class="col-12 offer__content">
        <div style="padding: 1rem 2.5rem; display: flex; flex-flow: column">
          <form>
            <div class="row" style="display: flex; flex-flow: column">
              <div class="col-lg-9" style="padding: 0">
                <div class="row" style="margin: 0">
                  <div class="col-md-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="firstName">
                        {{ $t("strUserFirstName") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <InputText
                        id="firstName"
                        class="offer--input"
                        type="text"
                        v-model="user.firstName"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="lastName">
                        {{ $t("strUserLastName") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <InputText
                        id="secondName"
                        class="offer--input"
                        type="text"
                        v-model="user.lastName"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-lg-9"
                style="border-bottom:1px solid #707070;padding:0;margin:15px"
              ></div>
            </div>

            <div class="row">
              <div class="col-lg-9" style="padding: 0">
                <div class="row" style="margin: 0">
                  <div class="col-lg-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="city">
                        {{ $t("strUserPhone") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <InputText
                        id="city"
                        class="offer--input"
                        type="tel"
                        v-model="user.phone"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="code">
                        {{ $t("strUserEmail") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <InputText
                        id="code"
                        class="offer--input"
                        type="text"
                        v-model="user.email"
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    class="col-lg-12"
                    style="border-bottom:1px solid #707070;padding:0;margin:15px"
                  ></div>
                </div>
                <div class="row" style="margin: 0">
                  <div class="col-lg-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="photo">
                        {{ $t("strUserPhoto") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <br />
                      <img
                        v-if="user !== undefined && user.photo !== undefined"
                        id="photo"
                        class="offer--input"
                        v-bind:src="'data:image/jpeg;base64,' + user.photo"
                      />
                      <input
                        type="file"
                        ref="file"
                        style="display: none"
                        accept="image/jpeg"
                        @change="loadPhotoFromFile"
                        v-if="editMode"
                      />

                      <VButton
                        v-if="editMode"
                        class="btn-outline-red mr-2"
                        @click="$refs.file.click()"
                        strKey="strPhotoSelect"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="my-2">
                      <label class="offer--input-label" for="shop">
                        {{ $t("strUserShop") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <Dropdown
                        id="shop"
                        class="offer--input"
                        v-model="userEdit.idShop"
                        :options="shops"
                        optionLabel="name"
                        optionValue="id"
                        :disabled="!editMode"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" style="margin: 0">
                  <div class="col-md-12">
                    <div class="my-2">
                      <label class="offer--input-label" for="signature">
                        {{ $t("strUserSignature") }}
                        <!-- <span style="color: red">*</span> -->
                      </label>
                      <InputText
                        id="signature"
                        class="offer--input"
                        type="text"
                        v-model="userEdit.signature"
                        :disabled="!editMode"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 offer-footer toRight d-flex mt-2">
              <VButton
                v-if="editMode"
                type="button"
                class="btn-outline-red"
                style="border: 2px solid #68bab5; color: #68bab5;margin-right:1rem"
                @click="cancel"
                strKey="strCancel"
              />
              <VButton
                v-if="editMode"
                type="button"
                class="btn-red"
                style="background: #68bab5; border: 2px solid #68bab5"
                @click="save"
                strKey="strSave"
              />
              <VButton
                v-if="!editMode"
                type="button"
                class="btn-outline-red"
                style="border: 2px solid #68bab5; color: #68bab5;margin-right:1rem"
                @click="editMode = true"
                strKey="strEdit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminService } from "../../services/admin.service";
// import { required, minLength, email } from "vuelidate/lib/validators";
// import { orderService } from "../../services/order.service.js";
// import { productService } from "../../services/product.service.js";
// import VariantItem from "@/components/offers/VariantItem";
// import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";
// import ProductEdit from "@/components/product/ProductEdit";
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";

export default {
  name: "User",
  data() {
    return {
      submitted: false,
      user: {},
      userEdit: {},
      shops: [],
      editMode: false,
    };
  },
  // validations: {
  //   user: {
  //      firstName: { required, minLength: minLength(3) },
  //   //   secondName: { required, minLength: minLength(3) },
  //   //   street: { required, minLength: minLength(2) },
  //   //   zipCode: { required, minLength: minLength(5) },
  //   //   place: { required, minLength: minLength(2) },
  //   //   phone1: { required, minLength: minLength(9) },
  //      email: { required, email },
  //   //   country: { required },
  //   },
  // },
  methods: {
    loadUser() {
      const thisVue = this;
      adminService.getCurrentUser().then((response) => {
        thisVue.user = response.data[0];
        thisVue.userOrg = { ...thisVue.user };
        thisVue.userEdit = { ...response.data[0] };
      });
      adminService.getShops().then((response) => {
        thisVue.shops = response.data;
      });
    },
    loadPhotoFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => this.setPhoto(e.target.result);
      reader.readAsDataURL(file);
    },
    setPhoto(photo) {
      this.userEdit.photo = photo.replace("data:image/jpeg;base64,", "");
      this.user.photo = photo.replace("data:image/jpeg;base64,", "");
    },
    save: function() {
      this.submitted = true;
      //this.$v.$touch();
      this.user = { ...this.userEdit };
      adminService.updateUser(this.user).then((response) => {
        if (response.isSuccess) {
          this.editMode = false;
          this.$toast.add({
            severity: "success",
            summary: this.$t("strUser"),
            detail: this.$t("strUserUpdated"),
            life: 3000,
          });
        }
      });
    },
    cancel() {
      this.editMode = false;
      this.userEdit = { ...this.user };
    },
  },
  mounted: function mounted() {
    this.loadUser();
  },
  watch: {},
  components: { ActionsMenu, VButton },
};
</script>
