<template>
  <div class="container">
    <div class="row" v-if="empty === false && product !== undefined">
      <div class="col-12">
        <div class="row">
          <div class="col-4">
            <span>{{ $t("strPosition") }}: {{ product.idPosition }}</span>
            <span
              >{{ $t("strPositionQuantity") }} {{ product.noPosition }}</span
            >
          </div>
          <div class="col-4">
            <span>{{ product.price }}zł</span>
          </div>
          <div class="col-4">
            <VButton class="btn-red" strKey="strShowPriceDetails" />
            <VButton class="btn-red" strKey="strCloseConfi" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from "../global/VButton.vue";
export default {
  name: "ProductEdit",
  data() {
    return {
      empty: true,
      product: {},
    };
  },
  methods: {
    assignProduct: function(product) {
      console.log("assigningProduct");
      this.product = { ...product };
      console.log(product);
    },
    clearProduct: function() {
      this.empty = true;
      this.product = {};
    },
    loadNewProduct: function(newProductResponse) {
      this.productInitResponse = newProductResponse;
      this.assignProduct(newProductResponse);
      //console.log('newProduct:');
      //console.log(newProductResponse);
      this.empty = false;
    },
  },
  components: {
    VButton,
  },
};
</script>
