<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div
        class="col-12"
        style="display:flex;margin-bottom:1.5rem;justify-content:right"
      >
        <VButton
          type="button"
          class="btn-outline-red mr-2"
          style="border: 2px solid #68bab5; color: #68bab5"
          @click="add"
          strKey="strAddAssembly"
        />
      </div>
      <div class="col-12">
        <DataTable
          :value="items"
          :paginator="true"
          :rows="50"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          dataKey="id"
          class="p-datatable-responsive-demo"
          sortField="lastUpdate"
          :sortOrder="-1"
        >
          <template #empty> {{ $t("strAssembliesNotFound") }} </template>
          <template #loading> {{ $t("strAssembliesLoading") }} </template>
          <Column field="name" :header="$t('strAssemblyName')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strAssemblyName") }}</span>
              <div style="cursor: default" @dblclick="edit(slotProps.data)">
                {{ slotProps.data.name }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['name']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column
            field="assemblyType"
            :header="$t('strAssemblyType')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strAssemblyType") }}</span>
              <div style="cursor: default" @dblclick="edit(slotProps.data)">
                {{ slotProps.data.assemblyName }}
              </div>
            </template>
            <template #filter>
              <Dropdown
                v-model="filters['assemblyName']"
                :options="types"
                placeholder=""
                class="p-column-filter"
                optionLabel="name"
                optionValue="id"
                :showClear="true"
              >
              </Dropdown>
            </template>
          </Column>
          <Column
            field="price"
            :header="$t('strPrice')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strPrice") }}</span>
              <div style="cursor: default" @dblclick="edit(slotProps.data)">
                {{ slotProps.data.price }}
              </div>
            </template>
          </Column>

          <Column
            field="active"
            :header="$t('strActive')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strActive") }}</span>
              <div style="cursor: default" @dblclick="edit(slotProps.data)">
                {{
                  slotProps.data.isActive === 1
                    ? $t("strActiveYes")
                    : $t("strActiveNo")
                }}
              </div>
            </template>
          </Column>

          <Column header="Akcja">
            <template #filter>
              <img
                src="@/assets/icons/vetrex-ikony/no_filter_outlined_32.svg"
                alt="clear"
                @click="clearFilters()"
                style="max-width: 24px; cursor: pointer"
              />
            </template>
            <template #body="row">
              <img
                src="@/assets/icons/edycja.svg"
                alt=""
                style="cursor: pointer; width: 20px; margin-right: 1.25rem"
                @click="edit(row.data)"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayEditDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div style="padding: 1rem 2.5rem; display: flex; flex-flow: column">
        <form>
          <div class="row" style="display: flex; flex-flow: column">
            <div class="col-lg-12" style="padding: 0">
              <div class="row" style="margin: 0">
                <div class="col-md-12">
                  <div class="my-2">
                    <label class="offer--input-label" for="name">
                      {{ $t("strAssemblyName") }}
                      <span style="color: red">*</span>
                    </label>
                    <InputText
                      id="name"
                      class="offer--input"
                      type="text"
                      v-model="editingItem.name"
                    />
                    <div
                      v-if="submitted && $v.editingItem.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.editingItem.name.required">{{
                        $t("strAssemblyNameRequired")
                      }}</span>
                      <span v-if="!$v.editingItem.name.minLength">{{
                        $t("strAssemblyNameMinLength")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 0">
                <div class="col-md-12">
                  <div class="my-2">
                    <label class="offer--input-label" for="assemblyType">
                      {{ $t("strAssemblyType") }}
                      <span style="color: red">*</span>
                    </label>
                    <Dropdown
                      id="assemblyType"
                      class="offer--input"
                      v-model="editingItem.assemblyType"
                      :options="types"
                      optionLabel="name"
                      optionValue="id"
                    />
                    <div
                      v-if="submitted && $v.editingItem.assemblyType.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.editingItem.assemblyType.required">{{
                        $t("strAssemblyNameRequired")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 0">
                <div class="col-md-12">
                  <div class="my-2">
                    <label class="offer--input-label" for="price">
                      {{ $t("strAssemblyPrice") }}
                      <span style="color: red">*</span>
                    </label>

                    <input
                      class="vetrex-inpt"
                      type="number"
                      showButtons
                      style="width: 100%"
                      v-model="editingItem.price"
                    />
                    <div
                      v-if="submitted && $v.editingItem.price.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.editingItem.price.required">{{
                        $t("strAssemblyNameRequired")
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" style="margin: 0" v-if="editingItem.id > 0">
                <div class="col-md-9">
                  <div class="my-2">
                    <label class="offer--input-label" for="isActive">
                      {{ $t("strAssemblyActive") }}
                      <span style="color: red">*</span>
                    </label>
                    <InputSwitch
                      v-model="editingItem.isActiveBoolean"
                      id="isActive"
                      style="display:block"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="col-md-12 offer-footer toRight d-flex"
            style="padding:0;margin-top: 2.5rem;"
          >
            <VButton
              type="button"
              class="btn btn-outline-red mr-2"
              style="border: 2px solid #68bab5; color: #68bab5"
              @click="closeEditAssembly"
              strKey="strCancel"
            />

            <VButton
              type="button"
              class="btn-red"
              style="background: #68bab5; border: 2px solid #68bab5"
              @click="saveEditAssembly"
              strKey="strSave"
            />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { adminService } from "../../services/admin.service.js";
import { required, minLength } from "vuelidate/lib/validators";
// import { translationsService } from "../../services/translations.service.js";
// import Vue from 'vue';
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";

export default {
  name: "AssembliesList",
  data() {
    return {
      items: [],
      types: [],
      loading: true,
      filters: {},
      displayEditDialog: false,
      editingItem: {},
      submitted: false,
    };
  },
  validations: {
    editingItem: {
      name: { required, minLength: minLength(3) },
      assemblyType: { required },
      price: { required },
      // secondName: { required, minLength: minLength(3) },
      // street: {required, minLength: minLength(2)},
      // zipCode: {required, minLength: minLength(5)},
      // place: {required, minLength: minLength(2)},
      // phone1: {required, minLength: minLength(9)},
      // email: {required, email},
      // country: {required},
    },
  },
  methods: {
    loadItems: function() {
      //alert('loading');
      //this.child = null;
      var thisVue = this;
      thisVue.loading = true;
      adminService.getAssembliesList().then((response) => {
        if (Number(response.statusCode) === 200) thisVue.items = response.data;
        else thisVue.items = [];
        thisVue.loading = false;
        thisVue.loadAssemblyTypes();
      });
    },
    loadAssemblyTypes() {
      const thisVue = this;
      adminService.getAssemblyTypes().then((response) => {
        thisVue.types = response.data;
      });
    },
    clearFilters() {
      this.filters = {};
    },
    edit(item) {
      //alert(item.name);
      //this.currentData = { ...this.basket };
      this.editingItemOrg = item;
      this.editingItem = {
        ...item,
        isActiveBoolean: this.editingItem.isActive === 1 ? true : false,
      };
      this.editingItem.isActiveBoolean =
        this.editingItem.isActive === 1 ? true : false;
      this.displayEditDialog = true;
    },
    add() {
      //alert(item.name);
      //this.currentData = { ...this.basket };
      this.editingItem = { id: 0, name: "", assemblyType: 0, price: 0 };
      //this.editingItem = { ...item, isActiveBoolean: ( this.editingItem.isActive === 1 ? true : false )};
      //this.editingItem.isActiveBoolean = this.editingItem.isActive === 1 ? true : false;
      this.displayEditDialog = true;
    },
    closeEditAssembly() {
      this.displayEditDialog = false;
    },
    saveEditAssembly() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const thisVue = this;
      if (this.editingItem.id === 0) {
        this.editingItem.isActive = 0;
        adminService.addAssembly(this.editingItem).then((response) => {
          console.log(response);
          if (response.isSuccess === true) {
            //this.$emit('customer-added', response.data);
            this.items.push(response.data[0]);
            this.$toast.add({
              severity: "success",
              summary: this.$t("strAssembly"),
              detail: this.$t("strAssemblyAdded"),
              life: 3000,
            });
            this.displayEditDialog = false;
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strAssembly"),
              detail: response.statusMessage,
            });
          }
        });
      } else {
        this.editingItem.isActive = this.editingItem.isActiveBoolean ? 1 : 0;
        adminService.updateAssembly(this.editingItem).then((response) => {
          //console.log(response);
          if (response.isSuccess === true) {
            //this.$emit('customer-added', response.data);
            thisVue.editingItemOrg.name = response.data[0].name;
            thisVue.editingItemOrg.assemblyType = response.data[0].assemblyType;
            thisVue.editingItemOrg.price = response.data[0].price;
            thisVue.editingItemOrg.isActive = response.data[0].isActive;
            this.$toast.add({
              severity: "success",
              summary: this.$t("strAssembly"),
              detail: this.$t("strAssemblyUpdated"),
              life: 3000,
            });
            this.displayEditDialog = false;
          } else {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strPosition"),
              detail: response.statusMessage,
            });
          }
        });
      }
      //this.displayEditDialog = false;
    },
  },
  mounted: function mounted() {
    this.loadItems();
  },
  components: {
    ActionsMenu,
    VButton,
  },
};
</script>
