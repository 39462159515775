import { handleResponse, getAPIUrl, getSession } from '../helpers';
//import { userService } from './user.service';

export const adminService = {
     getTranslations,
     getAssembliesList,
     getAssemblyTypes,
     addAssembly,
     updateAssembly,
     getShops,
     getShop,
     getCurrentUser,
     getCountries,
     getDeliveryDays,
     addShop,
     updateShop,
     deleteShop,
     updateUser,
     getPriceSettings,
     updatePriceSettings,

    getEmptyPositions,
    addEmptyPosition,
    updateEmptyPosition
};

function getTranslations() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid })
            };
            return fetch(getAPIUrl('get_translator'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getShops() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid })
            };
            return fetch(getAPIUrl('get_shopaddresslist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}


function getShop(idAddress) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idAddress })
            };
            return fetch(getAPIUrl('get_shopaddress'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getCurrentUser() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid })
            };
            return fetch(getAPIUrl('get_privatesettings'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getAssembliesList() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid })
            };
            return fetch(getAPIUrl('get_sldrassemblylist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getAssemblyTypes() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
            return fetch(getAPIUrl('get_assemblytypes'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}


function addAssembly(item) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            item.guid = session.guid;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            };
            return fetch(getAPIUrl('set_assembly'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function updateAssembly(item) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            item.idAssembly = item.id;
            item.guid = session.guid;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(item)
            };
            return fetch(getAPIUrl('update_assembly'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getDeliveryDays(){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };

            return fetch(getAPIUrl('get_deliverydays'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getCountries(){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };

            return fetch(getAPIUrl('get_cntrylist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function addShop(shop) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            shop.guid = session.guid;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(shop)
            };
            return fetch(getAPIUrl('set_shopaddress'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    console.log(error);
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function updateShop(shop) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            shop.guid = session.guid;
            shop.idAddress = shop.id;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(shop)
            };
            return fetch(getAPIUrl('update_shopaddress'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function deleteShop(idAddress) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({guid: session.guid, idAddress: idAddress})
            };
            return fetch(getAPIUrl('del_shopaddress'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function updateUser(user) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            user.guid = session.guid;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(user)
            };
            return fetch(getAPIUrl('update_privatesettings'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getPriceSettings() {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid})
            };
            return fetch(getAPIUrl('get_privatepricesettings'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function updatePriceSettings(markup) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, markup})
            };
            return fetch(getAPIUrl('update_privatepricesettings'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getEmptyPositions() {
    const idFPosition = 0;
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idFPosition })
            };
            return fetch(getAPIUrl('get_fpositionlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function addEmptyPosition(name, priceBuy,priceSell,quantity,notes, isActive) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, name, priceBuy,priceSell,quantity,notes, isActive: Number(isActive)})
            };
            return fetch(getAPIUrl('set_addfposition'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function updateEmptyPosition(idFPosition, name, priceBuy,priceSell,quantity,notes, isActive) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idFPosition, name, priceBuy,priceSell,quantity,notes, isActive: Number(isActive)})
            };
            return fetch(getAPIUrl('update_fposition'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}
