<template>
  <div
    style="background: #fff;width:584px;min-height:98vh;position:fixed;right:20px;z-index:999;box-shadow: -9px -9px 34px #00000039;top: 10px"
  >
    <div class="row" style="margin:0">
      <div
        class="col-xs-6 extraProductsHeader_item"
        v-bind:class="tab === 0 ? 'active' : ''"
      >
      </div>
      <div
        class="col-xs-6 extraProductsHeader_item"
        v-bind:class="tab === 1 ? 'active' : ''"
      >
        {{ $t("strEmptyItem") }}
      </div>
      <div class="col-xs-2" style="padding-left:5%">
        <img
          src="@/assets/icons/arrow.svg"
          alt=""
          style="cursor: pointer; width: 12px;margin-left: 1.5rem;"
          @click="hide()"
        />
      </div>
      <div v-if="tab === 1">
        <div class="row" style="margin-top:20px">
          <div class="col-xs-10" style="padding-right:10%;text-align:right">
            <span style="margin-right:1.5rem">{{ $t("strName") }}</span>
            <InputText
              id="serachExtraProductAvailable"
              class="offer--input"
              type="text"
              style="max-width:50%"
              v-model="searchText"
              @keyup="search()"
            />
            <span style="cursor:pointer;margin-left:1rem" @click="clearSearch()"
              >&#10005;</span
            >
          </div>
        </div>
        <div
          id="ExtraProductsItemAvailable"
          class="row"
          v-if="
            tab === 1 &&
              data !== undefined &&
              data !== null &&
              data.length !== undefined
          "
        >
          <div v-for="item in dataToDisplay" :key="item.id" :id="'EmptyPosition_' + item.id">
            <OverlayPanel 
                :ref="'op_' + item.id"                
                appendTo="body" 
                :showCloseIcon="true"
                id="overlay_panel"
                style="width: 350px; height: auto">
                <EmptyPositionForm :variantId="variantId" :item="item" @onSubmitForm="onSubmitForm" :editMode="false" />
            </OverlayPanel>
            <div class="col-xs-12" style="margin-top:20px;padding-right:7%">
              <div class="col-md-1"></div>
              <div
                class="col-md-11"
                style="box-shadow: 0px 3px 13px #00000022;min-height:175px;display:flex;align-items:center"
              >
                <div style="display:flex;width:100%">
                  <div style="width:140px;"></div>
                  <div
                    style="margin-left:19px;flex:1;display: flex;flex-flow: column;justify-content: space-between;gap: 7rem"
                  >
                    <div>
                      <div class="col-md-11">
                        <div>
                          <span style="font-size:12px;font-weight:bold">{{
                            item.name
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        class="col-xs-6"
                        style="height:100%;display:flex;justify-content:flex-end;flex-flow:column"
                      >
                      </div>
                      <div class="col-xs-6" style="text-align:right">
                        <!-- <product-input ref="productInput"></product-input> -->
                        <VButton
                          style="border:2px solid #ececec;border-radius: 4px;background:#fff;font-size:16px;width:95px;margin-top:.5rem;min-height:31px;color:#777777"
                          @click="toggleForm($event, item)"
                          strKey="strAdd"
                          :id="'EmptyPositionButton_' + item.id"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xs-12"
            v-if="dataToDisplay.length === 0"
            style="margin-top:5rem;padding-right:7%;text-align: center"
          >
            {{ $t("strNoItemsToDisplay") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
import VButton from "../global/VButton.vue";
import EmptyPositionForm from "@/components/offers/EmptyPositionForm";

export default {
components: { VButton, EmptyPositionForm },
  props: {
    variantId: Number,
  },
  name: "EmptyPosition",
  data() {
    return {
      tab: 1,
      data: [],
      dataToDisplay: [],
      searchText: "",
      searchTimeout: null,
    };
  },
  methods: {
    hide: function() {
      this.$emit("hideEmptyPositions");
    },
    loadEmptyPositionsList: function(idTechnology) {
      this.loadingIdTechnology = idTechnology;
      orderService.getVariantEmptyPositions().then((response) => {
        const { data } = response;
        this.data = [...data];
        this.dataToDisplay = [...data];
      });
    },
    search() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        let val = this.searchText;
        if (val !== "") {
          const stringSearch = val.trim().toLowerCase();
          let terms = stringSearch.split("*");
          terms = terms.filter((item) => item);
          let filterData = this.data.filter((x) =>
            terms.every((d) =>
              x.name
                .trim()
                .toLowerCase()
                .includes(d.trim())
            )
          );
          this.dataToDisplay = [...filterData];
        } else {
          this.dataToDisplay = [...this.data];
        }
      }, 500);
    },
    clearSelectedSearch() {
      this.searchText = "";
      this.dataToDisplay = [...this.data];
    },
    toggleForm(event, item) {
        const el = this.$refs["op_" + item.id];
        el[0].toggle(event);
    },
    onSubmitForm(id) {
        const el = this.$refs["op_" + id];
        el[0].toggle();
        this.$emit("loadVariantPositions");
    }
  },
  mounted: function mounted() {
    this.loadEmptyPositionsList();
  },
};
</script>

<style>
</style>
