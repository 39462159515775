<template>
  <div class="container">
    <ActionsMenu :isInValid="checkValid()" :inValidValue="'strCancelChanges'" />
    <div class="row">
      <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ $t("strOfferNe") }}
        </div>
      </div>
      <div class="col-12 offer__content">
        <form @submit.prevent="saveForm">
          <div class="row">
            <div class="col-lg-10">
              <div class="col-lg-3 col-md-6">
                <label class="offer--input-label" for="name">{{
                  $t("strOfferName")
                }}</label>
                <InputText
                  id="name"
                  class="offer--input"
                  type="text"
                  v-model="offer.name"
                  :class="{ 'is-invalid': submitted && $v.offer.name.$error }"
                  maxLength="40"
                />
                <div
                  v-if="submitted && $v.offer.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.offer.name.required">{{
                    $t("strOfferNameRequired")
                  }}</span>
                  <span v-if="!$v.offer.name.minLength">{{
                    $t("strOfferNameMinLength")
                  }}</span>
                  <span v-if="!$v.offer.name.minLength">{{
                    $t("strOfferNameValid")
                  }}</span>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="d-flex" @click="toggleCustomerValid">
                  <div style="width:70%">
                    <label class="offer--input-label" for="inputtext5"
                      >Klient</label
                    >
                    <Dropdown
                      id="customers"
                      class="offer--input"
                      :class="{
                        'is-invalid': submitted && $v.offer.idCustomer.$error,
                      }"
                      v-model="offer.idCustomer"
                      :options="customers"
                      optionLabel="alias"
                      optionValue="id"
                      style="padding: .8rem 0 .8rem 0;"
                      :filter="true"
                      :filterPlaceholder="$t('strSearch')"
                      :placeholder="$t('strCustomerSelect')"
                      :showClear="true"
                    />
                    <div
                      v-if="submitted && $v.offer.idCustomer.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.offer.idCustomer.required">{{
                        $t("strCustomerRequired")
                      }}</span>
                      <span v-if="!$v.offer.idCustomer.between">{{
                        $t("strCustomerRequired")
                      }}</span>
                    </div>
                  </div>
                  <div class="d-flex ml-2">
                    <VButton
                      type="button"
                      class="btn-outline-red"
                      style="height:36px;min-width:70px;margin-top:2.5rem"
                      @click="showAddNewCustomer"
                      icon="+"
                      strKey="strAdd"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 offer-footer toRight d-flex mt-xs-6">
              <VButton
                class="btn-outline-red mr-2"
                type="button"
                @click="closePage()"
                strKey="strCancel"
              />
              <VButton class="btn-red" type="submit" strKey="strOfferSave" />
            </div>
          </div>
        </form>
      </div>
    </div>
    <Dialog
      header="Nowy klient"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayNewCustomer"
      :style="{ width: '70%' }"
      :modal="true"
      @show="newCustomerOpened"
    >
      <customer-edit
        ref="customerEdit"
        :closeAddNewCustomer="closeAddNewCustomer"
      ></customer-edit>
    </Dialog>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDialogCancel"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t("strCancelChanges") }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn btn-red"
          @click="displayDialogCancel = false"
          strKey="strCancel"
        />
        <VButton class="btn btn-outline-red" @click="exit()" strKey="strExit" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { customerService } from "../../services/customer.service.js";

import ActionsMenu from "@/components/ActionsMenu";
import CustomerEdit from "@/components/customers/CustomerEdit";
import { orderService } from "../../services/order.service.js";

import {
  required,
  minLength,
  between,
  maxLength,
} from "vuelidate/lib/validators";
import VButton from "../global/VButton.vue";

export default {
  name: "OfferNew",
  data() {
    return {
      submitted: false,
      offer: {
        name: "",
      },
      displayNewCustomer: false,
      displayDialogCancel: false,
      customers: [],
      customerInValid: false,
      pageTitle: "",
    };
  },
  validations: {
    offer: {
      name: { required, minLength: minLength(5), maxLength: maxLength(40) },
      idCustomer: { required, between: between(1, 1000000) },
    },
  },
  mounted: function mounted() {
    this.loadCustomers();
    // let t = this.$t('strOfferNe');
    // this.pageTitle = t === 'strOfferNe' ? 'Nowa oferta' : t;
  },
  methods: {
    saveForm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.saveOrder();
    },
    saveOrder() {
      orderService
        .saveNewOrder(this.offer.name, this.offer.idCustomer)
        .then((response) => {
          this.$router.push({
            name: "OfferEdit",
            params: { offerId: response.data.id },
          });
        })
        .catch(() => {
          this.$toast.add({
            severity: "error",
            summary: this.$t("strOffer"),
            detail: this.$t("erOfferNewSaveError"),
          });
        });
    },
    loadCustomers: function() {
      var thisVue = this;
      customerService.getCustomers().then((response) => {
        thisVue.customers = response.data.filter((i) => i.alias !== null);
        //console.log(thisVue.customers);
      });
    },
    showAddNewCustomer() {
      this.displayNewCustomer = true;
    },
    closeAddNewCustomer() {
      this.displayNewCustomer = false;
    },
    newCustomerOpened() {
      this.$refs.customerEdit.addNewCustomer();
    },
    toggleCustomerValid() {
      this.customerInValid = false;
    },
    customerAdded(customer) {
      this.displayNewCustomer = false;
      this.customers.push(customer);
      this.offer.idCustomer = customer.id;
    },
    exit() {
      this.$router.push(`/`);
    },
    closePage() {
      if (this.offer.name !== "") {
        this.displayDialogCancel = true;
      } else {
        this.$router.push(`/`);
      }
    },
    checkValid() {
      return this.offer.name !== "";
    },
  },
  components: {
    ActionsMenu,
    CustomerEdit,
    VButton,
  },
};
</script>
