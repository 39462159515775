<template>
  <div class="container">
    <div class="row">
      <div class="col-12 offer__content">
        <form @submit.prevent="saveForm">
          <div class="row">
            <div class="col-lg-5">
              <div class="col-lg-3 col-md-6">
                <label class="offer--input-label" for="inputtext1">{{
                  $t("strNameOrder")
                }}</label>
                <InputText
                  id="inputtext1"
                  class="offer--input"
                  type="text"
                  v-model="variant.name"
                  required
                />
              </div>
            </div>
            <div class="col-md-12 offer-footer toRight d-flex">
              <VButton
                class="btn-outline-red mr-2"
                @click="deleteOffer()"
                strKey="strCancel"
              />
              <VButton class="btn-red" type="submit" strKey="strAddVariant" />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { orderService } from "../../services/order.service.js";
import VButton from "../global/VButton.vue";

export default {
  name: "VariantNew",
  data() {
    return {
      nameInvalid: false,
      variant: { name: "" },
      offer: {},
    };
  },
  // mounted: function mounted() {
  //   //this.loadCustomers();
  //   // this.value1 = this.$route.params.offerId;
  //   // orderService.getOrder(this.value1).then(response => {
  //   //   this.response = response;
  //   //   this.offer = response[0];
  //   //   console.log('getOrder');
  //   //   console.log(response);
  //   //   console.log('offer:');
  //   //   console.log(this.offer);
  //   // });
  // },
  methods: {
    saveForm() {
      if (this.variant.name) {
        this.saveVariant();
      } else {
        this.nameInvalid = true;
      }
    },
    addNewVariant(offer) {
      this.offer = offer;
    },
    saveVariant() {
      orderService.addVariant(this.offer.id).then((response) => {
        this.$parent.addNewVariant(response.data);
      });
    },
  },
  components: { VButton },
};
</script>
