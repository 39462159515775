<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
      <SelectButton v-model="selectedLang" :options="langs" />
      <button class="btn btn-primary float-right" @click="displayNewTranslation=true">Add</button>
      </div>
 </div>
 <div class="row">
      <div class="col-12">
        <DataTable
          :value="translations"
          :paginator="true"
          :rows="50"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          dataKey="id"
          class="p-datatable-responsive-demo"
          sortField="key" 
          :sortOrder="-1"
          editMode="row" :editingRows.sync="editingRows"
        @row-edit-init="onRowEditInit" @row-edit-cancel="onRowEditCancel" @row-edit-save="onRowEditSave"
        >
          <template #empty> No translations found. </template>
          <template #loading> Loading translations data. Please wait. </template>
          <Column field="key" header="Klucz" sortable>
            <template #body="slotProps">
              <span class="p-column-title">Klucz</span>
              <div style="cursor: default" @dblclick="edit(slotProps.data)">{{ slotProps.data.key }}</div>
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['key']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="value" header="Wartość" sortable>
            <!-- <template #body="row">
              <span  class="p-column-title">Wartość</span>
               <div v-if="row.data.isEditing !== true" style="cursor: default" @dblclick="edit(row.data)">{{ row.data.value }}</div>

 <InputText v-if="row.data.isEditing === true"
                style="max-width:100%"
                type="text"
                v-model="row.data.value"
                placeholder=""
              />
              {{ row.data.isEditing}}
            </template> -->
            <template #editor="slotProps">
                <InputText v-model="slotProps.data[slotProps.column.field]" />
            </template>
            <template #filter>
              <InputText
                style="max-width:100%"
                type="text"
                v-model="filters['value']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
            
          </Column>
          <Column :rowEditor="true" headerStyle="width:7rem" bodyStyle="text-align:center"></Column>
        </DataTable>
      </div>
    </div>

    <Dialog
      header="Nowe tłumaczenie"
      :visible.sync="displayNewTranslation"
      :style="{ width: '90%', height: '90%' }"
      :modal="true"
    >
      <div class="row">
         <div class="col-lg-6">
            <div class="col-lg-3 col-md-6">
              <label class="offer--input-label" for="newItemKey">Key</label>
              <InputText id="newItemKey" class="offer--input" type="text" v-model="newItem.key" />
            </div>
            <div class="col-lg-3 col-md-6">
              <label class="offer--input-label" for="newItemValue">Value</label>
              <InputText id="newItemValue" class="offer--input" type="text" v-model="newItem.value" />
            </div>

        </div>

      </div>
      <div class="row">
         <div class="col-lg-6">
           <button class="btn btn-primary" @click="displayNewTranslation=false">Cancel</button>
           <button class="btn btn-primary float-right" @click="saveNewItem">Save</button>
        </div>
      </div>
      
    </Dialog>

  </div>
</template>

<script>
import { adminService } from "../../services/admin.service.js";
import { userService } from "../../services/user.service.js";
import { translationsService } from "../../services/translations.service.js";
import Vue from 'vue';
// import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";

export default {
  name: "TranslationsEditor",
  data() {
    return {
      langs: ['pl', 'it', 'en'],
      items: [],
      response: {},
      selectedLang: 'en',
      translations: [],
      filters: {},
      loading: true,
      currentEditingId: 0,
      editingRows: [],
      displayNewTranslation: false,
      newItem : { key: '', value: ''}
    };
  },
  originalRows: null,
  created() {
        this.originalRows = {};
    },
  methods: {
   
    loadItems: function () {
      this.child = null;

      var thisVue = this;
      adminService.getTranslations().then((response) => {
        thisVue.items = response.data;
        thisVue.loading = false;
        thisVue.loadLangs();
        thisVue.loadTranslations(thisVue.selectedLang);
      });
    },
    loadTranslations(lang){
      if(this.items === undefined || this.items === null)
        this.translations = [];
      this.translations = this.items.filter(i => i.lang === lang);
      this.loading = false;
    },
    loadLangs(){
       this.langs = [...new Set(this.items.map(x => x.lang))];
    },
     onRowEditInit(event) {
            this.originalRows[event.index] = {...this.translations[event.index]};
        },
      onRowEditCancel(event) {
            Vue.set(this.translations, event.index, this.originalRows[event.index]);
        },
    edit(item){
      //alert(item.id);
      const tr = this.getTranslation(item.id);
      //this.currentEditingId = item.id;
      console.log('found:');
      console.log(tr);
      tr.isEditing = true;
      tr.orgValue = item.value;
      console.log(item);
    },
    save(item){
      alert(item.value);
    },
    getTranslation(id){
      console.log(`searching: ${id}`);
      return this.translations.filter(i => i.id === id)[0];
    },
    cancelEditing(item){
      item.value = item.orgValue;
      item.isEditing = false;
    },
    onRowEditSave(event){
      translationsService.updateTranslation(event.data.lang, event.data.key, event.data.value).then(response => {
        console.log(response);
      });
    },
    saveNewItem(){
      const thisVue = this;
       translationsService.addTranslation(this.selectedLang, this.newItem.key, this.newItem.value).then(response => {
         console.log('added: ');
        console.log(response);
        thisVue.items.push(response);
        thisVue.loadTranslations(thisVue.selectedLang);
        thisVue.displayNewTranslation = false;
      });
    }

  },
  watch: {
    selectedLang: function (val) {
      this.loadTranslations(val);
    }
  },
   mounted: function mounted() {
    const session = userService.getSession();
    if(session !== null)
      this.selectedLang = session.lang;
    this.loadItems();
  },
  components: {
  },
};
</script>

