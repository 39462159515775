<template>
  <div class="col-lg-3 col-md-6 configuratorItem" @click="open()" :class="{ 'active': this.selected || this.notSelected}" style="margin-bottom: 68px;">
    <div style="box-shadow: 6px 6px 10px #00000029;" :style="this.selected? 'border: 4px solid #68bab5;':''">
      <img
        :src="`${item.graphicURL}`"
        :alt="item.name"
        class="configuratorItem__img"
        style="display:inline-block;height:375px;background:#fff"
        :style="level===1?'object-fit:cover':'object-fit: contain;'"
      />
      <div class="configuratorItem__desc">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>

import { productService } from "../../services/product.service.js";

export default {
  name: "ConfiguratorItem",
  props: {
    item: {},
    level: Number,
    selected: Boolean,
    notSelected: Boolean
  },
  methods: {
    open: function(){
      if(this.level == 4){
        productService.getNewProduct(this.item.idWindowPosition, this.$route.params.idVariant).then(response => {
          if(response.isSuccess)
            this.$router.push(`/configurator/product/${response.data.idTechnology},${response.data.id},${Number(this.$route.params.idVariant)},${Number(this.$route.params.idOffer)},false`);
          else
            this.$toast.add({severity:'error', summary: this.$t('strConfigurator'), detail:response.statusMessage});
        });
        
      }
      if(this.level < 4)
        this.$parent.selectItem(this.level, this.item)
    },
    itemImage: function(){
      return typeof this.item.img !== 'undefined' ?  this.item.graphicURL : 'window.png';
    }
  },
};
</script>