import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const productService = {
    getInitialProduct,
    getPositionDetail,
    getAvailableAccessories,
    getSchema,
    getBasket,
    getSliders,
    getPriceDetails,
    getNewProduct,
    addToOffer,
    setBasket,
    getBasketReset,
    updatePosition,
};

function getBasket(idPosition, isFromGallery = 0) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, isFromGallery, idPosition })
            };

            return fetch(getAPIUrl('get_basket'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function setBasket(idPosition, basket, globalError) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, data: basket })
            };

            return fetch(getAPIUrl('set_basket'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    if (response.statusMessage === 'Internal Server Error') {
                        response.statusMessage = globalError;
                    }
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function getNewProduct(idSchema, idVariant) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idSchema, idVariant })
            };

            return fetch(getAPIUrl('get_posfromgallery'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                })
                .catch(error => {
                    resolve({ isSuccess: false, statusMessage: error });
                });
        });
    });
}

function getPriceDetails(idPosition) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, idPosition })
            };

            return fetch(getAPIUrl('get_pricedetail'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSliders(idTechnology) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, idTechnology })
            };

            return fetch(getAPIUrl('get_sldrmainlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getInitialProduct(treeId) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, categoryTreeId: treeId })
            };

            return fetch(getAPIUrl('get_initial_product'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getPositionDetail(idPosition) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, isFromGallery: 0, language: session.lang })
            };

            return fetch(getAPIUrl('get_posdetail'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getSchema(idPosition) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, isFromGallery: 0 })
            };

            return fetch(getAPIUrl('get_wndschema'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getAvailableAccessories(idTechnology) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idTechnology: idTechnology, language: session.lang })
            };

            return fetch(getAPIUrl('get_sldrkitaccessories'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function addToOffer(idPosition) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, language: session.lang })
            };

            return fetch(getAPIUrl('set_addtooffer'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function getBasketReset(idPosition, idVariant) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, idVariant: idVariant, language: session.lang })
            };

            return fetch(getAPIUrl('get_basketreset'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}

function updatePosition(idPosition, orderNotes){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({guid: session.guid, idPosition: idPosition, orderNotes: orderNotes})
            };
            return fetch(getAPIUrl('update_position'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    //console.log(response);
                    resolve(response);
                });
        });
    });
}
