import { userService } from '../services/user.service.js';
import { getAPIUrl } from '../helpers';

export function getSessionGuid() {

    return new Promise((resolve) => {

        const url = getAPIUrl('test');
        if(url.includes('/moq/')){
            resolve('24714DAA-16FA-4322-9566-8E7F48EE3105');
            return;
        }
        var session = localStorage.getItem('session');
        if (session === undefined || session === null) {
            userService.initSession().then(response => {
                //console.log(`session null, new session: ${response.data.guid}`);
                //console.log(response);
                resolve(response.guid);
            });
        }
        else {
            //console.log(session);
            var obj = JSON.parse(session);
            //console.log(session);
            //console.log(`session exists : ${session}, obj: ${obj}`);
            return resolve(obj.guid);
        }
    });
}

export function getSession() {

    return new Promise((resolve) => {

        const url = getAPIUrl('test');
        if(url.includes('/moq/')){
            resolve('24714DAA-16FA-4322-9566-8E7F48EE3105');
            return;
        }
        var session = localStorage.getItem('session');
        if (session === undefined || session === null) {
            userService.initSession().then(response => {
                //console.log(`session null, new session: ${response.data.guid}`);
                //console.log(response);
                resolve(response);
            });
        }
        else {
            //console.log(session);
            var obj = JSON.parse(session);
            //console.log(session);
            //console.log(`session exists : ${session}, obj: ${obj}`);
            return resolve(obj);
        }
    });
}