<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ $t("strSettingsPrice") }}
        </div>
        <div></div>
      </div>
      <div class="col-12 offer__content">
        <div style="display: flex; flex-flow: column">
          <form>
            <div class="row" style="display: flex; flex-flow: column">
              <div class="col-lg-9" style="padding: 0">
                <div class="row" style="margin: 0">
                  <VRole roleKey="settingsPricesInptMarkup">
                    <div class="col-md-6">
                      <div class="my-2">
                        <label class="offer--input-label" for="markup">
                          {{ $t("strPricingMarkup") }}
                          <span style="color: red">*</span>
                        </label>
                        <!-- <InputNumber
                    showButtons
                    style="width: 100%"
                    v-model="data.markup"
                  /> -->
                        <input
                          class="vetrex-inpt"
                          type="number"
                          showButtons
                          style="width: 100%"
                          v-model="data.markup"
                          :disabled="!editMode"
                        />
                        <div
                          v-if="submitted && $v.data.markup.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.data.markup.required">{{
                            $t("strPricingMarkupRequired")
                          }}</span>
                          <span v-if="!$v.data.markup.minValue">{{
                            $t("strPricingMarkupMinValue")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </VRole>
                  <VRole roleKey="settingsPricesInptDiscount">
                    <div class="col-md-6">
                      <div class="my-2">
                        <label class="offer--input-label" for="discount">
                          {{ $t("strPricingDiscount") }}
                          <span style="color: red">*</span>
                        </label>
                        <div style="display: flex; align-items: center">
                          <InputText
                            id="discount"
                            class="offer--input"
                            type="text"
                            v-model="data.discount"
                            disabled
                          />
                          <div
                            style="
                              width: 30px;
                              height: 30px;
                              border: 2px solid black;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                              border-radius: 30px;
                              margin-left: 0.5rem;
                            "
                          >
                            <span>i</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </VRole>
                </div>
              </div>
            </div>

            <div class="col-md-12 offer-footer toRight d-flex mt-2">
              <VButton
                v-if="editMode"
                type="button"
                class="btn-outline-red"
                style="
                  border: 2px solid #68bab5;
                  color: #68bab5;
                  margin-right: 1rem;
                "
                @click="cancel"
                strKey="strCancel"
              />

              <VButton
                v-if="editMode"
                type="button"
                class="btn-red"
                style="background: #68bab5; border: 2px solid #68bab5"
                strKey="strSave"
                @click="save"
              />
              <VButton
                v-if="!editMode"
                type="button"
                class="btn-outline-red"
                style="
                  border: 2px solid #68bab5;
                  color: #68bab5;
                  margin-right: 1rem;
                "
                @click="editMode = true"
                strKey="strEdit"
                roleKey="settingsPricesBtnEdit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { adminService } from "../../services/admin.service";
import { required, minValue } from "vuelidate/lib/validators";
// import { orderService } from "../../services/order.service.js";
// import { productService } from "../../services/product.service.js";
// import VariantItem from "@/components/offers/VariantItem";
// import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";
// import ProductEdit from "@/components/product/ProductEdit";
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";

export default {
  name: "Pricing",
  data() {
    return {
      submitted: false,
      data: {},
      editMode: false,
      markup: "",
    };
  },
  validations: {
    data: {
      markup: { required, minValue: minValue(0) },
    },
  },
  methods: {
    cancel() {
      this.editMode = false;
      this.data.markup = this.markup;
    },
    loadData() {
      const thisVue = this;
      adminService.getPriceSettings().then((response) => {
        thisVue.data = response.data;
        this.markup = response.data.markup;
      });
    },
    save: function() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) return;
      this.editMode = false;
      adminService.updatePriceSettings(this.data.markup).then((response) => {
        if (response.isSuccess) {
          this.markup = this.data.markup;
          this.$toast.add({
            severity: "success",
            summary: this.$t("strPricing"),
            detail: this.$t("strPricingMarkupUpdated"),
            life: 3000,
          });
        }
      });
    },
  },
  mounted: function mounted() {
    this.loadData();
  },
  watch: {},
  components: { ActionsMenu, VButton, VRole },
};
</script>
