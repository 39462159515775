<template>
  <div class="row">
    <!-- <div class="col-xs-12">
      <SelectButton
        v-model="selectedGroup"
        :options="selectedItems"
        optionLabel="groupName"
        optionValue="groupId"
      />
    </div> -->
    <div class="col-xs-12" style="padding-left: 0">
      <div style="display: flex">
        <div
          style="
            flex: 1;
            font-size: 2rem;
            align-items: center;
            justify-content: center;
            display: flex;
            backgorund: #fff;
            z-index: 2;
          "
        >
          <span style="cursor: pointer" @click="toRight">&lt;</span>
        </div>
        <div
          style="
            display: flex;
            margin-top: 1rem;
            margin-bottom: 1rem;
            overflow-x: hidden;
            flex: auto;
            z-index: 1;
          "
          id="sliderProductTypes"
        >
          <div
            :class="[
              selectedGroup === null ? 'productTabSelected' : 'productTab',
            ]"
            style="display: flex; align-items: center; display: block"
            @click="selectedGroup = null"
          >
            <span>{{ $t("strZoneAll") }}</span>
          </div>
          <div
            v-for="item in selectedItems"
            :key="item.groupId"
            @click="selectedGroup = item.groupId"
            :class="[
              selectedGroup === item.groupId
                ? 'productTabSelected'
                : 'productTab',
            ]"
            style="display: flex; align-items: center; display: block"
          >
            <img
              v-if="checkImg(item.groupId) !== ''"
              :src="checkImg(item.groupId)"
              :alt="item.groupId + '_img'"
              style="width: 25px"
            />
            <span v-else>
              <span v-if="Number($t('str' + item.groupId)) > 0">Z</span
              >{{ $t("str" + item.groupId) }}
            </span>
          </div>
        </div>
        <div
          style="
            flex: 1;
            font-size: 2rem;
            align-items: center;
            justify-content: center;
            display: flex;
            backgorund: #fff;
            z-index: 2;
          "
        >
          <span style="cursor: pointer" @click="toLeft">&gt;</span>
        </div>
      </div>
    </div>
    <div class="col-xs-12" style="margin-top: 1rem">
      <div
        v-show="selectedGroup === null || group.groupId === selectedGroup"
        class="row"
        v-for="group of selectedItems"
        v-bind:key="group.groupId"
        style="margin-bottom: 5rem; overflow-x: hidden"
      >
        <div class="row groupElement" style="margin-bottom: 0.75rem;max-width: 100%;">
          <div
            class="col-xs-12"
            style="display: flex; justify-content: space-between;padding-right: 0;"
          >
            <div class="groupName">
              <span v-if="Number($t(group.groupName)) > 0">Z</span
              >{{ $t(group.groupName) }}
            </div>
            <div>
              <VButton
                v-if="showAddButton(group)"
                :id="group.groupName"
                @click="addElement(group)"
                v-show="group.groupId.startsWith('Z') === false"
                class="float-right"
                :style="selectButton"
                style="margin-bottom: 1rem"
                strKey="strAdd"
              />
              <VButton
                @click="deleteSash(group)"
                v-show="group.shopDeleteButtonsSash === true"
                class="float-right"
                :style="selectButton"
                style="margin-bottom: 1rem; margin-right: 1rem"
                strKey="strWindowDeleteFittingSash"
              />
              <VButton
                @click="deleteFitting(group, false)"
                v-show="group.shopDeleteButtonsFitting === true"
                class="float-right"
                :style="selectButton"
                style="margin-bottom: 1rem"
                strKey="strWindowDeleteFitting"
              />
            </div>
          </div>
        </div>
        <div v-if="group.elements.length < 1">
          {{ $t("strNoItemsToDisplay") }}
        </div>
        <div v-for="(item, index) of group.elements" v-bind:key="index">
          <div v-if="item.id === 0 && item.elementName === 'SashPost'"></div>
          <div
            v-else
            :id="item.title + '_' + group.groupId"
            :style="item.id > 0 ? productsElement : productsElementDanger"
          >
            <div class="row" style="display: flex; width: 100%">
              <div
                v-if="!item.graphicUrl"
                class="col-xs-3"
                style="text-align: center; padding-left: 0"
              ></div>
              <div
                v-if="item.graphicUrl"
                class="col-xs-3"
                style="text-align: center; padding-left: 0"
              >
                <img
                  v-if="item.id > 0"
                  :id="'productsElement_' + item.id"
                  :src="item.graphicUrl"
                  style="height: 48px; width: 48px"
                  @click="toggleImg($event, item.id)"
                />
                <img
                  v-else
                  src="@/assets/raw-svg/exclamation-triangle.svg"
                  style="height: 48px; width: 48px"
                />
                <OverlayPanel
                  :ref="'opi_' + item.id"
                  :appendTo="'#productsElement_' + item.id"
                  :showCloseIcon="true"
                  id="overlay_panel"
                  style="
                    width: 250px;
                    height: auto;
                    background: #fff;
                    z-index: 1000;
                    position: relative;
                  "
                >
                  <img
                    :src="item.graphicUrl"
                    style="width: 100%; height: auto"
                  />
                </OverlayPanel>
              </div>
              <div class="col-xs-7">
                <div style="">
                  <div>
                    {{ $t(item.title.replace(/\d+/g, '')) }}
                    {{ extractNumber(item.title) }}
                  </div>
                  <div
                    style="
                      font-size: 16px;
                      font-weight: bold;
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    {{ item.id > 0 ? item.name : $t("strSliderNotSelected") }}
                    <product-element-required-validation
                      v-show="false"
                      v-if="
                        item.verificationRequiredInfo !== undefined &&
                          item.verificationRequiredInfo !== null &&
                          item.verificationRequiredInfo.state !== undefined &&
                          item.verificationRequiredInfo.state !== null
                      "
                      :info="item.verificationRequiredInfo"
                      :group="group"
                      :item="item"
                    ></product-element-required-validation>
                    <div v-if="group.groupId === 'Accessories'">
                      <span>
                        {{ item.quantity }}({{
                          item.quantity * basket.quantity
                        }})
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-2">
                <div style="display: flex; align-items: flex-end">
                  <div>
                    <VButton
                      :style="item.id > 0 ? selectButton : disabledButton"
                      :title="
                        canSliderItemBeChanged(item.elementName, group.groupId)
                      "
                      :disabled="
                        canSliderItemBeChanged(
                          item.elementName,
                          group.groupId
                        ) !== '' || Number(item.id) < 1
                      "
                      @click="
                        setParentTabName(item.title);
                        actionClicked(group, item);
                      "
                      :strKey="
                        item.id === 0
                          ? 'strSelect'
                          : group.groupId.startsWith('Z')
                          ? 'strChange'
                          : 'strDelete'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("strDeleteAsk") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />
        <VButton
          class="btn-outline-red"
          @click="deleteAction()"
          strKey="strDelete"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { productSlidersService } from "../../services/product.sliders.service";
import ProductElementRequiredValidation from "./ProductElementRequiredValidation.vue";
import VButton from "../global/VButton.vue";

export default {
  name: "ProductSelectedElements",
  props: {
    changeZone: Function,
    setTabName: Function,
    basket: {},
    positionDetails: {},
  },
  data() {
    return {
      selectedItems: [],
      selectedGroup: null,
      sliderPage: 1,
      displayDeleteDialog: false,
      groupToDelete: null,
      itemToDelete: null,
    };
  },
  methods: {
    extractNumber(title){
      const regex = /\d+/;
      const matches = title.match(regex)
      return matches ? matches[0] : null;
    },
    toLeft() {
      let el = document.getElementById("sliderProductTypes");
      let items = el.children;
      let max = items.length - 3;
      for (let i = 0; i < items.length; i++) {
        if (i >= max) break;
        let item = items[i];
        if (item.style.display === "block") {
          item.style.display = "none";
          break;
        }
      }
    },
    toRight() {
      let el = document.getElementById("sliderProductTypes");
      let items = el.children;
      for (let i = items.length - 1; i >= 0; i--) {
        let item = items[i];
        if (item.style.display === "none") {
          //console.log(item);
          item.style.display = "block";
          break;
        }
      }
    },
    loadItems(basket) {
      this.selectedItems = [];

      if (
        this.basket === undefined ||
        this.basket === null ||
        this.basket.idColorI === undefined
      ) {
        return;
      }

      //frame
      let group = {
        groupId: "Z0",
        groupName: "strSliderZ0",
        elements: [],
        filtredData: [],
        search: false,
      };

      group.elements.push({
        title: this.$t("strSliderNameColorIn"),
        elementName: "ColorIn",
        id: basket.idColorI,
        name: basket.nameColorI,
        graphicUrl: basket.graphicURLI,
      });
      group.elements.push({
        title: this.$t("strSliderNameColorEx"),
        elementName: "ColorEx",
        id: basket.idColorEx,
        name: basket.nameColorEx,
        graphicUrl: basket.graphicURLEx,
        verificationRequiredInfo: basket.colorexVerificationRequired,
      });
      group.elements.push({
        title: this.$t("strSliderNameColorC"),
        elementName: "ColorC",
        id: basket.idColorC,
        name: basket.nameColorC,
        graphicUrl: basket.graphicURLC,
        verificationRequiredInfo: basket.colorcVerificationRequired,
      });
      group.elements.push({
        title: this.$t("strSliderGasket"),
        elementName: "Gasket",
        id: basket.idColorGasketIn,
        idEx: basket.idColorGasketEx,
        name: basket.nameGasket,
        graphicUrl: basket.graphicURL,
        verificationRequiredInfo: basket.gasketVerificationRequired,
      });
      group.elements.push({
        title: this.$t("strFrame"),
        elementName: "Frame",
        id: basket.idFrame,
        name: basket.nameFrame,
      });
      this.selectedItems.push(group);

      //zones
      basket.zone.forEach((zone) => {
        let group = {
          groupId: zone.zoneName,
          groupName: `strSlider${zone.zoneName}`,
          elements: [],
        };

        if (zone.zoneItems?.length > 0) {
          zone.zoneItems.forEach((it) => {
            if (
              it.itemName === "Sash" ||
              (it.itemName === "Fitting" && it.id === 0)
            ) {
              return;
            } else {
              group.elements.push({
                title: this.$t(`strSliderName${it.itemName}${it.kindItem}`),
                elementName: it.itemName,
                id: it.id,
                kindItem: it.kindItem,
                name: it.name,
                graphicUrl: it.graphicURL,
                verificationRequiredInfo: it.VerificationRequired,
              });
            }
          });
        }

        const idFitting = zone.zoneItems?.find((i) => i.itemName === "Fitting")
          ?.id;
        //const idSash = zone.zoneItems?.find((i) => i.itemName === "Sash")?.id;
        if ((idFitting ?? 0) > 0) {
          group.shopDeleteButtonsFitting = true;
        }

        const idSash = zone.zoneItems?.find((i) => i.itemName === "Sash")?.id;
        if ((idSash ?? 0) > 0) {
          group.shopDeleteButtonsSash = true;
        }

        this.selectedItems.push(group);
      });

      // assemblies
      group = {
        groupId: "Assembly",
        groupName: "strSliderAssemblies",
        elements: [],
      };
      if (basket.assemblies?.length > 0) {
        basket.assemblies.forEach((element) => {
          if (element.id > 0) {
            group.elements.push({
              title: this.$t("strSliderAssembly"),
              elementName: "Assembly",
              id: element.id,
              name: element.name,
            });
          }
        });
      }
      this.selectedItems.push(group);

      // services
      group = {
        groupId: "Service",
        groupName: "strSliderServices",
        elements: [],
      };
      if (basket.services?.length > 0) {
        basket.services.forEach((element) => {
          group.elements.push({
            title: this.$t("strSliderService"),
            elementName: "Service",
            id: element.id,
            name: element.name,
          });
        });
      }
      this.selectedItems.push(group);

      // accessories
      group = {
        groupId: "Accessories",
        groupName: "strSliderAccessories",
        elements: [],
      };
      if (basket.accessories?.length > 0) {
        basket.accessories.forEach((element) => {
          group.elements.push({
            title: this.$t("strSliderAccessory"),
            elementName: "Accessory",
            id: element.id,
            name: element.name,
            quantity: element.amount,
            totalQuantity: element.amount * this.basket.amount,
            assembly: element.assembly,
          });
        });
      }
      this.selectedItems.push(group);

      // roller shutter
      group = {
        groupId: "rollerShutter",
        groupName: "strRollerShutters",
        elements: [],
      };
      if (basket.rollerShutter?.length > 0) {
        basket.rollerShutter.forEach((element) => {
          // ROLETY MATRYCA!!!
          if (element.id > 0) {
            group.elements.push({
              title: this.$t("strRollerShutters"),
              elementName: "rollerShutter",
              id: element.id,
              name: element.name,
            });
          }
        });
      }
      this.selectedItems.push(group);
    },
    deleteFitting(group, deleteSash) {
      const zone = this.basket.zone.find((i) => i.zoneName === group.groupId);
      var fittingElement = zone.zoneItems.find((i) => i.itemName === "Fitting");
      fittingElement.id = 0;
      if (deleteSash === true) {
        var sashElement = zone.zoneItems.find((i) => i.itemName === "Sash");
        sashElement.id = 0;
      }
      this.$parent.$parent.calculate(false, true);
    },
    deleteSash(group) {
      const zone = this.basket.zone.find((i) => i.zoneName === group.groupId);
      let sashElement = zone.zoneItems.find((i) => i.itemName === "Sash");
      sashElement.id = 0;
      this.$parent.$parent.calculate(false, true);
    },
    checkImg(zone) {
      let img = "";
      let name = "";
      if (zone === "Assembly" || zone === "Service" || zone === "Accessories")
        name = zone.toLowerCase();
      if (name !== "") img = require(`@/assets/icons/${name}.svg`);
      return img;
    },
    showAddButton(group) {
      if (group.groupId === "Assembly") return group.elements.length === 0;
      return true;
    },
    addElement(group) {
      const { groupName } = group;
      this.$parent.setLastEl(groupName);
      this.$parent.setTabName(groupName);
      this.$parent.itemClicked(group, null);
    },
    setParentTabName(name) {
      this.$parent.setTabName(name);
    },
    canSliderItemBeChanged(elementName, zoneId) {
      return productSlidersService.canSliderItemBeChanged(
        elementName,
        this.basket,
        zoneId,
        this.$parent.selectedItemKind
      );
    },
    actionClicked(group, item) {
      if (!group.groupId.startsWith("Z")) {
        this.displayDeleteDialog = true;
        this.groupToDelete = group;
        this.itemToDelete = item;
      } else {
        this.$parent.setLastEl(item.title + "_" + group.groupId);
        this.$parent.itemClicked(group, item);
      }
    },
    deleteAction() {
      this.$parent.itemClicked(this.groupToDelete, this.itemToDelete);
      this.displayDeleteDialog = false;
      this.groupToDelete = null;
      this.itemToDelete = null;
    },
    canShow(index) {
      let lastEl = this.sliderPage * 2 - 1;
      return index === lastEl || index === lastEl - 1;
    },
    setSliderPage(direction) {
      if (direction === 0 && this.sliderPage - 1 > 0) {
        this.sliderPage = this.sliderPage - 1;
      }
      if (direction === 1) {
        let max = Math.ceil(this.selectedItems.length / 2);
        if (max > this.sliderPage + 1) this.sliderPage = this.sliderPage + 1;
      }
    },
    toggleImg(event, id) {
      const el = this.$refs["opi_" + id];
      el[0].toggle(event);
    },
  },
  // mounted: function(){
  //     const thisVue = this;
  //     productSlidersService.getSlidersList(49).then(slidersResponse => {
  //         thisVue.sliders = slidersResponse.data;
  //         console.log('sliders:');
  //         console.log(slidersResponse);
  //     });
  // },
  computed: {
    // productsElementsWindow() {
    //     return {
    //         "min-height": "70vh",
    //         // "box-shadow": "-2px -2px 6px #0000001A",
    //         "background": "#FFFFFF",
    //         "border-left":"1px solid rgba(0, 0, 0, 0.1)"
    //     };
    // },
    productsElement() {
      return {
        "box-shadow": "0px 3px 13px #00000022",
        padding: "2rem 3rem",
        "margin-bottom": "1.5rem",
        cursor: "pointer",
        "min-height": "98px",
        "align-items": "center",
        display: "flex",
      };
    },
    productsElementDanger() {
      return {
        "box-shadow": "0px 3px 13px #00000022",
        padding: "2rem 3rem",
        "margin-bottom": "1.5rem",
        cursor: "not-allowed",
        "min-height": "98px",
        "align-items": "center",
        display: "flex",
        border: "1px solid rgb(198, 30, 30)",
      };
    },
    // productsHeader(){
    //     let display = this.displayDetails ? 'block':'none';
    //     return {
    //         'margin': 0,
    //         'display': display
    //     };
    // },
    // elementsList(){
    //     let display = this.displayDetails ? 'none':'block';
    //     return {
    //         'display': display
    //     };
    // },
    // detailsList(){
    //     let display = this.displayDetails ? 'block':'none';
    //     return {
    //         'display': display,
    //         "margin-top": "20px"
    //     };
    // },
    selectButton() {
      return {
        background: "#c61e1e",
        color: "#fff",
        border: "none",
        "font-weight": "normal",
        "font-size": "14px",
        padding: ".5rem 1rem",
        "border-radius": ".5rem",
      };
    },
    disabledButton() {
      return {
        background: "gray",
        color: "#fff",
        border: "none",
        "font-weight": "normal",
        "font-size": "14px",
        padding: ".5rem 1rem",
        "border-radius": ".5rem",
      };
    },
  },
  watch: {
    basket: {
      handler(newVal) {
        this.loadItems(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  components: { ProductElementRequiredValidation, VButton },
};
</script>
