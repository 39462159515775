<template>
  <div class="row actionsMenu">
    <div class="col-xs-6 d-flex">
      <VRole roleKey="headerBtnList">
        <img
          src="@/assets/icons/vetrex-ikony/list_outlined_32.svg"
          alt=""
          class="listIcon"
          @click="setPage('/')"
        />
      </VRole>
      <div>
        <VButton
          class="btn-red-outline"
          @click="setPage('/new-offer')"
          strKey="strOfferNe"
          roleKey="headerBtnNewoffer"
        />
      </div>
    </div>
    <div class="col-xs-6 d-flex toRight actionsMenu_r">
      <VRole roleKey="headerBtnSettings">
        <div style="display:flex;align-items:center" @click="toggle">
          <img
            src="@/assets/icons/vetrex-ikony/settings_outlined_32.svg"
            alt=""
            class="listIcon"
          />
          <span class="hidden-xs " style="margin-left:.5rem;cursor:pointer">{{
            $t("strSettings")
          }}</span>
        </div>
      </VRole>
      <VRole roleKey="headerBtnStats">
        <div style="display:flex;align-items:center">
          <img
            src="@/assets/icons/vetrex-ikony/statistics_outlined_32.svg"
            alt=""
            class="listIcon"
          />
          <span class="hidden-xs" style="margin-left:.5rem">{{
            $t("strStats")
          }}</span>
        </div>
      </VRole>
    </div>
    <Menu ref="menu" :model="getMenuItems()" :popup="true" />
    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayInValidDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="display: flex;padding: 5rem; justify-content: center; font-size: 20px;"
      >
        {{ $t(inValidValue) }}
      </div>
      <div
        style="display: flex;justify-content: space-between;padding: 0 2rem 1rem 2rem;"
      >
        <VButton
          class="btn-red"
          @click="displayInValidDialog = false"
          strKey="strCancel"
        />
        <VButton class="btn-outline-red" @click="exit" strKey="strExit" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VButton from "./global/VButton.vue";
import VRole from "./global/VRole.vue";
export default {
  name: "ActionsMenu",
  props: {
    isInValid: Boolean,
    inValidValue: String,
  },
  data() {
    return {
      displayInValidDialog: false,
      pageToOpen: "/",
      items: [],
    };
  },
  computed: {
    ...mapState(["roles"]),
  },
  methods: {
    getMenuItems() {
      const roleKeys = [
        "settingsItemGlobal",
        "settingsItemPrivate",
        "settingsItemPrice",
        "settingsItemConfigurator",
        "settingsItemPositions",
      ];
      const roles = this.roles.filter((e) => roleKeys.includes(e.key));
      const menu = [];
      roles.map((role, index) => {
        if (role.value === 1) menu.push(this.getMenuItem(index));
      });
      menu.push(this.getMenuItem(4))
      return menu;
    },
    getMenuItem(index) {
      const menuItems = [
        {
          label: this.$t("strSettingsGlobal"),
          command: () => {
            this.setPage("/admin/company");
          },
        },
        {
          label: this.$t("strSettingsPrivate"),
          command: () => {
            this.setPage("/admin/user");
          },
        },
        {
          label: this.$t("strSettingsPrice"),
          command: () => {
            this.setPage("/admin/pricing");
          },
        },
        {
          label: this.$t("strConfiguratorAssembly"),
          command: () => {
            this.setPage("/admin/assemblies");
          },
        },
        {
          label: this.$t("strConfiguratorPosition"),
          command: () => {
            this.setPage("/admin/empty-position");
          },
        },
      ];
      return menuItems[index];
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    setPage(page) {
      this.pageToOpen = page;
      if (typeof this.isInValid === "undefined" || this.isInValid)
        this.displayInValidDialog = true;
      else this.$router.push(page);
    },
    exit() {
      this.$router.push(this.pageToOpen);
    },
  },
  components: {
    VButton,
    VRole,
  },
};
</script>
