import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const dictionariesService = {
    getUnloadingList,
    getWeldList,
    getCurrList,
    getOrderStatusses,
    getTaxes
};

function getUnloadingList() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_unloadinglist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getWeldList() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_weldlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getCurrList() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_currlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getOrderStatusses() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_statuslist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    console.log(response.data);
                    resolve(response);
                });
        });
    });
}

function getTaxes() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_taxlist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}




