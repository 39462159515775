<template>
    <span v-if="display">
        <slot></slot>
    </span>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "VRole",
  props: {
    roleKey: String,
  },
  data() {
    return {
      display: true,
    };
  },
  computed: {
    ...mapState(["roles"]),
  },
  mounted() {
    this.checkRole();
  },
  methods: {
    checkRole: function() {
      if (this.roles) {
        let role = this.roles.find((e) => e.key === this.roleKey);
        if (role) {
          if (role.value === 1) this.display = true;
          else this.display = false;
        }
      }
    },
  },
  watch: {
    roles: function() {
      this.checkRole();
    },
  },
};
</script>
