<template>
  <div class="container" style="padding: 1rem 2rem">
    <div class="row" style="width: 1200px;overflow-y: auto;">
      <div class="col-md-12 d-flex mt-xs-6">
        <VButton
          type="button"
          class="btn-outline-red mr-2"
          @click="closePriceDetails"
          strKey="strBack"
        />
      </div>
      <div
        class="col-md-12"
        style="font-size: 14px; margin-top: 2.5rem; padding-bottom: 1.5rem"
      >
        <div class="row">
          <div class="col-md-12" style="border-bottom: 1px solid #000">
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strKind')}}
            </div>
            <div
              class="col-xs-3"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strName')}}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strPricePiece')}}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strAccessoryQuantity')}}
            </div>
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strNet')}}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              VAT
            </div>
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{$t('strGross')}}
            </div>
          </div>
          <div
            class="col-md-12"
            v-for="item of priceDetails"
            v-bind:key="item.id"
            style="border-bottom: 1px solid #000"
          >
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.detail }}
            </div>
            <div
              class="col-xs-3"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.name }}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.netPrice }} {{currencyName}}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.quantity }}
            </div>
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.netValue }} {{currencyName}}
            </div>
            <div
              class="col-xs-1"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.vat }}%
            </div>
            <div
              class="col-xs-2"
              style="padding: 1rem 0.5rem;"
            >
              {{ item.grossValue }} {{currencyName}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from "../global/VButton.vue";
import { dictionariesService } from "../../services/dictionaries.service.js";

export default {
  name: "ProductPriceDetails",
  props: {
    closePriceDetails: Function,
    priceDetails: Array,
  },  data() {
    return {
      currList: [],
      currencyName: ''
    };
  },
  mounted: function() {
    dictionariesService.getCurrList().then((response) => {
      const sessionStr = localStorage.getItem('session');
      const session = JSON.parse(sessionStr);
      this.currList = response.data;
      let c = this.currList.find(
        (e) => e.id === session.idCurrency
      );
      this.currencyName = c.currency;
    });
  },
  components: {
    VButton
  }
};
</script>

