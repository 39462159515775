<template>
  <div class="container">
    <!-- <ActionsMenu :isInValid='checkValid()' :inValidValue="'strCancelChanges'" /> -->
    <div class="row">
      <!-- <div class="col-12 offer__header">
        <div class="offer__header-title">
          {{ pageTitle }}
        </div>
      </div> -->
      <div class="col-6 offset-md-3 offer__content" v-if="isLoading === true">
        <ProgressSpinner />
        {{ currentStep }}
      </div>

      <div
        class="col-6 offset-md-3 offer__content"
        v-if="isLoading === false"
        style="color: #c61e1e;
        font-size: 1.8rem;
        letter-spacing: .15rem;
        font-weight: 600;
        margin-top: 1.5rem;
        text-align: center;"
      >
        {{ response.statusMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from "../../services/user.service.js";
import { translationsService } from "../../services/translations.service.js";

//import ActionsMenu from '@/components/ActionsMenu';
//import CustomerEdit from "@/components/customers/CustomerEdit";
//import { orderService } from '../../services/order.service.js';

//import { required, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "Login",
  data() {
    return {
      pageTitle: "",
      email: "",
      idAddress: 0,
      response: {},
      isLoading: true,
      currentStep: "",
    };
  },
  //    validations: {
  //             offer: {
  //                 name: { required, minLength: minLength(5) },
  //                 idCustomer: {required, between: between(1, 1000000)}
  //             }
  //    },
  mounted: function mounted() {
    localStorage.removeItem("session");
    localStorage.removeItem("userInfo");
    if (this.$route.params.idAddress) {
      this.idAddress = Number(this.$route.params.idAddress);
    }

    if (this.$route.params.email) {
      this.email = this.$route.params.email;
    }

    this.signIn();
    // this.loadCustomers();
    // let t = this.$t('strOfferNe');
    this.pageTitle = this.$t("strSigningInHeader");
  },
  methods: {
    signIn() {
      const thisVue = this;
      this.currentStep = "Logging in user into system...";
      userService.signIn(this.email, this.idAddress).then((response) => {
        if (response.guid) {
          userService.setLang(response.lang);
          thisVue.loadTranslations(response.lang);
          this.$store.commit("setLang", response.lang);
          this.$store.commit("setKindRole", +response.kindRole);
          this.getRole();
        } else {
          thisVue.isLoading = false;
          thisVue.response = response;
        }
      });
    },
    getRole() {
      userService.getRole().then((response) => {
        this.$store.commit("setRoles", response.data);
      });
    },
    loadTranslations(langId) {
      const thisVue = this;
      this.currentStep = "Loading settings...";
      translationsService.getTranslations(langId).then((items) => {
        //thisVue.currentLang = langId;
        let obj = {};
        items.forEach((item) => {
          obj[item.key] = item.value;
        });
        thisVue.$i18n.setLocaleMessage(langId, obj);
        thisVue.$i18n.locale = langId;
        thisVue.$router.push("/");
        //thisVue.loadedLang = true;
      });
    },
  },
  components: {
    // ActionsMenu,
    // CustomerEdit
  },
};
</script>
