<template>
  <div :style="productsElementsWindow">
    <div class="row" style="margin: 0">
      <div class="col-xs-6 extraProductsHeader_item" v-bind:class="mainTab === 0 ? 'active' : ''" @click="
        setLastEl(null);
      mainTab = 0;
      ">
        {{ $t("strProductElementsSelected") }}
      </div>
      <div class="col-xs-6 extraProductsHeader_item" v-bind:class="mainTab === 1 ? 'active' : ''" @click="
        setLastEl(null);
      mainTab = 1;
      ">
        {{ $t("strConfigure") }}
      </div>
    </div>
    <div style="
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin: 0px;
        overflow: hidden auto;
        max-height: 90vh;
      " id="configTab" @scroll="onScrollItems()" v-if="mainTab === 1">
      <div class="row" :style="detailsList">
        <div class="col-xs-12" style="margin-bottom: 2rem; padding-left: 0">
          <div style="display: flex">
            <img src="@/assets/icons/arrow.svg" alt="" style="
                cursor: pointer;
                width: 10px;
                margin-left: 1.5rem;
                transform: rotate(180deg);
              " @click="hide()" />
            <span style="font-size: 16px; margin-left: 1rem">
              {{ $t("strSelect") }}
              {{ $t(tabName.replace(/\d+/g, '')) }}
              {{ extractNumber(tabName) }}</span>
          </div>
        </div>
      </div>

      <div v-show="this.displayDetails === true &&
        detailsName.toLowerCase().startsWith('color')
        " class="row" style="margin: 0; display: flex">
        <div class="greyBtn" v-bind:class="detailsName.toLowerCase() === 'colorin' ? 'active' : ''"
          @click="openElement('ColorIn')">
          {{ $t("strSliderColorIn") }}
        </div>
        <div class="greyBtn" v-bind:class="detailsName.toLowerCase() === 'colorex' ? 'active' : ''"
          @click="openElement('ColorEx')">
          {{ $t("strSliderColorEx") }}
        </div>
        <div class="greyBtn" v-bind:class="detailsName.toLowerCase() === 'colorc' ? 'active' : ''"
          @click="openElement('ColorC')">
          {{ $t("strSliderColorC") }}
        </div>
      </div>

      <div v-show="this.displayDetails === true && detailsName.toLowerCase() === 'glass'
        ">
        <div class="row">
          <div class="col-xs-12" style="padding-bottom: 1rem">
            <span style="color: #ba1c1c; font-size: 18px; font-weight: 700">{{
              $t("strGlassGroup")
            }}</span>
          </div>
          <div class="col-xs-6 p-field-checkbox mb-1" v-for="item of glassGroups" v-bind:key="item.id"
            style="display: flex">
            <Checkbox :id="'glassGroup' + item.key" :name="item.name" :value="item.id" v-model="selectedGlassGroups" />
            <div>
              <label style="font-weight: 500; margin-bottom: 0; margin-left: 0.5rem" :for="'glassGroup' + item.id">{{
                item.name }}</label>
            </div>
            <!-- <SelectButton
              v-model="selectedFittingGroup"
              :options="fittingGroups"
              optionLabel="name"
            /> -->
          </div>
          <div class="row" style="margin: 0">
            <div class="col-xs-12 p-field mx-1" style="margin-top: 1rem">
              <label style="margin-bottom: 0; font-weight: 500" for="glassKind">{{ $t("strSliderNameGlassKind") }}</label>
              <br />
              <Dropdown id="glassKind" v-model="glassKind" :options="glassKinds" optionLabel="name" optionValue="id" />
            </div>
          </div>
          <!-- <div class="row" style="margin: 0">
            <div class="col-xs-12 p-field mx-1" style="margin-top: 1rem">
              <label
                style="margin-bottom: 0; font-weight: 500"
                for="interframe"
                >{{ $t("strSliderNameInterFrame") }}</label
              >
              <br />
              <Dropdown
                id="interframe"
                v-model="interframe"
                :options="interframesList"
                optionLabel="name"
                optionValue="id"
              />
            </div>
          </div> -->
        </div>
      </div>
      <div v-if="this.displayDetails === true && detailsName === 'rollerShutter'" class="row"
        style="margin: 0; min-height: 60vh;">
        <div class="col-xs-12 mt-2">
          <label>{{ $t("strRollershutterType") }}</label>
          <Dropdown class="offer--input" id="rollerShutterDropdown" v-model="selectedRollerKind" :options="rollerKinds"
            optionLabel="nameRoller" optionValue="kindRoller" @change="setRollerKind" />
        </div>
        <div v-if="selectedRollerKind">
          <div class="col-xs-12" style="margin-top: 3.5rem">
            <label>{{ $t("strRollershutterName") }}</label>
            <Dropdown class="offer--input" id="rollerShutterBoxDropdown" v-model="selectedRollerBox"
              :options="rollerBoxes" optionLabel="name" optionValue="id" />
          </div>
          <div v-if="selectedRollerKind === 2" class="col-xs-12" style="margin-top: 3.5rem">
            <label>{{ $t("strRollershutterWidth") }}</label>
            <InputText class="offer--input" type="text" v-model="rollerWidth" />
          </div>
          <div class="col-xs-12" style="margin-top: 3.5rem">
            <label>{{ $t("strPrice") }}</label>
            <InputText class="offer--input" type="text" v-model="rollerPrice" />
          </div>
          <div class="col-xs-12" style="margin-top: 3.5rem">
            <VButton class="selectButton" @click="itemSelected" type="button" :style="selectButton" aria:haspopup="true"
              aria-controls="overlay_panel" :disabled="addRollerBtn()" strKey="strSelect" />
          </div>
        </div>
      </div>
      <div v-show="this.displayDetails === true && detailsName.toLowerCase() === 'handle'
        " class="row" style="margin: 0">
        <div class="col-xs-6 extraProductsHeader_item" v-bind:class="selectedItemKind === 'A' ? 'active' : ''" @click="
          selectedItemKind = 'A';
        openElement('handle');
        ">
          {{ $t("strSliderHandleA") }}
        </div>
        <div class="col-xs-6 extraProductsHeader_item" v-bind:class="selectedItemKind === 'B' ? 'active' : ''" @click="
          selectedItemKind = 'B';
        openElement('handle');
        ">
          {{ $t("strSliderHandleB") }}
        </div>
      </div>
      <div v-show="this.displayDetails === true &&
        detailsName.toLowerCase() === 'sashposts' ||
        this.displayDetails === true &&
        detailsName.toLowerCase() === 'sashpost'
        ">
        <div class="row" style="margin-top: 2rem">
          <div class="col-xs-12" style="padding-left: 0">
            <div style="display: flex">
              <div style="
                  flex: 1;
                  font-size: 2rem;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  backgorund: #fff;
                  z-index: 2;
                ">
                <span style="cursor: pointer" @click="toRight">&lt;</span>
              </div>

              <div style="
                  display: flex;
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  overflow-x: hidden;
                  flex: auto;
                  z-index: 1;
                " id="sliderProductTypes">
                <div v-for="(item, index) in sashpostSchemats" :key="index" v-tooltip.bottom="item.name"
                  style="cursor: pointer" @click="selectSashpost(item)">
                  <img :src="item.graphicURL" :alt="item.name" class="sliderItem-img" :class="[
                    selectedSashpost && item.id === selectedSashpost.id ? 'active' : '',
                  ]" />
                </div>
              </div>
              <div style="
                  flex: 1;
                  font-size: 2rem;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  backgorund: #fff;
                  z-index: 2;
                ">
                <span style="cursor: pointer" @click="toLeft">&gt;</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 1rem" v-if="selectedSashpost">
          <div class="col-xs-12">
            <div v-for="(item, index) in selectedSashpost.posts.filter(e => e.position === 'horizontal')" :key="index">
              {{ item.id }}
              <div style="margin-top: .5rem">
                <label class="offer--input-label">
                  {{ $t("strAxis") }} Y
                </label>
                <div class="row">
                  <div class="col-xs-12">
                    <input class="vetrex-inpt" type="number" min="0" @keypress="isNumber($event)" showButtons style="width: 100%" v-model="item.y" />
                  </div>
                </div>
              </div>
              <!-- <div v-else style="margin-top: .5rem">
                <label class="offer--input-label">
                  {{ $t("strAxis") }} X
                </label>
                <div class="row">
                  <div class="col-xs-12">
                    <input class="vetrex-inpt" type="number" min="0" @keypress="isNumber($event)" showButtons style="width: 100%" v-model="item.x" />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-xs-12">
            <div v-for="(item, index) in selectedSashpost.posts.filter(e => e.position === 'vertical')" :key="index">
              <div style="margin-top: .5rem">
                <label class="offer--input-label">
                  {{ $t("strAxis") }} X
                </label>
                <div class="row">
                  <div class="col-xs-12">
                    <input class="vetrex-inpt" type="number" min="0" @keypress="isNumber($event)" showButtons style="width: 100%" v-model="item.x" />
                  </div>
                </div>
              </div>
              <!-- <div v-else style="margin-top: .5rem">
                <label class="offer--input-label">
                  {{ $t("strAxis") }} Y
                </label>
                <div class="row">
                  <div class="col-xs-12">
                    <input class="vetrex-inpt" type="number" min="0" @keypress="isNumber($event)" showButtons style="width: 100%" v-model="item.y" />
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div v-show="this.displayDetails === true &&
        detailsName.toLowerCase() === 'fitting'
        ">
        <div class="row" style="margin-top: 2rem">
          <div class="col-xs-12" style="padding-left: 0">
            <div style="display: flex">
              <div style="
                  flex: 1;
                  font-size: 2rem;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  backgorund: #fff;
                  z-index: 2;
                ">
                <span style="cursor: pointer" @click="toRight">&lt;</span>
              </div>

              <div style="
                  display: flex;
                  margin-top: 1rem;
                  margin-bottom: 1rem;
                  overflow-x: hidden;
                  flex: auto;
                  z-index: 1;
                " id="sliderProductTypes">
                <div v-for="(item, index) in fittingGroups" :key="index" v-tooltip.bottom="item.name"
                  style="cursor: pointer" @click="selectFittingGroup(item)">
                  <img :src="item.graphicURL" :alt="item.name" class="sliderItem-img" :class="[
                    item.id === selectedFittingGroup.id ? 'active' : '',
                  ]" />
                </div>
              </div>
              <div style="
                  flex: 1;
                  font-size: 2rem;
                  align-items: center;
                  justify-content: center;
                  display: flex;
                  backgorund: #fff;
                  z-index: 2;
                ">
                <span style="cursor: pointer" @click="toLeft">&gt;</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="margin-top: 3rem" v-if="selectedFittingGroup.fittingKind !== 'undefinded' &&
          displayFittingGroup()
          ">
          <div class="col-xs-12" style="padding-bottom: 1rem">
            <span style="color: #ba1c1c; font-size: 18px; font-weight: 700">{{
              $t("strFittingGroup")
            }}</span>
          </div>
          <div class="col-xs-12" style="display: flex; flex-wrap: wrap">
            <div class="p-field-checkbox" v-for="item of selectedFittingGroup.fittingKind" v-bind:key="item.id"
              style="margin-bottom: 1rem; width: 50%; display: flex">
              <Checkbox :id="'fittingKind' + item.key" :name="item.name" :value="item.id"
                v-model="selectedFittingKinds" />
              <div>
                <label style="
                    font-weight: 500;
                    margin-bottom: 0;
                    margin-left: 0.5rem;
                  " :for="'fittingKind' + item.id">{{ item.name }}</label>
              </div>
              <!-- <SelectButton
              v-model="selectedFittingGroup"
              :options="fittingGroups"
              optionLabel="name"
            /> -->
            </div>
          </div>
        </div>
      </div>

      <div :style="elementsList">
        <div style="padding-top: 4rem; font-weight: bold; font-size: 21px">
          {{ $t("strElements") }}
        </div>
        <div style="margin-top: 4rem">
          <div @click="
            openElement(item.sliderName);
          setTabName(item.sliderElement);
          " :style="productsElement" v-for="item of sliders" v-bind:key="item.id" :class="[
  isZoneEligible(item.sliderName) === true &&
    (selectedZone === null ||
      selectedZone === '' ||
      selectedZone === 'Z0')
    ? 'itemDisabled'
    : '',
]">
            <div class="row" style="display: flex; align-items: center; width: 100%; margin: 0">
              <div class="col-xs-3" style="text-align: center; padding-left: 0">
                <img v-if="item.graphicURL" :src="item.graphicURL" style="width: 32px; height: 32px" />
              </div>
              <div class="col-xs-9" style="font-size: 16px; font-weight: bold">
                <!-- <span
                  v-if="
                    isZoneEligible(item.sliderName) === true &&
                    (selectedZone === null ||
                      selectedZone === '' ||
                      selectedZone === 'Z0')
                  "
                  class="mark exclamation"
                >
                  
                </span> -->
                {{ item.sliderElement }}
                <span v-if="isZoneEligible(item.sliderName) === true &&
                  (selectedZone === null ||
                    selectedZone === '' ||
                    selectedZone === 'Z0')
                  " style="font-size: 25px; margin-left: 0.75rem" v-tooltip.bottom="`${$t('strTooltipZone')} ${$t('strTooltipZone1')}.`
    ">&#9888;</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" :style="detailsList">
        <div class="col-xs-12">
          <div v-if="detailsName !== 'rollerShutter'" class="col-xs-12"
            style="text-align: left; padding-bottom: 1rem; padding-left: 0">
            <div style="font-size: 16px">{{ $t("strName") }}</div>
            <InputText class="offer--input" type="text" style="max-width: 70%" v-model="sliderItemsSearchText"
              @keyup="getItemsToDisplay()" :placeholder="$t('strSearch')" />
            <span style="cursor: pointer; margin-left: 1rem" @click="clearSelectedSearch()">&#10005;</span>
          </div>
          <div v-if="tab === 0 && 
            detailsName !== 'rollerShutter' && 
            detailsName.toLowerCase() !== 'sashposts' && 
            detailsName.toLowerCase() !== 'sashpost' ||
            tab === 0 &&
            detailsName.toLowerCase() === 'sashposts' && 
            sashpostTypes.length > 0 ||
            tab === 0 &&
            detailsName.toLowerCase() === 'sashpost' && 
            sashpostTypes.length > 0">
            <div v-if="!checkItems()">
              {{ $t("strNoItemsToDisplay") }}
            </div>
            <div :style="productsElement" v-for="item in itemsToDisplay" :key="item.id">
              <div :id="item.id" class="row" style="display: flex; width: 100%; margin: 0">
                <div class="col-xs-3" style="text-align: center; padding-left: 0">
                  <img v-if="item.graphicURL" :src="item.graphicURL" style="width: 32px; height: 32px"
                    @click="toggleImg($event, item.id)" />
                  <OverlayPanel :ref="'opi_' + item.id" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                    style="width: 350px; height: auto">
                    <img :src="item.graphicURL" style="width: 100%; height: auto" />
                  </OverlayPanel>
                </div>
                <div class="col-xs-9" style="
                    font-size: 13px;
                    font-weight: normal;
                    display: flex;
                    justify-content: space-between;
                  ">
                  <div style="max-width: 80%">
                    <strong>{{ item.name }}</strong>
                    <br />
                    <span v-if="detailsName.toLowerCase().startsWith('color')">
                      {{ item.group_name }}
                    </span>
                    <!-- <strong v-if="detailsName.toLowerCase() == 'accessory'">{{ item.quantity}}</strong> -->
                  </div>
                  <div style="display: flex; align-items: flex-end">
                    <div>
                      <VButton v-if="(isZoneEligible(detailsName) === false ||
                          basket.zone.length < 2) &&
                        detailsName.toLowerCase() !== 'accessories'
                        " @click="itemSelected(item)" :style="selectButton" strKey="strSelect" />
                      <VButton v-if="isZoneEligible(detailsName) === true &&
                        basket.zone.length > 1
                        " type="button" :style="selectButton" @click="toggle($event, item)" aria:haspopup="true"
                        aria-controls="overlay_panel" strKey="strSelect" />
                      <OverlayPanel :ref="'op_' + item.id" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                        style="width: 450px; height: auto">
                        <div v-if="detailsName.toLowerCase() === 'glass'" class="flex-row" style="
                            display: flex;
                            flex-flow: column;
                            padding-bottom: 1.5rem;
                          ">
                          <label style="margin-bottom: 0.25rem; font-weight: 500" for="interframe">{{
                            $t("strSliderNameInterFrame") }}</label>
                          <Dropdown id="interframe" v-model="interframe" :options="interframesList" optionLabel="name"
                            optionValue="id" style="max-width: 200px" />
                        </div>
                        <div style="display: flex; justify-content: space-between">
                          <!-- <div>
                            <SelectButton
                              v-model="selectedZones"
                              :options="basket.zone"
                              optionLabel="zoneName"
                              optionValue="zoneName"
                              :multiple="true"
                            />
                          </div> -->
                          <div style="display: flex">
                            <div v-for="item in basket.zone" :key="item.id" @click="setSelectedZonesM(item.id)" :class="[
                              selectedZonesM.includes(item.id)
                                ? 'productTabMultiSelected'
                                : 'productTabMulti',
                            ]">
                              {{ item.zoneName }}
                            </div>
                          </div>
                          <div>
                            <VButton @click="
                              itemSelected(item);
                            toggle($event, item);
                            " :style="selectButton" style="margin-top: 1rem" strKey="strSelect" />
                          </div>
                        </div>
                      </OverlayPanel>
                      <VButton v-if="detailsName.toLowerCase() === 'accessories'" type="button" :style="selectButton"
                        @click="
                          toggleAccessory($event, item);
                        clearForm();
                        " aria:haspopup="true" aria-controls="overlay_panel_accessory" strKey="strSelect" />
                      <OverlayPanel :ref="'opacc_' + item.id" appendTo="body" :showCloseIcon="true"
                        id="overlay_panel_accessory" style="width: 300px; height: auto">
                        <div class="row">
                          <div class="col-xs-12 mb-1">
                            <div>{{ $t("strAccessoryQuantity") }}</div>
                            <div>
                              <InputNumber showButtons style="width: 100%" v-model="accessoryQuantity" />
                            </div>
                          </div>
                          <div class="col-xs-12 mb-1" v-if="item.isCountable === 0">
                            <div>{{ $t("strAccessoryAssembly") }}</div>
                            <div>
                              <Dropdown :disabled="accessorySizeActive" v-model="accessoryAssembly"
                                :options="accessoryAssemblies" optionLabel="name" optionValue="id" />
                            </div>
                          </div>
                          <div class="col-xs-12 mb-1" v-if="item.isCountable === 0">
                            <div class="p-field-checkbox">
                              <Checkbox id="accessorySizeActive" v-model="accessorySizeActive" :binary="true" />
                              <label style="
                                  font-weight: 500;
                                  margin-bottom: 0;
                                  margin-left: 0.5rem;
                                " for="accessorySizeActive">{{ $t("strAccessorySizeActive") }}</label>
                            </div>
                          </div>
                          <div class="col-xs-12 mb-1" v-if="accessorySizeActive && item.isCountable === 0">
                            <div>{{ $t("strSize") }}</div>
                            <div>
                              <InputNumber showButtons style="width: 100%" v-model="accessorySize" />
                            </div>
                          </div>
                          <div class="col-xs-12 mb-1" v-if="item.isCountable === 0">
                            <div>{{ $t("strNotes") }}</div>
                            <div>
                              <Textarea v-model="accessoryNotes" rows="5" cols="30" />
                            </div>
                          </div>
                          <div class="col-xs-12 my-1">
                            <VButton @click="
                              itemSelected(item);
                            toggleAccessory($event, item);
                            " :style="selectButton" strKey="strSelect" />
                          </div>
                        </div>
                      </OverlayPanel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="tab === 1"></div>
        </div>
      </div>
    </div>
    <div style="
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        margin: 0px;
        overflow: auto;
        max-height: 90vh;
      " v-if="mainTab === 0">
      <product-selected-elements ref="productSelectedElements" :basket="basket"
        :setTabName="setTabName"></product-selected-elements>
    </div>
  </div>
</template>

<script>
import { productSlidersService } from "../../services/product.sliders.service.js";
import ProductSelectedElements from "./ProductSelectedElements.vue";
import VButton from "../global/VButton.vue";
import Tooltip from "primevue/tooltip";

export default {
  name: "ProductElements",
  props: {
    selectedZone: String,
    basket: {},
    positionDetails: {},
    idTechnology: Number,
    idPosition: Number,
    changeZone: Function,
  },
  data() {
    return {
      displayDetails: false,
      detailsName: "",
      tab: 0,
      mainTab: 0,
      sliders: [],
      sliderItems: [],
      selectedItems: [],
      selectedItemId: 0,
      selectedSashpost: null,
      sashpostSchemats: [],
      sashpostTypes: [],
      sashPostList: [],
      fittingGroups: [],
      selectedFittingGroup: {},
      selectedFittingKinds: [],
      selectedZonesM: [],
      glassGroups: [],
      glassKinds: [],
      selectedGlassGroups: [],
      sliderCurrentZone: "Z0",
      sliderItemsSearchText: "",
      selectedZones: [],
      tabName: "",
      selectedItemKind: "",
      accessoryQuantity: 1,
      accessoryAssembly: 0,
      accessorySize: 0,
      accessorySizeActive: false,
      accessoryNotes: "",
      accessoryAssemblies: [],
      glassKind: 1,
      interframe: 0,
      interframesList: [],
      sliderLastIndex: 20,
      itemsToDisplay: [],
      selectedRollerKind: null,
      selectedRollerBox: null,
      rollerBoxes: [],
      rollerData: [],
      rollerKinds: [],
      rollerPrice: "",
      rollerWidth: "",
      lastEl: null,
    };
  },
  methods: {
    extractNumber(title){
      const regex = /\d+/;
      const matches = title.match(regex)
      return matches ? matches[0] : null;
    },
    isNumber: function(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) 
        evt.preventDefault()
    
    },
    setLastEl(id) {
      this.lastEl = id;
    },
    displayFittingGroup() {
      return Object.keys(this.selectedFittingGroup).length > 0;
    },
    toLeft() {
      let el = document.getElementById("sliderProductTypes");
      let items = el.children;

      let max = items.length - 3;
      for (let i = 0; i < items.length; i++) {
        if (i >= max) break;
        let item = items[i];
        if (item.style.display === "block") {
          item.style.display = "none";
          break;
        }
      }
    },
    toRight() {
      let el = document.getElementById("sliderProductTypes");
      let items = el.children;
      for (let i = items.length - 1; i >= 0; i--) {
        let item = items[i];
        if (item.style.display === "none") {
          item.style.display = "block";
          break;
        }
      }
    },
    clearForm() {
      this.accessoryQuantity = 1;
      this.accessoryAssembly = 0;
      this.accessorySize = 0;
      this.accessorySizeActive = false;
      this.accessoryNotes = "";
    },
    setTabName(name) {
      this.tabName = name;
    },
    setTab: function (tab) {
      this.tab = tab;
    },
    setSelectedZonesM(id) {
      let added = this.selectedZonesM.includes(id);
      if (added) {
        if (this.selectedZonesM.length > 1) {
          let arr = this.selectedZonesM.filter((elId) => elId !== id);
          this.selectedZonesM = [...arr];
        }
      } else this.selectedZonesM.push(id);

      if (this.basket.zone.length > this.selectedZonesM.length)
        this.$parent.selectedZone = `Z${this.selectedZonesM[0]}`;
      else this.$parent.selectedZone = "Z0";
    },
    toggleImg(event, id) {
      const el = this.$refs["opi_" + id];
      el[0].toggle(event);
    },
    toggle(event, item) {
      this.selectedZones = [];
      if (
        this.selectedZone !== undefined &&
        this.selectedZone !== null &&
        this.selectedZone !== "" &&
        this.selectedZone !== "Z0"
      )
        this.selectedZones.push(this.selectedZone);
      const el = this.$refs["op_" + item.id];
      el[0].toggle(event);

      let zoneId = this.$parent.selectedZone.replace("Z", "");
      this.selectedZonesM = [Number(zoneId)];
    },
    toggleAccessory(event, item) {
      this.accessoryAssemblies = [
        { name: this.$t("strAccessoryAssembly0"), id: 0 },
        { name: this.$t("strAccessoryAssembly1"), id: 1 },
        { name: this.$t("strAccessoryAssembly2"), id: 2 },
        { name: this.$t("strAccessoryAssembly3"), id: 3 },
      ];
      const el = this.$refs["opacc_" + item.id];
      el[0].toggle(event);
    },
    isZoneEligible(name) {
      switch (name.toLowerCase()) {
        case "glass":
        case "handle":
        case "handlea":
        case "handleb":
        case "fitting":
        case "cylinder":
        case "airinlet":
        case "cap":
        case "wing":
        case "glassdoor":
        case "decor":
        case "sashpost":
        case "sashposts":
          return true;
        default:
          return false;
      }
    },
    openElement: function (name, item, zone, selectedItemKind='') {
      this.detailsName = name;
      this.sliderItems = [];
      this.itemsToDisplay = [];
      this.sliderLastIndex = 20;
      this.selectedItemKind = selectedItemKind
      if (
        this.isZoneEligible(name) === true &&
        (this.$parent.selectedZone === null ||
          this.$parent.selectedZone === "" ||
          this.$parent.selectedZone === "Z0")
      )
        return;

      // if (this.detailsName === name){
      //   this.mainTab = 1;
      //   this.displayDetails = true;
      //   return;
      // }

      if (name.toLowerCase() === "fitting") {
        this.loadFittingGroups(zone === undefined ? this.selectedZone : zone);
      }

      if (name.toLowerCase() === "glass") {
        this.loadGlassGroups();
        this.loadGlassKinds();
        this.initInterFrameId(zone === undefined ? this.selectedZone : zone);
        if (this.selectedGlassGroups.length > 0)
          this.loadGlassList(this.selectedGlassGroups);
        this.selectedItemKind = item.kindItem
      }

      if (name.toLowerCase() === "handle" && item?.kindItem !== undefined) {
        this.selectedItemKind = item.kindItem;
      } else if (
        name.toLowerCase() === "handle" &&
        this.selectedItemKind === ""
      ) {
        this.selectedItemKind = "A";
      }

      if (name.toLowerCase() !== "fitting" && name.toLowerCase() !== "glass") {
        this.loadSliderElements(name);
        if (item?.id !== undefined) this.selectedItemId = item.id;
        else this.selectedItemId = 0;
      }
      this.mainTab = 1;
      this.displayDetails = true;
    },
    initInterFrameId(zone) {
      this.interframe = this.basket.zone
        .find((i) => i.zoneName === zone)
        ?.zoneItems?.find((i) => i.itemName === "InterFrame")?.id;
    },
    onScrollItems: function () {
      let el = document.getElementById("configTab");
      let y = el.scrollTop;
      let percent = Math.round((y / el.scrollHeight) * 100);
      if (percent < 50) return;
      let items = this.getSliderItems();
      if (items.length < 20 || this.sliderLastIndex >= items.length) return;
      let nextIndex =
        this.sliderLastIndex + 20 > items.length
          ? this.sliderLastIndex +
          20 -
          (this.sliderLastIndex + 20 - items.length)
          : this.sliderLastIndex + 20;
      let data = items.slice(this.sliderLastIndex, nextIndex);
      this.sliderLastIndex = nextIndex;
      this.itemsToDisplay = [...this.itemsToDisplay, ...data];
    },
    getItemsToDisplay() {
      this.itemsToDisplay = [];
      this.sliderLastIndex = 20;
      let data = this.getSliderItems();
      let items = data.slice(0, this.sliderLastIndex);
      this.itemsToDisplay = [...items];
    },
    getSliderItems() {
      const stringSearch = this.sliderItemsSearchText.trim().toLowerCase();
      let terms = stringSearch.split("*");
      terms = terms.filter((item) => item);
      if (terms.length === 0 || stringSearch === "") return this.sliderItems;

      return this.sliderItems.filter((x) =>
        terms.every((d) =>
          x.name
            .trim()
            .toLowerCase()
            .includes(d.trim())
        )
      );
    },
    checkItems() {
      let items = this.getSliderItems();
      return items?.length > 0;
    },
    clearSelectedSearch() {
      this.sliderItemsSearchText = "";
      this.getItemsToDisplay();
    },
    loadFittingList(kinds) {
      if (kinds?.length > 0) {
        let request = {
          idTechnology: this.idTechnology,
          idPosition: this.idPosition,
          idFittingKind: this.selectedFittingGroup.id,
          idFitting: this.basket.zone
            .find((i) => i.zoneName === this.selectedZone)
            .zoneItems.find((i) => i.itemName === "Fitting").id,
          idGroup: kinds.join(","),
          idCount: kinds.length,
        };

        const thisVue = this;
        productSlidersService.getItems("fitting", request).then((response) => {
          thisVue.sliderItems = response.data;
          thisVue.getItemsToDisplay();
        });
      }
    },
    loadGlassList(groups) {
      if (groups?.length > 0) {
        let request = productSlidersService.getSliderRequest(
          "Glass",
          this.basket,
          this.idTechnology,
          this.selectedZone,
          this.selectedItemKind
        );
        request.idGroup = groups.join(",");
        request.idCount = groups.length;
        //if(this.glassKind > 0)
        request.kind = this.glassKind;

        const thisVue = this;
        productSlidersService.getItems("glass", request).then((response) => {
          thisVue.sliderItems = response.data;
          thisVue.getItemsToDisplay();
        });
      }
    },
    loadSliderElements(name) {
      if (name === undefined) return;
      //alert(`Selected: ${this.selectedZone}`);
      this.sliderItems = [];
      if (
        this.isZoneEligible(name) === true &&
        (this.$parent.selectedZone === "" || this.$parent.selectedZone === "Z0")
      ) {
        alert("return");
        return;
      }
      let request = productSlidersService.getSliderRequest(
        name,
        this.basket,
        this.idTechnology,
        this.$parent.selectedZone,
        this.selectedItemKind
      );
      if (name === "Frame") request.idType = this.basket.idType;
      const thisVue = this;
      const idSash =
        this.basket.nameSchema.toLowerCase() === "door" &&
          name.toLowerCase() === "handle"
          ? this.getIdSash(this.$parent.selectedZone)
          : 0;
      if(name === 'SashPost'){
        const zone = this.basket.zone.find(z => z.zoneName === this.$parent.selectedZone)
        if(zone){
          const item = zone.zoneItems.find(i => i.itemName === 'SashPost')
          this.selectedSashpost = item ? item : null;
          if(item) this.selectSashpost(item)
        }
      }
      if(name === 'sashposts') this.selectedSashpost = null
      productSlidersService
        .getItems(name, request, this.selectedItemKind, idSash)
        .then((response) => {
          if (response.data === null) thisVue.sliderItems = [];
          else if (name === "rollerShutter")
            thisVue.handleRollers(response.data);
          else if (name.toLowerCase() === 'sashposts' || name.toLowerCase() === 'sashpost') thisVue.sashpostSchemats = response.data
          else thisVue.sliderItems = response.data;

          if (name.toLowerCase() === "cylinder") thisVue.getCylinderB();
          else if (name.toLowerCase() === 'sashposts' || name.toLowerCase() === 'sashpost') return;
          else thisVue.getItemsToDisplay();
        });
      if (name === "color") {
        thisVue.openElement("ColorIn");
      }
    },
    handleRollers(data) {
      this.rollerData = data;
      this.rollerKinds = [...data];
    },
    setRollerKind(e) {
      this.selectedRollerBox = null;
      let type = e;
      let data = this.rollerData.find((e) => e.kindRoller === type.value);
      data.kindBox.forEach((el) => {
        el.name = `${el.nameRoller} ${el.sizeRoller}mm`;
      });
      this.rollerBoxes = [...data.kindBox];
      this.rollerWidth = this.basket.width;
    },
    addRollerBtn() {
      let disabled = false;
      if (this.selectedRollerBox === null) disabled = true;
      if (this.selectedRollerKind === 2 && this.rollerWidth === "")
        disabled = true;
      if (this.rollerPrice === "") disabled = true;
      return disabled;
    },
    getCylinderB() {
      let request = productSlidersService.getSliderRequest(
        "cylinderb",
        this.basket,
        this.idTechnology,
        this.$parent.selectedZone,
        this.selectedItemKind
      );
      if (request.idHandle === 0) {
        this.checkCylinder();
        if (this.sliderItems.length === 0) {
          this.mainTab = 0;
          return;
        }
        this.getItemsToDisplay();
        return;
      }
      productSlidersService
        .getItems("cylinder", request, this.selectedItemKind)
        .then((response) => {
          if (response.data !== null)
            this.sliderItems = [...this.sliderItems, ...response.data];
          if (this.sliderItems.length === 0) this.mainTab = 0;
          this.getItemsToDisplay();
          this.checkCylinder();
        });
    },
    checkCylinder() {
      let _cylinder = this.basket.zone
        .find((i) => i.zoneName === this.$parent.selectedZone)
        ?.zoneItems?.find((i) => i.itemName === "Cylinder");
      if (_cylinder) {
        let inList = this.sliderItems.findIndex((i) => i.id === _cylinder.id);
        if (inList !== -1) return;
        let zoneIndex = this.basket.zone.findIndex(
          (i) => i.zoneName === this.$parent.selectedZone
        );
        // let zoneItems = this.basket.zone[zoneIndex].zoneItems;
        // let cylinderIndex = zoneItems.findIndex(i => i.itemName === 'Cylinder')
        // zoneItems[cylinderIndex].id = 0;
        // this.basket.zone[zoneIndex].zoneItems = [...zoneItems]
        let withoutCylinder = this.basket.zone
          .find((i) => i.zoneName === this.$parent.selectedZone)
          ?.zoneItems?.filter((i) => i.itemName !== "Cylinder");
        this.basket.zone[zoneIndex].zoneItems = [...withoutCylinder];
        console.log(
          this.basket.zone.find((i) => i.zoneName === this.$parent.selectedZone)
            ?.zoneItems
        );
      }
    },
    loadFittingGroups(zone) {
      const idFitting = this.basket.zone
        .find((i) => i.zoneName === zone)
        ?.zoneItems?.find((i) => i.itemName === "Fitting")?.id;
      productSlidersService
        .getSliderFittingGroups(
          this.idTechnology,
          this.idPosition,
          this.getIdSash(zone),
          idFitting
        )
        .then((response) => {
          this.fittingGroups = response.data;
          if (this.selectedFittingKinds.length > 0) {
            this.loadFittingList(this.selectedFittingKinds);
          }
        });
    },
    loadGlassGroups() {
      const thisVue = this;
      productSlidersService.getSliderGlassGroups().then((response) => {
        thisVue.glassGroups = response.data;
        productSlidersService
          .getInterframesList(this.idTechnology)
          .then((response) => {
            thisVue.interframesList = response.data;
          });
      });
    },
    loadGlassKinds() {
      const thisVue = this;
      productSlidersService.getSliderGlassKinds().then((response) => {
        thisVue.glassKinds = response.data;
      });
    },
    getIdSash(zone) {
      //return this.basket.
      return this.basket.zone
        .find((i) => i.zoneName === zone)
        ?.zoneItems?.find((i) => i.itemName === "Sash")?.id;
    },
    hide: function () {
      this.interframe = 0;
      this.sliderItemsSearchText = "";
      this.selectedGlassGroups = [];
      this.selectedFittingGroup = {};
      this.selectedFittingKinds = [];
      this.displayDetails = false;
    },
    itemClicked(group, item) {
      //console.log('itemClicked',item.elementName);
      //if (item?.elementName === "InterFrame") item.elementName = "Glass";
      this.sliderCurrentZone = group.groupId;
      if (group.groupId.startsWith("Z")) {
        if (group.groupId !== "Z0") {
          this.changeZone(group.groupId);
        }
        if (item.id > 0) {
          //console.log(`Item Changing, group: ${item.elementName}`);
          this.openElement(item.elementName, item, group.groupId);
        } else {
          //console.log(`Item Selecting, group: ${item.elementName}`);
          this.openElement(item.elementName, item, group.groupId);
        }
      } else {
        if (item === null) {
          this.openElement(group.groupId, group.groupId);
        } else {
          let gId = group.groupId.toLowerCase();
          switch (group.groupId.toLowerCase()) {
            case "service":
              gId = "services";
              break;
            case "assembly":
              gId = "assemblies";
              break;
            case "rollershutter":
              gId = "rollerShutter";
              break;
          }
          let it = this.basket[gId];
          if (item.id > 0) {
            const ind = it.findIndex((i) => i.id === item.id);
            if (gId === "assemblies" && ind >= 0) {
              this.basket[gId][ind].id = 0;
              this.basket[gId][ind].name = "";
            } else if (ind >= 0) this.basket[gId].splice(ind, 1);
          }
        }
      }
    },
    getSliders() {
      if (
        this.sliders === undefined ||
        this.sliders === null ||
        this.selectedZone === undefined ||
        this.selectedZone === null ||
        this.selectedZone === ""
      ) {
        return [];
      }

      return this.sliders;
    },
    // saveRollerShuter(){

    // },
    itemSelected(item) {
      let setMainWindow = true;
      if (this.isZoneEligible(this.detailsName)) {
        if (this.basket.zone.length === 1)
          this.selectedZones = [this.basket.zone[0].zoneName];
        else {
          let arr = [];
          this.selectedZonesM.forEach((z) => arr.push(`Z${z}`));
          this.selectedZones = [...arr];
        }
        if (this.selectedZones?.length > 0 === false) {
          alert(this.$t("errZoneNotSelected"));
          return;
        }
      }

      const sliderName = this.detailsName.toLowerCase();
      let oldValue = 0;
      let oldName = "";
      switch (sliderName) {
        case "colorin":
          oldValue = this.basket.idColorI;
          oldName = this.basket.nameColorI;
          this.basket.idColorI = item.id;
          this.basket.nameColorI = item.name;
          this.basket.graphicURLI = item.graphicURL;
          this.sliderItemChanged(
            sliderName,
            null,
            oldValue,
            item.id,
            oldName,
            item.name
          );
          break;
        case "colorex":
          oldValue = this.basket.idColorEx;
          oldName = this.basket.nameColorEx;
          this.basket.idColorEx = item.id;
          this.basket.nameColorEx = item.name;
          this.basket.graphicURLEx = item.graphicURL;
          this.sliderItemChanged(
            sliderName,
            null,
            oldValue,
            item.id,
            oldName,
            item.name
          );
          break;
        case "colorc":
          oldValue = this.basket.idColorC;
          oldName = this.basket.nameColorC;
          this.basket.idColorC = item.id;
          this.basket.nameColorC = item.name;
          this.basket.graphicURLC = item.graphicURL;
          this.sliderItemChanged(
            sliderName,
            null,
            oldValue,
            item.id,
            oldName,
            item.name
          );
          break;
        case "gasket":
          this.basket.idColorGasketIn = item.idColorGasketIn;
          this.basket.idColorGasketEx = item.idColorGasketEx;
          this.basket.nameGasket = item.name;
          this.basket.graphicURL = item.graphicURL;
          break;
        case "frame":
          this.basket.idFrame = item.id;
          this.basket.nameFrame = item.name;
          break;
        case "glass":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Glass", this.selectedItemKind);
            });
          });
          if (
            this.interframe !== undefined &&
            this.interframe > 0 &&
            this.interframesList.find((i) => i.id === this.interframe) !==
            undefined
          ) {
            this.basket.zone.forEach((z) => {
              this.upsertZoneItem(
                z,
                this.interframesList.find((i) => i.id === this.interframe),
                "InterFrame",
                ""
              );
            });
          }
          break;
        case "interframe":
          this.basket.zone.forEach((z) => {
            this.upsertZoneItem(z, item, "InterFrame", "");
          });
          break;
        case "cap":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Cap", "");
            });
          });
          break;
        case "fitting":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Fitting", "");
              if (item.isHandle === 0) {
                let index = z.zoneItems.findIndex(
                  (e) => e.itemName.toLowerCase() === "handle"
                );
                if (index >= 0) {
                  z.zoneItems.splice(index, 1);
                }
              }
            });
          });
          if (item.isHandle > 0) {
            setMainWindow = false;
            this.setTabName("");
            this.openElement("handle");
          }
          break;
        case "airinlet":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "AirInlet", "");
            });
          });

          // this.selectedZones.forEach((zone) => {
          //   this.basket.zone.forEach((z) => {
          //     if (zone !== null && z.zoneName !== zone) {
          //       return;
          //       }
          //     this.upsertZoneItem(z, item, "AirInlet", "");
          //   });
          // });
          break;
        case "cylinder":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Cylinder", "");
            });
          });
          break;
        case "handle":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Handle", this.selectedItemKind);
              if (this.selectedItemKind === "A") {
                let findb = z.zoneItems.findIndex(
                  (e) =>
                    e.itemName.toLowerCase() === "handle" &&
                    e.kindItem.toLowerCase() === "b"
                );
                if (findb >= 0) {
                  z.zoneItems.splice(findb, 1);
                }
              }
            });
          });
          if (
            (this.selectedItemKind === "A" && item.isHandleA !== 1) ||
            this.selectedItemKind === "B"
          ) {
            setMainWindow = false;
            this.sliderItemsSearchText = "";
            this.selectedItemKind = "A";
            this.setTabName("");
            this.openElement("cylinder");
          }
          if (this.selectedItemKind === "A" && item.isHandleA === 1) {
            setMainWindow = false;
            this.sliderItemsSearchText = "";
            this.selectedItemKind = "B";
            this.openElement("handle", item, null, 'B');
          }
          break;
        case "handlea":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "HandleA", this.selectedItemKind);
            });
          });
          break;
        case "handleb":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "HandleB", this.selectedItemKind);
            });
          });
          break;
        case "wing":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Wing", this.selectedItemKind);
            });
          });
          break;
        case "glassdoor":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "GlassDoor", this.selectedItemKind);
            });
          });
          break;
        case "decor":
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, item, "Decor", this.selectedItemKind);
            });
          });
          break;
        case "assembly":
          if (this.addAssembly(item) === false) return;
          break;
        case "service":
          this.basket.services.push({ id: item.id, name: item.name });
          this.$toast.add({
            severity: "success",
            summary: this.$t("strServices"),
            detail: this.$t("strServiceAdded"),
            life: 3000,
          });
          break;
        case "sashposts":
          this.selectedSashpost.posts.forEach(p => {
            p.idPost = item.id;
            p.name = item.name
          })
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, {...this.selectedSashpost}, "SashPost", "");
            });
          });
          // this.selectedSashpost = null;
          // this.sashpostSchemats = [];
          // this.sashpostTypes = [];
          // this.sashPostList = [];
          break;
        case "sashpost":
          this.selectedSashpost.posts.forEach(p => {
            p.idPost = item.id;
            p.name = item.name
          })
          this.selectedZones.forEach((zone) => {
            this.basket.zone.forEach((z) => {
              if (zone !== null && z.zoneName !== zone) return;
              this.upsertZoneItem(z, {...this.selectedSashpost}, "SashPost", "");
            });
          });
          // this.selectedSashpost = null;
          // this.sashpostSchemats = [];
          // this.sashpostTypes = [];
          // this.sashPostList = [];
          break;
        case "rollershutter":
          this.basket.rollerShutter = [];
          var activeRollershuter;
          this.rollerData.forEach((el) => {
            if (el.kindRoller === this.selectedRollerKind) {
              let c = el.kindBox;
              c.map((element) => {
                if (element.id === this.selectedRollerBox) {
                  activeRollershuter = element;
                  return;
                }
              });
            }
          });
          //activeRollershuter.kindRoller
          var res = {
            id: activeRollershuter.id,
            name: activeRollershuter.nameRoller,
            width: this.rollerWidth,
            height: activeRollershuter.sizeRoller,
            start_point: 1,
            price: this.rollerPrice,
          };
          this.basket.rollerShutter.push(res);
          break;
        case "accessories":
          if (item.isCountable === 1) {
            this.basket.accessories.push({
              id: item.id,
              name: item.name,
              amount: this.accessoryQuantity,
              height: 0,
              autosize: 0,
              notes: "",
              assembly: -1,
            });
          } else {
            if (this.accessorySizeActive === false) {
              this.basket.accessories.push({
                id: item.id,
                name: item.name,
                amount: this.accessoryQuantity,
                height: 0, //this.accessorySize,
                autosize: 1, //this.accessorySizeActive === false,
                notes: this.accessoryNotes,
                assembly: this.accessoryAssembly,
              });
            } else {
              this.basket.accessories.push({
                id: item.id,
                name: item.name,
                amount: this.accessoryQuantity,
                height: this.accessorySize,
                autosize: 0, //this.accessorySizeActive === false,
                notes: this.accessoryNotes,
                assembly: -2, //this.accessoryAssembly,
              });
            }
          }

          this.$toast.add({
            severity: "success",
            summary: this.$t("strAccessories"),
            detail: this.$t("strAccessoryAdded"),
            life: 3000,
          });
          this.clearForm();
          break;
      }
      //alert(this.detailsName);

      if (setMainWindow) this.mainTab = 0;
    },
    sliderItemChanged(sliderName, sliderZone, oldValue, newValue) {
      this.emptySliderItemNeedVerification(sliderName, sliderZone);
      if (oldValue !== newValue) {
        const dependentSliders = productSlidersService.getSliderDependencies(
          sliderName
        );
        if (dependentSliders.length) {
          let order = 0;
          dependentSliders.forEach((dp) => {
            this.addSliderItemNeedVerification(dp, sliderZone, order++);
          });
        }
        this.verifyBasket();
      }
    },
    emptySliderItemNeedVerification(sliderName, sliderZone) {
      if (sliderZone === undefined || sliderZone === null) {
        let objChange = this.basket[sliderName + "VerificationRequired"];
        if (objChange === undefined) {
          return;
        }
        objChange.state = "";
      } else {
        this.basket.zone.forEach((z) => {
          if (z.zoneName === sliderZone) {
            let el = z.zoneItems.find(
              (i) => i.itemName.toLowerCase() === sliderName //&&
              //i.kindItem === this.selectedItemKind
            );
            let objChange = {};
            if (el !== undefined && el !== null) {
              objChange = el.VerificationRequired;
              if (objChange === undefined) {
                return;
              }
              objChange.state = "";
            }
          }
        });
      }
    },
    addSliderItemNeedVerification(sliderName, sliderZone, order) {
      if (sliderZone === undefined || sliderZone === null) {
        let objChange = this.basket[sliderName + "VerificationRequired"];
        if (objChange === undefined) {
          objChange = this.basket[sliderName + "VerificationRequired"] = {};
        }
        objChange.sliderName = sliderName;
        objChange.zoneId = "";
        objChange.state = "ParentChanged";
        objChange.order = order;
        switch (sliderName) {
          case "colorex":
            objChange.oldValue = this.basket.idColorEx;
            objChange.oldName = this.basket.nameColorEx;
            break;
          case "colorc":
            objChange.oldValue = this.basket.idColorC;
            objChange.oldName = this.basket.nameColorC;
            break;
          case "gasket":
            objChange.oldValue = this.basket.idColorGasketIn;
            objChange.oldName = this.basket.nameGasket;
            break;
        }
      } else {
        this.basket.zone.forEach((z) => {
          if (z.zoneName === sliderZone) {
            let el = z.zoneItems.find(
              (i) => i.itemName.toLowerCase() === sliderName //&&
              //i.kindItem === this.selectedItemKind
            );
            let objChange = {};
            if (el !== undefined && el !== null) {
              objChange = el.VerificationRequired;
              if (objChange === undefined) {
                objChange = el.VerificationRequired = {};
              }
              objChange.sliderName = sliderName;
              objChange.zoneId = z.zoneName;
              objChange.state = "ParentChanged";
              objChange.order = order;
            }
          }
        });
      }

      const dependentSliders = productSlidersService.getSliderDependencies(
        sliderName
      );
      if (dependentSliders.length) {
        let order = 0;
        dependentSliders.forEach((dp) => {
          this.addSliderItemNeedVerification(dp, sliderZone, order++);
        });
      }
    },
    verifyBasket() {
      let itemsToVerify = [];
      if (this.basket.colorexVerificationRequired?.state === "ParentChanged") {
        itemsToVerify.push(this.basket.colorexVerificationRequired);
      }
      if (this.basket.colorcVerificationRequired?.state === "ParentChanged") {
        itemsToVerify.push(this.basket.colorcVerificationRequired);
      }
      if (this.basket.gasketVerificationRequired?.state === "ParentChanged") {
        itemsToVerify.push(this.basket.gasketVerificationRequired);
      }

      this.basket.zone.forEach((zone) => {
        zone.zoneItems
          .filter((i) => i.VerificationRequired?.state === "ParentChanged")
          .sort((a, b) => a.order - b.order)
          .forEach((zoneItem) => {
            itemsToVerify.push(zoneItem.VerificationRequired);
          });
      });
      if (itemsToVerify.length > 0) {
        this.verifyItem(this, 0, itemsToVerify);
      }
    },
    verifyItem(thisVue, index, itemsToVerify) {
      var item = itemsToVerify[index];
      let request = productSlidersService.getSliderRequest(
        item.sliderName,
        thisVue.basket,
        thisVue.idTechnology,
        item.zoneId,
        thisVue.selectedItemKind
      );
      item.state = "Verifying";
      const requestValidation = productSlidersService.canSliderItemBeChanged(
        item.sliderName,
        thisVue.basket,
        item.zoneId,
        thisVue.selectedItemKind
      );
      if (requestValidation !== "") {
        item.state = "BadRequest";
        item.errorMessage = requestValidation;
        thisVue.setNullValue(item);
      } else {
        //setTimeout(() => {
        productSlidersService
          .getItems(item.sliderName, request)
          .then((items) => {
            item.state = "Verified";
            index = index + 1;
            if (
              items.data?.some((i) => i.id === item.oldValue) &&
              item.sliderName !== "gasket"
            ) {
              item.state = "OK";
            } else {
              if (items.data?.length > 0) {
                thisVue.setCorrectValue(item, items.data[0]);
                item.state = "ItemChanged";
              } else {
                item.state = "VerifiedNegatively";
                thisVue.setNullValue(item);
              }
            }

            if (index < itemsToVerify.length) {
              thisVue.verifyItem(thisVue, index, itemsToVerify);
            }
          });
        //}, 2000);
      }
    },
    setCorrectValue(item, newElement) {
      this.setItemValue(
        item,
        newElement.id,
        newElement.name,
        newElement.graphicURL,
        newElement
      );
    },
    setNullValue(item) {
      this.setItemValue(item, 0, "", "");
    },
    setItemValue(item, id, name, url, newElement) {
      switch (item.sliderName) {
        case "colorin":
          break;
        case "colorex":
          this.basket.idColorEx = id;
          this.basket.nameColorEx = name;
          this.basket.graphicURLEx = url;
          break;
        case "colorc":
          this.basket.idColorC = id;
          this.basket.nameColorC = name;
          this.basket.graphicURLC = url;
          break;
        case "gasket":
          this.basket.idColorGasketIn = newElement.idColorGasketIn;
          this.basket.idColorGasketEx = newElement.idColorGasketEx;
          this.basket.nameGasket = name;
          this.basket.graphicURL = url;
          break;
        case "glass":
          this.setItemValueZone(item.sliderName, item.zoneId, id, name, url);
          this.setItemValueZone("interframe", item.zoneId, id, name, url);
          break;
        default:
          this.setItemValueZone(item.sliderName, item.zoneId, id, name, url);
          break;
      }
    },
    setItemValueZone(itemName, zoneId, id, name, url) {
      this.basket.zone.forEach((z) => {
        if (z.zoneName !== zoneId) return;
        let el = z.zoneItems.find((i) => i.itemName.toLowerCase() === itemName);
        if (el?.id) {
          el.id = id;
          el.name = name;
          el.graphicURL = url;
        }
      });
    },
    addAssembly(item) {
      let as = this.basket.assemblies.find((i) => i.id === item.id);
      if (as?.id === item.id) {
        alert("Już dodano");
        return false;
      } else {
        this.basket.assemblies.push({ id: item.id, name: item.name });
        this.$toast.add({
          severity: "success",
          summary: this.$t("strAssemblies"),
          detail: this.$t("strAssemblyAdded"),
          life: 3000,
        });
        return true;
      }
    },
    upsertZoneItem(z, item, itemName, kindItem) {
      let el = z.zoneItems.find(
        (i) =>
          i.itemName.toLowerCase() === itemName.toLowerCase() &&
          i.kindItem === kindItem
      );
      if (el === undefined || el === null) {
        el = { itemName: itemName };
        z.zoneItems.push(el);
      }
      let oldValue = el.id;
      el.id = item.id;
      el.name = item.name;
      el.kindItem = kindItem;
      el.graphicURL = item.graphicURL;
      if (el.itemName === "SashPost") el.posts = item.posts
      if (el.itemName === "Handle") el.idHandleA = item.isHandleA;
      this.sliderItemChanged(
        itemName.toLowerCase(),
        z.zoneName,
        oldValue,
        item.id,
        el.name,
        item.name
      );
    },
    selectSashpost(item) {
      const thisVue = this;
      this.selectedSashpost = item;
      if(item.id === 0) this.itemSelected(item)
      else {
        if(this.sashPostList.length === 0){
          productSlidersService.getSashpostList(this.$route.params.idTechnology)
          .then((res) => {
            thisVue.sashpostTypes = res.data
            thisVue.sliderItems = res.data;
            thisVue.getItemsToDisplay();
            });
        }
      }
    },
    selectFittingGroup(item) {
      this.selectedFittingGroup = item;
      this.selectedFittingKinds = [];
      this.sliderItems = [];
    },
    loadSlidersList() {
      const thisVue = this;
      productSlidersService
        .getSlidersList(Number(this.$route.params.idTechnology))
        .then((slidersResponse) => {
          thisVue.sliders = slidersResponse.data;
        });
    },
  },
  mounted: function () {
    this.loadSlidersList();
  },
  computed: {
    productsElementsWindow() {
      return {
        "min-height": "70vh",
        // "box-shadow": "-2px -2px 6px #0000001A",
        background: "#FFFFFF",
        "border-left": "1px solid rgba(0, 0, 0, 0.1)",
      };
    },
    productsElement() {
      return {
        "box-shadow": "0px 3px 13px #00000022",
        padding: "2rem .5rem",
        "margin-bottom": "1.5rem",
        cursor: "pointer",
        "min-height": "98px",
        "align-items": "center",
        display: "flex",
      };
    },
    productsHeader() {
      let display = this.displayDetails ? "block" : "none";
      return {
        margin: 0,
        display: display,
      };
    },
    elementsList() {
      let display = this.displayDetails ? "none" : "block";
      return {
        display: display,
      };
    },
    detailsList() {
      let display = this.displayDetails ? "block" : "none";
      return {
        display: display,
        "margin-top": "20px",
      };
    },
    selectButton() {
      return {
        background: "#c61e1e",
        color: "#fff",
        border: "none",
        "font-weight": "normal",
        "font-size": "14px",
        padding: ".5rem 1rem",
        "border-radius": ".5rem",
      };
    },
  },
  watch: {
    selectedFittingKinds: {
      handler(newVal) {
        this.loadFittingList(newVal);
      },
      deep: true,
      immediate: true,
    },
    selectedGlassGroups: {
      handler(newVal) {
        this.loadGlassList(newVal);
      },
      deep: true,
      immediate: true,
    },
    glassKind: {
      handler() {
        this.loadGlassList(this.selectedGlassGroups);
      },
      deep: true,
      immediate: true,
    },
    sliderColorGroup: {
      handler(newVal) {
        this.loadSliderElements(newVal);
      },
      deep: true,
      immediate: true,
    },
    mainTab(val) {
      if (val === 0) {
        setTimeout(() => {
          document.getElementById(this.lastEl).scrollIntoView({
            behavior: "auto",
            block: "center",
            inline: "center",
          });
          this.setLastEl(null);
        }, 150);
      }
    },
  },
  components: {
    ProductSelectedElements,
    VButton,
  },
  directives: {
    tooltip: Tooltip,
  },
};
</script>

<style scoped>
.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 66px;
  color: #212429;
  text-align: center;
  background: #e1e1e1;
  cursor: pointer;
}

.active {
  background: #fff !important;
  color: #212429;
}

.selectButton:disabled {
  background: #dddddd !important;
  cursor: not-allowed;
}
</style>
