<template>
  <div
    style="text-align:center;color:#212429;font-size:16px;margin-top: 1.5rem;display:flex;justify-content: flex-end;"
  >
    <span style="text-transform:capitalize;">{{ title }}</span>
    <div
      style="display:flex;border:1px solid #ECECEC;margin-top:1rem;width: 95px;"
    >
      <div
        @click="subtract()"
        style="padding: 0 1rem;font-weight: bold;cursor:pointer"
      >
        -
      </div>
      <input
        type="text"
        v-model="inputValue"
        size="1"
        style="background:#F7F7F7;border: none;text-align:center;width:100%"
        onkeyup="this.value=this.value.replace(/[^\d]/,'')"
      />
      <div
        @click="add()"
        style="padding: 0 1rem;font-weight: bold;cursor:pointer"
      >
        +
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  name: "ProductInput",
  data() {
    return {
      inputValue: 1,
    };
  },
  methods: {
    add: function() {
      this.inputValue += 1;
    },
    subtract: function() {
      this.inputValue -= 1;
    },
  },
};
</script>
