import { userService } from '../services/user.service.js';

export function handleResponse(response) {

    if (!response.ok) {
        if (response.status === 401) {
            deleteSession();
            userService.initSession();
        }
        else {
            return response.text().then(text => {
                if (response.status === 400 && text && JSON.parse(text)) {
                    const data = JSON.parse(text);
                    return { isSuccess: false, errorCode: 400, statusMessage: data.title, errors: data.errors, }
                } else {
                    return { isSuccess: false, errorCode: response.status, statusMessage: text };
                }
            });
        }

        const error = response.statusText;
        return Promise.reject(error);
    }


    return response.text().then(text => {
        const data = text && JSON.parse(text);

        // if (data.statusCode !== 200) {
        //     console.log(`responseCode: ${data.statusCode}`);
        //     console.log(data);
        // }

        if (data.statusCode === 401 || data.statusCode === 408) {
            //console.log('401');
            //alert('401');
            deleteSession();
            userService.initSession(true);
        }

        return data;
    });
}

function deleteSession() {
    localStorage.removeItem('session');
}

// import { userService } from '../services/user.service.js';

// export function handleResponse(response) {

//     if (!response.ok) {
//         console.log('handle-response');
//         //console.log(response.body.text());
//         alert(response.statusCode);
//         if (response.status === 401 || response.status === 408 || response.statusCode === 408) {
//             // auto logout if 401 response returned from api
//             deleteSession();
//             //location.reload(true);
//             userService.initSession();
//         }

//         // if (response.status === 400) {
//         //     return { isSuccess: false, errorCode: 400, errorMessage: data.title, errors: data.errors, }
//         // }

//         const error = response.statusText;
//         return Promise.reject(error);
//     }

//     response.text().then(text => {
//         const data = text && JSON.parse(text);
//         console.log(data);
//         //alert(data[0].statusCode);
//         if(data[0].statusCode === 408){
//             deleteSession();
//             //location.reload(true);
//             userService.initSession();
//             const error = data.statusMessage;
//             return Promise.reject(error);
//         }
//         alert('ok');
//         return data;
//     });
// }

// function deleteSession() {
//     localStorage.removeItem('session');
// }