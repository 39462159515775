import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const customerService = {
    getCustomer,
    getCustomers,
    saveNewCustomer,
    updateCustomer,
    getCountries
};

function getCountries(){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };

            return fetch(getAPIUrl('get_cntrylist'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getCustomers() {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };

            return fetch(getAPIUrl('get_customers'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getCustomer(idCustomer) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, idCustomer })
            };

            return fetch(getAPIUrl('get_customer'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function saveNewCustomer(newCustomer) {
    return new Promise((resolve) => {
        getSession().then(async session => {

            newCustomer.guid = session.guid;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newCustomer)
            };
            return fetch(getAPIUrl('set_customer'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function updateCustomer(customer) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            customer.rodo = 1;
            customer.guid = session.guid;
            customer.idCustomer = customer.id;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(customer)
            };
            return fetch(getAPIUrl('update_customer'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

