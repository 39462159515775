import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const orderService = {
    getOrders,
    getOrder,
    getOrderVariants,
    getVariantPositions,
    getVariantAccessories,
    getVariantAllAccessories,
    exportVariant,
    addVariantAccessory,
    deleteAccessory,
    saveNewOrder,
    updateOrder,
    deleteOrder,

    addVariant,
    updateVariant,
    duplicateVariant,
    deleteVariant,
    recalcVariant,

    duplicatePosition,
    deletePosition,
    getOffer,
    getPrintTypes,
    duplicateOrder,

    getVariantEmptyPositions,
    addEmptyPositionToVariant,
    updateEmptyPositionVariant,
    duplicateEmptyPosition,
    deleteEmptyPositionVariant
};

function getOrders() {
    return new Promise((resolve) => {
        getSession().then(async session => {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: `${session.guid}`, language : session.lang })
            };
            return fetch(getAPIUrl('get_orders'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getOrder(orderId) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idOrder: `${orderId}`, language : session.lang })
            };
            const handleResp = await fetch(getAPIUrl('get_orddetail'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getOrderVariants(orderId) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idOrder: `${orderId}`, language: session.lang })
            };

            const handleResp = await fetch(getAPIUrl('get_ordvariants'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getVariantPositions(variantId) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: `${variantId}`, language: session.lang })
            };

            const handleResp = await fetch(getAPIUrl('get_varpositions'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getVariantAllAccessories(idTechnology) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idTechnology: idTechnology, language: session.lang })
            };

            const handleResp = await fetch(getAPIUrl('get_varallaccessories'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}


function deleteAccessory(idAccessorie) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idAccessorie: idAccessorie })
            };

            const handleResp = await fetch(getAPIUrl('del_accessorie'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getVariantAccessories(variantId) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: variantId, language: session.lang })
            };

            const handleResp = await fetch(getAPIUrl('get_varaccessories'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function addVariantAccessory(idVariant, idAccessory, isKit, amount, size) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: idVariant,idAccessorie: idAccessory, isKit: isKit, size: size,  amount : amount })
            };
            const handleResp = await fetch(getAPIUrl('set_varallaccessories'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function saveNewOrder(nameOrder, idCustomer) {

    return new Promise((resolve, reject) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, nameOrder: `${nameOrder}`, idCustomer: idCustomer })
            };

            fetch(getAPIUrl('set_order'), requestOptions).then(handleResp => {
                handleResponse(handleResp).then(rsp => {
                    resolve(rsp);
                })
                .catch(reason => {
                    reject(reason);
                });
            }).catch(reason => {
                reject(reason);
            });
        });
    });
}

function updateOrder(offer) {

    return new Promise((resolve, reject) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid,
                idOrder: offer.id,
                nameOrder: offer.nameOrder,
                idTrader: offer.idTrader,
                idCustomer: offer.idCustomer,
                promoCode: offer.promoCode,
                idOrderStatus: offer.idOrderStatus,
                language: session.lang })
            };

            fetch(getAPIUrl('update_order'), requestOptions).then(handleResp => {
                handleResponse(handleResp).then(rsp => {
                    resolve(rsp);
                });
            }).catch(reason =>{
                reject(reason);
            });
        });
    });
}

function duplicateVariant(idVariant){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: idVariant, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('duplicate_variant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function deleteVariant(idVariant){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: idVariant })
            };
            const handleResp = await fetch(getAPIUrl('del_variant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function deleteOrder(idOrder){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idOrder: idOrder })
            };
            const handleResp = await fetch(getAPIUrl('del_order'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}



function deletePosition(idPosition){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition })
            };
            const handleResp = await fetch(getAPIUrl('del_position'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}



function addVariant(idOrder, nameOrder){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idOrder: idOrder, nameOrder: nameOrder, notes: '', pInternal: 0 })
            };
            const handleResp = await fetch(getAPIUrl('set_variant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function updateVariant(variant) {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                guid: session.guid,
                idVariant: variant.idVariant,
                nameVariant: variant.nameVariant,
                idTypeOfWelding: variant.typeOfWelding,
                idCurrency: variant.idCurrency,
                skonto: variant.skontoDiscount,
                extraDiscount: variant.extraDiscount,
                extraAssemblyDiscount: variant.extraAssemblyDiscount,
                idVat: variant.idVat,
                sellCurrency: variant.sellCurrency,
                purchaseCurrency: variant.purchaseCurrency,
                notes: variant.notes,
                customerNotes: variant.customerNotes,
               })
            };
            const handleResp = await fetch(getAPIUrl('update_variant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function duplicatePosition(idPosition, idVariant){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idPosition: idPosition, idVariant, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('duplicate_position'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function exportVariant(idVariant, status = -1){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant, status })
            };
            const handleResp = await fetch(getAPIUrl('set_exportvariant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getOffer(idVariant, type){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: idVariant, idUser: session.idUser, type, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('get_offer'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function recalcVariant(idVariant, idOrder){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idVariant: idVariant, idOrder: idOrder, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('get_recalcvariant'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getPrintTypes(){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('get_offrlist'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });
        });
    });
}

function duplicateOrder(idOrder){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idOrder: idOrder, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('duplicate_order'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function getVariantEmptyPositions() {

    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, language: session.lang, pIdFPosition: 0 })
            };

            const handleResp = await fetch(getAPIUrl('get_sldrfpositionlist'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function addEmptyPositionToVariant(idVariant,idFPosition,priceBuy,priceSell,quantity,notes){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    guid: session.guid, 
                    idVariant,
                    idFPosition,
                    priceBuy,
                    priceSell,
                    quantity,
                    notes
                })
            };
            const handleResp = await fetch(getAPIUrl('set_varfposition'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function updateEmptyPositionVariant(id, idVariant,idFPosition,priceBuy,priceSell,quantity,notes){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    guid: session.guid, 
                    id,
                    idVariant,
                    idFPosition,
                    priceBuy,
                    priceSell,
                    quantity,
                    notes
                })
            };
            const handleResp = await fetch(getAPIUrl('update_varfposition'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function duplicateEmptyPosition(idPosition, idVariant){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, id: idPosition, idPosition, idVariant, language: session.lang })
            };
            const handleResp = await fetch(getAPIUrl('duplicate_varfposition'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}

function deleteEmptyPositionVariant(idPosition){
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, id: idPosition, idPosition })
            };
            const handleResp = await fetch(getAPIUrl('del_varfposition'), requestOptions);
            handleResponse(handleResp).then(rsp => {
                resolve(rsp);
            });

        });
    });
}