<template>
  <div id="header">
    <div class="container header">
      <div class="row">
        <div class="col-md-12">
          <img class="logo" src="@/assets/logovetrex-svg.svg" />
          <!-- <span style="margin-left: 1rem">Data ostatniej aktualizacji: 02.03.2022 12:10</span> -->
        </div>
        <div class="col-md-12">
          <ul class="menu_1">
            <!-- <li class="menu_1_element">
              {{$t('strNews')}}
            </li> -->
            <!-- <li class="menu_1_element" @click="toMyAccount">
              {{ $t("strYourAccount") }}
            </li> -->
            <li class="menu_1_element" style="display:none">
              <Dropdown
                v-model="selectedLanguage"
                :options="languages"
                @change="languageChanged"
                optionLabel="name"
                optionValue="code"
                placeholder="Select a Language"
              />
            </li>
            <li class="menu_1_element" @click="logOut">
              {{ $t("strLogOut") === "strLogOut" ? "Logout" : $t("strLogOut") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-red">
      <div class="container d-flex menuList">
        <div v-if="env === 'dev'">
          {{ $t("strEnv") === "strEnv" ? "" : $t("strEnv") }}
          {{ $t("strEnvDev") === "strEnvDev" ? "DEV" : $t("strEnvDev") }}
        </div>
        <div
          v-if="env === 'test'"
          style="background: #f4d03d;font-weight: bold;color: black;"
        >
          {{ $t("strEnv") === "strEnv" ? "" : $t("strEnv") }}
          {{ $t("strEnvTest") === "strEnvTest" ? "TEST" : $t("strEnvTest") }}
        </div>
        <div
          v-if="env === 'prod'"
          style="background: #f4d03d;font-weight: bold;color: black;"
        >
          {{ $t("strEnv") === "strEnv" ? "" : $t("strEnv") }}
          {{ $t("strEnvProd") === "strEnvProd" ? "PROD" : $t("strEnvProd") }}
        </div>
        <!-- <div>STRONA GŁÓWNA</div>
        <div>AKADEMIA</div>
        <div>E-SKLEP</div>
        <div>POlite</div>
        <div>MATERIAŁY MARKETINGOWE</div>
        <div>INFORMACJE O PRODUKTACH</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { userService } from "../services/user.service.js";
import { getAPIUrl } from "../helpers";

export default {
  name: "Menu",
  props: {
    languageChanged: Function,
  },
  data() {
    return {
      languages: [
        { name: "PL", code: "pl" },
        { name: "EN", code: "en" },
        { name: "FR", code: "fr" },
        { name: "DE", code: "de" },
        { name: "IT", code: "it" },
      ],
      selectedLanguage: "pl",
      env: "",
    };
  },
  mounted: function() {
    this.selectedLanguage = userService.getLang();

    const url = getAPIUrl("env");
    const envs = ["dev", "test", "prod"];

    envs.forEach((e) => {
      if (url.includes(e)) this.env = e;
    });
  },
  methods: {
    // onLanguageChanged(event){
    //   this.languageChanged(event.value);
    // }
    toMyAccount() {
      window.location.href = "https://portal.vetrex.eu/my-account/";
    },
    logOut() {
      let thisVue = this;
      userService.logOut().then((response) => {
        if (response.isSuccess) thisVue.toMyAccount();
        else
          this.$toast.add({
            severity: "error",
            summary: response.statusMessage,
            detail: "",
          });
      });
    },
  },
  watch: {
    "$store.state.lang": function() {
      this.selectedLanguage = this.$store.state.lang;
    },
  },
};
</script>
