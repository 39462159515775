<template>
  <div
    style="background: #fff;width:584px;min-height:98vh;position:fixed;right:20px;z-index:999;box-shadow: -9px -9px 34px #00000039;"
    :style="toTop"
  >
    <div class="row" style="margin:0">
      <div
        class="col-xs-6 extraProductsHeader_item"
        v-bind:class="tab === 0 ? 'active' : ''"
        @click="setTab(0)"
      >
        {{ $t("strAddedAccessories") }}
      </div>
      <div
        class="col-xs-6 extraProductsHeader_item"
        v-bind:class="tab === 1 ? 'active' : ''"
        @click="setTab(1)"
      >
        {{ $t("strAccessories") }}
      </div>
    </div>
    <div class="col-xs-2" style="padding-left:5%">
      <img
        src="@/assets/icons/arrow.svg"
        alt=""
        style="cursor: pointer; width: 12px;margin-left: 1.5rem;"
        @click="hide()"
      />
    </div>

    <div v-if="tab === 0">
      <div class="row" style="margin-top:20px">
        <div
          class="col-xs-10"
          style="padding-right:10%;text-align:right;padding-bottom:1rem"
        >
          <span style="margin-right:1.5rem">{{ $t("strName") }}</span>
          <InputText
            id="serachExtraProduct"
            class="offer--input"
            type="text"
            style="max-width:50%"
            v-model="searchTextSelected"
            @keyup="searchSelectedAccessories($event.target.value)"
          />
          <span
            style="cursor:pointer;margin-left:1rem"
            @click="clearSelectedSearch()"
            >&#10005;</span
          >
        </div>
      </div>
      <div
        class="row"
        style="margin:0;overflow:auto;overflow-x:hidden;height:70vh;"
        v-if="
          accessories !== undefined &&
            accessories !== null &&
            accessories.length !== undefined
        "
      >
        <ExtraProductsItem
          v-bind:accessory="item"
          v-for="item in selectedAccesoriesToDisplay"
          :key="item.id"
          @accessoryDeleted="accessoryDeleted"
          @checkCountable="checkCountable"
        />
        <div
          class="col-xs-12"
          v-if="selectedAccesoriesToDisplay.length === 0"
          style="margin-top:5rem;padding-right:7%;text-align: center"
        >
          {{ $t("strAccessoriesEmpty") }}
        </div>
      </div>
      <div class="row" v-else>
        <div
          class="col-xs-12"
          style="margin-top:5rem;padding-right:7%;text-align: center"
        >
          {{ $t("strAccessoriesEmpty") }}
        </div>
      </div>
      <!-- <div style="padding-top:70px;padding-bottom:70px">
                <div style="display:flex;font-weight:bold;font-size:16px;justify-content:space-between;margin:0 10%;border-top:2px solid #000;padding-top:10px">
                    <div>{{ $t('strTotal') }}:</div>
                    <div>{{amount}} PLN</div>
                </div>
            </div> -->
    </div>

    <div v-if="tab === 1">
      <div class="row" style="margin-top:20px">
        <div class="col-xs-10" style="padding-right:10%;text-align:right">
          <span style="margin-right:1.5rem">{{ $t("strName") }}</span>
          <InputText
            id="serachExtraProductAvailable"
            class="offer--input"
            type="text"
            style="max-width:50%"
            v-model="searchText"
            @keyup="searchAccessories()"
          />
          <span style="cursor:pointer;margin-left:1rem" @click="clearSearch()"
            >&#10005;</span
          >
        </div>
      </div>
      <div
        id="ExtraProductsItemAvailable"
        class="row"
        :style="extraProductsItemAvailable"
        v-if="
          tab === 1 &&
            availableAccessories !== undefined &&
            availableAccessories !== null &&
            availableAccessories.length !== undefined
        "
        @scroll="onScrollItems()"
      >
        <ExtraProductsItemAvailable
          @newAccessory="addAccessory"
          v-bind:accessory="item"
          v-for="(item, index) in accessoriesToDisplay"
          :key="index"
        />
        <div
          class="col-xs-12"
          v-if="accessoriesToDisplay.length === 0"
          style="margin-top:5rem;padding-right:7%;text-align: center"
        >
          {{ $t("strAccessoriesEmpty") }}
        </div>
      </div>
      <div class="row" :style="extraProductsItemAvailable" v-else>
        <div
          class="col-xs-12"
          style="margin-top:5rem;padding-right:7%;text-align: center"
        >
          {{ $t("strAccessoriesEmpty") }}
        </div>
      </div>
      <!-- <div class="row" style="margin:0" v-if="accessoriesToDisplay === null || accessoriesToDisplay.length === 0">
                <div  class="col-xs-12" style="margin-top:5rem;padding-right:7%;text-align: center">
                    {{ $t('strAccessoriesEmpty') }}
                </div>
            </div> -->
      <div style="text-align:center;padding-top:2rem">
        {{ accessoriesToDisplay.length }} {{ $t("strFrom") }}
        {{
          searchText !== "" && searchText.length > 1
            ? searchDataLength
            : availableAccessories.length
        }}
      </div>
      <div
        class="row"
        style="margin:0"
        v-if="tab === 1 && availableAccessories === null"
      >
        <div
          class="col-xs-12"
          style="margin-top:5rem;padding-right:7%;text-align: center"
        >
          {{ $t("strAccessoriesLoading") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExtraProductsItem from "@/components/offers/ExtraProductsItem";
import ExtraProductsItemAvailable from "@/components/offers/ExtraProductsItemAvailable";
import { orderService } from "../../services/order.service.js";

export default {
  props: {
    accessories: Array,
    variantId: Number,
    technologyId: Number,
  },
  name: "ExtraProducts",
  data() {
    return {
      tab: 1,
      amount: 0,
      loadingIdTechnology: 0,
      availableAccessories: [],
      accessoriesToDisplay: [],
      selectedAccesoriesToDisplay: [],
      page: 0,
      perPage: 20,
      visible: true,
      searchTimeout: null,
      searchText: "",
      searchTimeoutSelected: null,
      searchTextSelected: "",
      searchDataLength: 0,
    };
  },
  methods: {
    hide: function() {
      this.$emit("hideExtraProducts");
    },
    setTab: function(tab) {
      this.tab = tab;
    },
    loadAvailableAccessories: function(idTechnology) {
      if (this.loadingIdTechnology !== 0) return;
      const thisVue = this;
      this.loadingIdTechnology = idTechnology;
      orderService.getVariantAllAccessories(idTechnology).then((response) => {
        thisVue.availableAccessories = response.data;
        thisVue.loadingIdTechnology = 0;
        thisVue.setPage();
        if (thisVue.accessories)
          thisVue.selectedAccesoriesToDisplay = [...thisVue.accessories];
        else thisVue.selectedAccesoriesToDisplay = [];
      });
    },
    addAccessory(item) {
      const thisVue = this;
      orderService
        .addVariantAccessory(
          this.variantId,
          item.item.id,
          item.isKit,
          item.quantity,
          item.size
        )
        .then((response) => {
          //thisVue.accessories.push(response.data);
          thisVue.$emit("accessoryAdded", response.data);
          thisVue.$parent.recalcVariantt();
          thisVue.$toast.add({
            severity: "success",
            summary: this.$t("strAccessories"),
            detail: this.$t("strAccessoryAdded"),
            life: 3000,
          });
          thisVue.loadAvailableAccessories(49);
          thisVue.setPage();
        });
    },
    accessoryDeleted(id) {
      this.$emit("accessoryDeleted", id);
      this.loadAvailableAccessories(49);
    },
    handleScroll() {
      this.visible = this.inViewport();
    },
    inViewport: function() {
      let el = document.getElementById("header");
      let r, html;
      if (!el || 1 !== el.nodeType) {
        return false;
      }
      html = document.documentElement;
      r = el.getBoundingClientRect();
      return (
        !!r &&
        r.bottom >= 0 &&
        r.right >= 0 &&
        r.top <= html.clientHeight &&
        r.left <= html.clientWidth
      );
    },
    setPage: function() {
      let data;
      let s = this.page * this.perPage;
      let e = this.perPage * this.page + this.perPage;
      if (this.searchText !== "" && this.searchText.length > 1) {
        const stringSearch = this.searchText.trim().toLowerCase();
        let terms = stringSearch.split("*");
        terms = terms.filter((item) => item);
        let filterData = this.availableAccessories.filter((x) =>
          terms.every((d) =>
            x.name
              .trim()
              .toLowerCase()
              .includes(d.trim())
          )
        );
        this.searchDataLength = filterData.length;
        filterData = filterData.slice(s, e);
        data = filterData;
      } else data = this.availableAccessories.slice(s, e);
      this.accessoriesToDisplay = [...this.accessoriesToDisplay, ...data];
      this.page++;
    },
    onScrollItems: function() {
      let el = document.getElementById("ExtraProductsItemAvailable");
      let y = el.scrollTop;
      let percent = Math.round((y / el.scrollHeight) * 100);
      if (percent > 70) this.setPage();
    },
    searchAccessories() {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.page = 0;
        this.searchDataLength = 0;
        this.accessoriesToDisplay = [];
        // if(val !== '' && val.length > 1){
        //     // let filterData = this.availableAccessories.filter(x => x.name.toLowerCase().includes(val.toLowerCase()))
        //     // this.searchDataLength = filterData.length;
        //     // filterData = filterData.slice(0,20)
        //     // this.accessoriesToDisplay = [...filterData];
        //     this.accessoriesToDisplay = []
        // }else{
        //     this.accessoriesToDisplay = []
        // }
        // // console.log(this.accessoriesToDisplay)
        // // console.log(this.accessoriesToDisplay.length);
        this.setPage();
      }, 700);
    },
    searchSelectedAccessories(val) {
      clearTimeout(this.searchTimeoutSelected);
      this.searchTimeoutSelected = setTimeout(() => {
        if (val !== "") {
          const stringSearch = val.trim().toLowerCase();
          let terms = stringSearch.split("*");
          terms = terms.filter((item) => item);
          let filterData = this.accessories.filter((x) =>
            terms.every((d) =>
              x.nameAccessory
                .trim()
                .toLowerCase()
                .includes(d.trim())
            )
          );
          this.selectedAccesoriesToDisplay = [...filterData];
        } else {
          this.selectedAccesoriesToDisplay = [...this.accessories];
        }
      }, 500);
    },
    clearSearch() {
      this.searchText = "";
      this.accessoriesToDisplay = [];
      this.page = 0;
      this.setPage();
    },
    clearSelectedSearch() {
      this.searchTextSelected = "";
      this.selectedAccesoriesToDisplay = [...this.accessories];
    },
    checkCountable(idElement) {
      return this.availableAccessories.find((a) => a.id === idElement)
        .isCountable;
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    toTop() {
      //"top": this.visible ? '220px' : '10px'
      return {
        top: "10px",
      };
    },
    extraProductsItemAvailable() {
      return {
        margin: 0,
        overflow: "auto",
        "overflow-x": "hidden",
        height: this.visible ? "65vh" : "75vh",
      };
    },
  },
  components: {
    ExtraProductsItem,
    ExtraProductsItemAvailable,
  },
  watch: {
    technologyId: function(newValue) {
      this.loadAvailableAccessories(newValue);
    },
  },
  mounted: function mounted() {
    this.loadAvailableAccessories(49);
  },
};
</script>

<style></style>
