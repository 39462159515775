import { handleResponse, getAPIUrl, getSession } from '../helpers';

export const configuratorService = {
    getTechnologies,
    getSchema
};


function getTechnologies() {
    return new Promise((resolve) => {
        getSession().then(async session => {
            console.log('session3:');
            console.log(session);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: `${session.guid}`, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_tiles'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}

function getSchema(idTechnology) {
    return new Promise((resolve) => {
        getSession().then(async session => {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid: session.guid, idTechnology: idTechnology, language: session.lang })
            };
           
            return fetch(getAPIUrl('get_schema'), requestOptions)
                .then(handleResponse)
                .then(response => {
                    resolve(response);
                });
        });
    });
}





