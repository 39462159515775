<template>
    <div class="container">
        <ActionsMenu />
        <div class="row">
            <div
                class="col-12"
                style="display:flex;margin-bottom:1.5rem;justify-content:right"
            >
                <VButton
                    type="button"
                    class="btn-outline-red"
                    style="border: 2px solid #68bab5; color: #68bab5"
                    @click="add"
                    strKey="strAddPosition"
                    roleKey="adminAddEmptyPosition"
                />
            </div>
            <div class="col-12">
                <DataTable
                        :value="list"
                        :paginator="true"
                        :rows="50"
                        :loading="loading"
                        :filters="filters"
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        dataKey="id"
                        class="p-datatable-responsive-demo"
                        sortField="lastUpdate"
                        :sortOrder="-1"
                    >
                    <template #empty> {{ $t("strNoData") }} </template>
                    <template #loading> {{ $t("strLoading") }} </template>
                    <Column field="name" :header="$t('strName')" sortable>
                        <template #body="slotProps">
                            <span class="p-column-title">{{ $t("strName") }}</span>
                            <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                                {{ slotProps.data.name }}
                            </div>
                        </template>
                        <template #filter>
                            <InputText
                                style="max-width: 100%"
                                type="text"
                                v-model="filters['name']"
                                class="p-column-filter"
                                placeholder=""
                            />
                        </template>
                    </Column>
                    <Column field="priceBuy" :header="$t('strBuyNetto')" sortable>
                        <template #body="slotProps">
                            <span class="p-column-title">{{ $t("strBuyNetto") }}</span>
                            <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                                {{ slotProps.data.priceBuy }}
                            </div>
                        </template>
                        <template #filter>
                            <InputText
                                style="max-width: 100%"
                                type="text"
                                v-model="filters['priceBuy']"
                                class="p-column-filter"
                                placeholder=""
                            />
                        </template>
                    </Column>
                    <Column field="priceSell" :header="$t('strSellNetto')" sortable>
                        <template #body="slotProps">
                            <span class="p-column-title">{{ $t("strSellNetto") }}</span>
                            <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                                {{ slotProps.data.priceSell }}
                            </div>
                        </template>
                        <template #filter>
                            <InputText
                                style="max-width: 100%"
                                type="text"
                                v-model="filters['priceSell']"
                                class="p-column-filter"
                                placeholder=""
                            />
                        </template>
                    </Column>
                    <Column field="quantity" :header="$t('strAccessoryQuantity')" sortable>
                        <template #body="slotProps">
                            <span class="p-column-title">{{ $t("strAccessoryQuantity") }}</span>
                            <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                                {{ slotProps.data.quantity }}
                            </div>
                        </template>
                        <template #filter>
                            <InputText
                                style="max-width: 100%"
                                type="text"
                                v-model="filters['quantity']"
                                class="p-column-filter"
                                placeholder=""
                            />
                        </template>
                    </Column>
                    <Column :header="$t('StrAction')" >
                        <template #filter>
                            <img
                                src="@/assets/icons/vetrex-ikony/no_filter_outlined_32.svg"
                                alt="clear"
                                @click="clearFilters()"
                                style="max-width: 24px; cursor: pointer"
                            />
                        </template>
                        <template #body="row">
                            <img
                                src="@/assets/icons/edycja.svg"
                                alt=""
                                style="cursor: pointer; width: 20px; margin-right: 1.25rem"
                                @click="editPosition(row.data)"
                            />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <Dialog
            widgetVar="process_msg"
            id="process_msg"
            :modal="true"
            :visible.sync="displayDialog"
            :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        >
            <div style="padding: 1rem 2.5rem; display: flex; flex-flow: column; max-width: 500px;">
                <div class="col-lg-12" style="padding: 0">
                    <div class="row" style="margin: 0">
                        <div class="col-xs-12" style="margin-bottom: 2rem;">
                            <div>{{ $t("strName") }}</div>
                            <div>
                                <InputText type="text" class="offer--input" style="width: 100%" v-model="name" />
                            </div>
                        </div>
                        <div class="col-xs-12 mb-1">
                            <div>{{ $t("strBuyNetto") }}</div>
                            <div>
                                <InputNumber showButtons style="width: 100%" v-model="priceBuy" />
                            </div>
                        </div>
                        <div class="col-xs-12 mb-1">
                            <div>{{ $t("strSellNetto") }}</div>
                            <div>
                                <InputNumber showButtons style="width: 100%" v-model="priceSell" />
                            </div>
                        </div>
                        <div class="col-xs-12 mb-1">
                            <div>{{ $t("strCustomerNotes") }}</div>
                            <div>
                                <Textarea v-model="notes" rows="5" style="width: 100%;" />
                            </div>
                        </div>
                        <div class="col-xs-12 mb-1">
                            <div>{{ $t("strAssemblyActive") }}</div>
                            <div>
                                <InputSwitch style="display:block" v-model="isActive" />
                            </div>
                        </div>
                        <div
                            class="col-md-12 offer-footer toRight d-flex"
                            style="padding:0;margin-top: 2.5rem;"
                        >
                            <VButton
                                type="button"
                                class="btn btn-outline-red mr-2"
                                style="border: 2px solid #68bab5; color: #68bab5"
                                @click="closeDialog"
                                strKey="strCancel"
                            />

                            <VButton
                                type="button"
                                class="btn-red"
                                style="background: #68bab5; border: 2px solid #68bab5"
                                @click="save"
                                strKey="strSave"
                            />
                        </div>

                    </div>
                </div>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { adminService } from "../../services/admin.service";
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";
export default {
    components: { ActionsMenu, VButton },
    name: "EmptyPositionAdmin",
    data() {
        return {
            loading: true,
            list: [],
            filters: {},
            displayDialog: false,
            name: '',
            quantity: 1,
            priceBuy: 0,
            priceSell: 0,
            notes: '',
            id: 0,
            isActive: true
        };
    },
    mounted: function mounted() {
        this.loadList();
    },
    methods: {
        loadList() {
            this.loading = true;
            adminService.getEmptyPositions().then((response) => {
                this.list = response.data;
                this.loading = false;
            });
        },
        add() {
            this.clearForm()
            this.displayDialog = true
        },
        editPosition(item) {
            this.clearForm()
            this.displayDialog = true;
            this.name = item.name
            this.quantity = 1;
            this.priceBuy = item.priceBuy
            this.priceSell = item.priceSell
            this.notes = item.notes
            this.id = item.id
        },
        save() {
            const {id} = this;
            if(id === 0) this.addPosition()
            else this.updatePosition(id)

        },
        addPosition(){
            const {name, priceBuy, priceSell, quantity, notes, isActive} = this;
            adminService.addEmptyPosition(
                name, priceBuy, priceSell, quantity, notes, isActive
            ).then((response)=> {
                if(response.isSuccess){
                    this.loadList()
                    this.closeDialog()
                    this.$toast.add({
                        severity: "success",
                        summary: this.$t("strEmptyItem"),
                        detail: this.$t("strEmptyPositionAdded"),
                        life: 3000,
                    });
                }else{
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("strEmptyItem"),
                        detail: response.statusMessage,
                    });
                }
            })
        },
        updatePosition(id) {
            const {name, priceBuy, priceSell, quantity, notes, isActive} = this;
            adminService.updateEmptyPosition(
                id, name, priceBuy, priceSell, quantity, notes, isActive
            ).then((response)=> {
                if(response.isSuccess){
                    this.loadList()
                    this.closeDialog()
                    this.$toast.add({
                        severity: "success",
                        summary: this.$t("strEmptyItem"),
                        detail: this.$t("strEmptyPositionAdded"),
                        life: 3000,
                    });
                }else{
                    this.$toast.add({
                        severity: "error",
                        summary: this.$t("strEmptyItem"),
                        detail: response.statusMessage,
                    });
                }
            })
        },
        closeDialog(){
            this.displayDialog = false
            this.clearForm()
        },
        clearForm() {
            this.name = ''
            this.quantity = 1;
            this.priceBuy = 0
            this.priceSell = 0
            this.notes = ''
            this.id = 0
        }
    }
}
</script>
