<template>
  <span>{{ info.state }}</span>
</template>

<script>
export default {
  props: ["info", "group", "item"],
  data() {
    return {};
  },
};
</script>

<style>
</style>