import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true,
  state: {
    lang: '',
    roles: [],
    kindRole: null
  },
  mutations: {
    setLang: (state, newLang) => (state.lang = newLang),
    setRoles: (state, roles) => (state.roles = [...roles]),
    setKindRole: (state, role) => (state.kindRole = role),
  },
  getters: {
    getRoles(state) {
      return state.roles;
    },
    getKindRole(state) {
      return state.kindRole;
    }
  }
})

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Calendar from 'primevue/calendar';
import Textarea from 'primevue/textarea';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Carousel from 'primevue/carousel';
import Dialog from 'primevue/dialog';
import Panel from 'primevue/panel';
import OverlayPanel from 'primevue/overlaypanel';
import InputNumber from 'primevue/inputnumber';
import ToastService from 'primevue/toastservice';
//import ConfirmationService from 'primevue/confirmationservice';
import Toast from 'primevue/toast';
import SelectButton from 'primevue/selectbutton';
import RadioButton from 'primevue/radiobutton';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Menu from 'primevue/menu';
// import Tooltip from 'primevue/tooltip';

Vue.component('DataTable', DataTable );
Vue.component('Column', Column );
Vue.component('InputText', InputText );
Vue.component('Calendar', Calendar );
Vue.component('Textarea', Textarea );
Vue.component('Dropdown', Dropdown);
Vue.component('Checkbox', Checkbox);

Vue.component('Accordion', Accordion );
Vue.component('AccordionTab', AccordionTab );
Vue.component('Carousel', Carousel );
Vue.component('Dialog', Dialog );
Vue.component('Panel', Panel );
Vue.component('OverlayPanel', OverlayPanel );
Vue.component('InputNumber', InputNumber );
Vue.use(ToastService);
Vue.component('Toast', Toast);
Vue.component('SelectButton', SelectButton);
Vue.component('RadioButton', RadioButton);
Vue.component('ProgressSpinner', ProgressSpinner);
Vue.component('InputSwitch', InputSwitch);
Vue.component('Menu', Menu);
// Vue.component('Tooltip', Tooltip);
//Vue.use(ConfirmationService);

import moment from 'moment';

import Vuelidate from 'vuelidate';
Vue.use(Vuelidate);

Vue.filter('formatDate', function (value) {
  if (value) {
      return moment(String(value)).toDate().toLocaleDateString();//.format('YYYY-MM-DD')
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
      return moment(String(value)).toDate().toLocaleString()//.format('YYYY-MM-DD hh:mm')
  }
});

Vue.filter('formathhmm', function (value) {
  if (value) {
      return moment(String(value)).format('hh:mm')
  }
});

 const messages = {
  en: {
    message: {
      hello: 'hello world'
    }
  },
  pl: {
    message: {
      hello: 'Witaj!!!'
    }
  }
}
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'ja', // set locale
  messages, // set locale messages
  silentTranslationWarn: true,
})


let _oldFetch = fetch; 
window.fetch = function(){
    let fetchStart = new Event( 'fetchStart', { 'view': document, 'bubbles': true, 'cancelable': false } );
    let fetchEnd = new Event( 'fetchEnd', { 'view': document, 'bubbles': true, 'cancelable': false } );
    let fetchCall = _oldFetch.apply(this, arguments);
    document.dispatchEvent(fetchStart);
    fetchCall.then(function(){
        document.dispatchEvent(fetchEnd);
    }).catch(function(){
        document.dispatchEvent(fetchEnd);
    });
    return fetchCall;
};


let loaders = 0;
document.addEventListener('fetchStart', function() {
  loaders++;
  if(loaders === 1)
    document.getElementById('vetrexLoader').style.display = 'flex'
});

document.addEventListener('fetchEnd', function() {
  loaders--;
  if(loaders === 0)
    document.getElementById('vetrexLoader').style.display = 'none'
});

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
