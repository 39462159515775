<template>
  <div class="container">
    <div
      class="row"
      v-if="items !== undefined && items !== null && items.length !== 0"
    >
      <Carousel
        :value="items"
        class="col-12"
        :numVisible="maxItems"
        :numScroll="1"
      >
        <template #item="slotProps">
          <div class="product-item" @click="select(slotProps.data)">
            <div class="product-item-content">
              <div class="p-mb-3">
                <img
                  src="@/img/tree_item.png"
                  :alt="slotProps.data.name"
                  class="product-image"
                />
              </div>
              <div>
                <h4 class="p-mb-1">{{ slotProps.data.name }}</h4>
              </div>
            </div>
          </div>
        </template>
      </Carousel>
    </div>
    <div
      class="row"
      v-if="child !== undefined && child !== null && child.id !== undefined"
    >
      <div class="col-12">
        <ProductHierarchySelect
          :level="level + 1"
          @selectedItem="selectedItemClicked"
          :parentId="child.id"
          v-if="level < 8"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import { orderService } from "../../services/order.service.js";
import { productHierarchyService } from "../../services/product-hierarchy.service.js";
import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";

export default {
  name: "ProductHierarchySelect",
  props: {
    level: Number,
    parentId: Number,
  },
  data() {
    return {
      items: [],
      items2: [],
      items3: [],
      items4: [],
      child: {},
      maxItems: 1,
    };
  },
  methods: {
    selectedItemClicked: function (item) {
      if (item !== undefined && item !== null && item.childrenCount === 0)
        this.$emit("selectedItem", item);
    },
    select: function (item) {
      if (item.childrenCount === 0) {
        this.$emit("selectedItem", item);
        this.child = null;
      } else {
        this.child = item;
      }
    },
    loadItems: function (parentId) {
      this.child = null;
      if (parentId === undefined && this.level > 1) {
        this.items = [];
        return;
      }

      var thisVue = this;
      productHierarchyService.getLevelItems(parentId).then((response) => {
        thisVue.items = response;
        if (
          thisVue.items === undefined ||
          thisVue.items === null ||
          thisVue.items.length < 2
        ) {
          thisVue.maxItems = 1;
        } else {
          thisVue.maxItems =
            thisVue.items.length > 3 ? 4 : thisVue.items.length;
        }

        if (
          thisVue.items !== undefined &&
          thisVue.items !== null &&
          thisVue.items.length === 1
        ) {
          thisVue.select(thisVue.items[0]);
        }
      });
    },
  },
  watch: {
    $props: {
      handler() {
        this.loadItems(this.parentId);
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    ProductHierarchySelect,
  },
};
</script>

