var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"background":"#fff","width":"584px","min-height":"98vh","position":"fixed","right":"20px","z-index":"999","box-shadow":"-9px -9px 34px #00000039"},style:(_vm.toTop)},[_c('div',{staticClass:"row",staticStyle:{"margin":"0"}},[_c('div',{staticClass:"col-xs-6 extraProductsHeader_item",class:_vm.tab === 0 ? 'active' : '',on:{"click":function($event){return _vm.setTab(0)}}},[_vm._v(" "+_vm._s(_vm.$t("strAddedAccessories"))+" ")]),_c('div',{staticClass:"col-xs-6 extraProductsHeader_item",class:_vm.tab === 1 ? 'active' : '',on:{"click":function($event){return _vm.setTab(1)}}},[_vm._v(" "+_vm._s(_vm.$t("strAccessories"))+" ")])]),_c('div',{staticClass:"col-xs-2",staticStyle:{"padding-left":"5%"}},[_c('img',{staticStyle:{"cursor":"pointer","width":"12px","margin-left":"1.5rem"},attrs:{"src":require("@/assets/icons/arrow.svg"),"alt":""},on:{"click":function($event){return _vm.hide()}}})]),(_vm.tab === 0)?_c('div',[_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-xs-10",staticStyle:{"padding-right":"10%","text-align":"right","padding-bottom":"1rem"}},[_c('span',{staticStyle:{"margin-right":"1.5rem"}},[_vm._v(_vm._s(_vm.$t("strName")))]),_c('InputText',{staticClass:"offer--input",staticStyle:{"max-width":"50%"},attrs:{"id":"serachExtraProduct","type":"text"},on:{"keyup":function($event){return _vm.searchSelectedAccessories($event.target.value)}},model:{value:(_vm.searchTextSelected),callback:function ($$v) {_vm.searchTextSelected=$$v},expression:"searchTextSelected"}}),_c('span',{staticStyle:{"cursor":"pointer","margin-left":"1rem"},on:{"click":function($event){return _vm.clearSelectedSearch()}}},[_vm._v("✕")])],1)]),(
        _vm.accessories !== undefined &&
          _vm.accessories !== null &&
          _vm.accessories.length !== undefined
      )?_c('div',{staticClass:"row",staticStyle:{"margin":"0","overflow":"auto","overflow-x":"hidden","height":"70vh"}},[_vm._l((_vm.selectedAccesoriesToDisplay),function(item){return _c('ExtraProductsItem',{key:item.id,attrs:{"accessory":item},on:{"accessoryDeleted":_vm.accessoryDeleted,"checkCountable":_vm.checkCountable}})}),(_vm.selectedAccesoriesToDisplay.length === 0)?_c('div',{staticClass:"col-xs-12",staticStyle:{"margin-top":"5rem","padding-right":"7%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("strAccessoriesEmpty"))+" ")]):_vm._e()],2):_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12",staticStyle:{"margin-top":"5rem","padding-right":"7%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("strAccessoriesEmpty"))+" ")])])]):_vm._e(),(_vm.tab === 1)?_c('div',[_c('div',{staticClass:"row",staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"col-xs-10",staticStyle:{"padding-right":"10%","text-align":"right"}},[_c('span',{staticStyle:{"margin-right":"1.5rem"}},[_vm._v(_vm._s(_vm.$t("strName")))]),_c('InputText',{staticClass:"offer--input",staticStyle:{"max-width":"50%"},attrs:{"id":"serachExtraProductAvailable","type":"text"},on:{"keyup":function($event){return _vm.searchAccessories()}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}}),_c('span',{staticStyle:{"cursor":"pointer","margin-left":"1rem"},on:{"click":function($event){return _vm.clearSearch()}}},[_vm._v("✕")])],1)]),(
        _vm.tab === 1 &&
          _vm.availableAccessories !== undefined &&
          _vm.availableAccessories !== null &&
          _vm.availableAccessories.length !== undefined
      )?_c('div',{staticClass:"row",style:(_vm.extraProductsItemAvailable),attrs:{"id":"ExtraProductsItemAvailable"},on:{"scroll":function($event){return _vm.onScrollItems()}}},[_vm._l((_vm.accessoriesToDisplay),function(item,index){return _c('ExtraProductsItemAvailable',{key:index,attrs:{"accessory":item},on:{"newAccessory":_vm.addAccessory}})}),(_vm.accessoriesToDisplay.length === 0)?_c('div',{staticClass:"col-xs-12",staticStyle:{"margin-top":"5rem","padding-right":"7%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("strAccessoriesEmpty"))+" ")]):_vm._e()],2):_c('div',{staticClass:"row",style:(_vm.extraProductsItemAvailable)},[_c('div',{staticClass:"col-xs-12",staticStyle:{"margin-top":"5rem","padding-right":"7%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("strAccessoriesEmpty"))+" ")])]),_c('div',{staticStyle:{"text-align":"center","padding-top":"2rem"}},[_vm._v(" "+_vm._s(_vm.accessoriesToDisplay.length)+" "+_vm._s(_vm.$t("strFrom"))+" "+_vm._s(_vm.searchText !== "" && _vm.searchText.length > 1 ? _vm.searchDataLength : _vm.availableAccessories.length)+" ")]),(_vm.tab === 1 && _vm.availableAccessories === null)?_c('div',{staticClass:"row",staticStyle:{"margin":"0"}},[_c('div',{staticClass:"col-xs-12",staticStyle:{"margin-top":"5rem","padding-right":"7%","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.$t("strAccessoriesLoading"))+" ")])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }