<template>
    <div v-if="basket !== undefined && basket !== null && basket.description !== undefined">
        <div class="productDescription" v-html="basket.description"></div>
        <div style="text-align:right;cursor:pointer" @click="toggleDescription()">
            <span v-if="!show">{{$t('strMore')}} +</span>
            <span v-if="show">{{$t('strLess')}} -</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "ProductDescription",
     props: {
        basket: {}
    },
    data() {
        return {
            show: false,
        }
    },
    methods: {
        toggleDescription(){
            this.show = !this.show
            if(this.show){
                let el = document.getElementsByClassName('description-hidden')
                el[0].className = 'description-block'
            }else{
                let el = document.getElementsByClassName('description-block')
                el[0].className = 'description-hidden'
            }
        }
    },
};
</script>