<template>
  <div class="container">
    <ActionsMenu />
    <div class="row">
      <div
        class="col-12"
        style="display:flex;margin-bottom:1.5rem;justify-content:right"
      >
        <VButton
          type="button"
          class="btn-outline-red"
          style="border: 2px solid #68bab5; color: #68bab5"
          @click="add"
          strKey="strAddShop"
          roleKey="adminAddShop"
        />
      </div>
      <div class="col-12">
        <DataTable
          :value="shops"
          :paginator="true"
          :rows="50"
          :filters="filters"
          :loading="loading"
          paginatorTemplate="PrevPageLink PageLinks NextPageLink"
          dataKey="id"
          class="p-datatable-responsive-demo"
          sortField="lastUpdate"
          :sortOrder="-1"
        >
          <template #empty> {{ $t("strShopsNotFound") }} </template>
          <template #loading> {{ $t("strShopsLoading") }} </template>
          <Column field="name" :header="$t('strShopName')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strShopName") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.name }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['name']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="place" :header="$t('strShopPlace')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strShopPlace") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.place }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['place']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="nip" :header="$t('strShopTaxId')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strShopTaxId") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.nip }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['nip']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="phone" :header="$t('strShopPhone')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strShopPhone") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.phone }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['phone']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <Column field="email" :header="$t('strShopEmail')" sortable>
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strShopEmail") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{ slotProps.data.email }}
              </div>
            </template>
            <template #filter>
              <InputText
                style="max-width: 100%"
                type="text"
                v-model="filters['email']"
                class="p-column-filter"
                placeholder=""
              />
            </template>
          </Column>
          <!-- <Column
            field="active"
            :header="$t('strActive')"
            sortable
            filterMatchMode="contains"
          >
            <template #body="slotProps">
              <span class="p-column-title">{{ $t("strActive") }}</span>
              <div style="cursor: default" @dblclick="rowClick(slotProps.data)">
                {{
                  slotProps.data.active ? $t("strActiveYes") : $t("strActiveNo")
                }}
              </div>
            </template>
          </Column> -->
          <Column header="Akcja" v-if="canBeEdited">
            <template #filter>
              <img
                src="@/assets/icons/vetrex-ikony/no_filter_outlined_32.svg"
                alt="clear"
                @click="clearFilters()"
                style="max-width: 24px; cursor: pointer"
              />
            </template>
            <template #body="row">
              <VRole roleKey="adminCompanyDelete">
                <img
                  src="@/assets/icons/vetrex-ikony/trash_outlined_32.svg"
                  alt=""
                  style="cursor: pointer; width: 22px; margin-right: 1.75rem"
                  @click="deleteShop(row.data)"
                />
              </VRole>
              <VRole roleKey="adminCompanyEdit">
                <img
                  src="@/assets/icons/edycja.svg"
                  alt=""
                  style="cursor: pointer; width: 20px; margin-right: 1.25rem"
                  @click="edit(row.data)"
                />
              </VRole>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <Dialog
      :header="shopEditHeader"
      widgetVar="process_msg"
      id="process_msg"
      :visible.sync="displayEditShop"
      :style="{ width: '70%' }"
      :modal="true"
      @show="editShopOpened"
    >
      <shop-edit
        ref="customerEdit"
        v-on:shop-added="shopAdded"
        v-on:shop-updated="shopUpdated"
        :cancel="cancelEditShop"
      ></shop-edit>
    </Dialog>

    <Dialog
      widgetVar="process_msg"
      id="process_msg"
      :modal="true"
      :visible.sync="displayDeleteDialog"
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    >
      <div
        style="
          display: flex;
          padding: 5rem;
          justify-content: center;
          font-size: 20px;
        "
      >
        {{ $t("qnShopDelete") }}
      </div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 2rem 1rem 2rem;
        "
      >
        <VButton
          class="btn-red"
          @click="displayDeleteDialog = false"
          strKey="strCancel"
        />

        <VButton
          class="btn-outline-red"
          @click="deletingConfirmed()"
          strKey="strDelete"
        />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { adminService } from "../../services/admin.service";
import { required, minLength, email } from "vuelidate/lib/validators";
import ShopEdit from "@/components/admin/ShopEdit";
import ActionsMenu from "@/components/ActionsMenu";
import VButton from "../global/VButton.vue";
import VRole from "../global/VRole.vue";
// import { userService } from "../../services/user.service";
// import UserRole from "../../enums/UserRole";
export default {
  name: "Company",
  data() {
    return {
      loading: true,
      filters: {},
      company: {},
      shops: [],
      displayEditShop: false,
      displayDeleteDialog: false,
      shopEditHeader: "",
      canBeEdited: true,
      deletingItem: {},
    };
  },
  validations: {
    company: {
      firstName: { required, minLength: minLength(3) },
      //   secondName: { required, minLength: minLength(3) },
      //   street: { required, minLength: minLength(2) },
      //   zipCode: { required, minLength: minLength(5) },
      //   place: { required, minLength: minLength(2) },
      //   phone1: { required, minLength: minLength(9) },
      email: { required, email },
      //   country: { required },
    },
  },
  methods: {
    loadCompany() {
      // this.canBeEdited = userService.getCurrentUserRoleId() === UserRole.Owner;
      const thisVue = this;
      this.loading = true;
      adminService.getShops().then((response) => {
        thisVue.shops = response.data;
        thisVue.loading = false;
      });
    },
    add() {
      this.displayEditShop = true;
      this.editingItem = { id: 0 };
    },
    edit(item) {
      this.editingItem = { ...item };
      this.editingItemOrg = item;
      this.displayEditShop = true;
    },
    editShopOpened() {
      if (this.editingItem.id === 0) this.$refs.customerEdit.addNew();
      else this.$refs.customerEdit.edit(this.editingItem);
    },
    shopAdded(item) {
      this.shops.push(item);
      this.displayEditShop = false;
      this.$toast.add({
        severity: "success",
        summary: this.$t("strShop"),
        detail: this.$t("strShopAdded"),
        life: 3000,
      });
    },
    shopUpdated(item) {
      Object.assign(this.editingItemOrg, item);
      this.displayEditShop = false;
      this.$toast.add({
        severity: "success",
        summary: this.$t("strShop"),
        detail: this.$t("strShopUpdated"),
        life: 3000,
      });
    },
    cancelEditShop() {
      console.log("cancel");
      this.displayEditShop = false;
    },
    deleteShop(item) {
      this.displayDeleteDialog = true;
      this.deletingItem = item;
    },
    deletingConfirmed() {
      const thisVue = this;
      adminService.deleteShop(this.deletingItem.id).then(() => {
        thisVue.displayDeleteDialog = false;
        const ind = thisVue.shops.findIndex(
          (i) => i.id === thisVue.deletingItem.id
        );
        if (ind >= 0) {
          this.shops.splice(ind, 1);
        }
      });
    },
  },
  mounted: function mounted() {
    this.loadCompany();
  },
  watch: {},
  components: { ActionsMenu, ShopEdit, VButton, VRole },
};
</script>
