<template>
  <div style="padding: 1rem 2.5rem; display: flex; flex-flow: column">
    <form>
      <div class="row" style="display: flex; flex-flow: column">
        <div class="col-lg-9" style="padding: 0">
          <div class="row" style="margin: 0">
            <div class="col-md-6">
              <div class="my-2">
                <label class="offer--input-label" for="name">
                  {{ $t("strShopName") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="name"
                  class="offer--input"
                  type="text"
                  v-model="shop.name"
                  required
                />
                <div
                  v-if="submitted && $v.shop.name.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.name.required">{{
                    $t("strShopFirstNameRequired")
                  }}</span>
                  <span v-if="!$v.shop.name.minLength">{{
                    $t("strShopFirstNameMinLength")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-11"
          style="border-bottom: 1px solid #707070; padding: 0; margin: 15px"
        ></div>
      </div>

      <div class="row">
        <div class="col-lg-9" style="padding: 0">
          <div class="row" style="margin: 0">
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="street">
                  {{ $t("strShopStreet") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="street"
                  class="offer--input"
                  type="text"
                  v-model="shop.street"
                />
                <div
                  v-if="submitted && $v.shop.street.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.street.required">{{
                    $t("strShopStreetRequired")
                  }}</span>
                  <span v-if="!$v.shop.street.minLength">{{
                    $t("strShopStreetMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="city">
                  {{ $t("strShopCity") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="city"
                  class="offer--input"
                  type="text"
                  v-model="shop.place"
                />
                <div
                  v-if="submitted && $v.shop.place.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.place.required">{{
                    $t("strShopPlaceRequired")
                  }}</span>
                  <span v-if="!$v.shop.place.minLength">{{
                    $t("strShopPlaceMinLength")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="margin: 0">
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="zipCode">
                  {{ $t("strShopZipCode") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="zipCode"
                  class="offer--input"
                  type="text"
                  v-model="shop.zipCode"
                  @keypress="validateNumber(event)"
                />
                <div
                  v-if="submitted && $v.shop.zipCode.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.zipCode.required">{{
                    $t("strShopCodeRequired")
                  }}</span>
                  <span v-if="!$v.shop.zipCode.minLength">{{
                    $t("strShopCodeMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="my-2">
                <label class="offer--input-label" for="idCountry">
                  {{ $t("strShopCountry") }}
                  <span style="color: red">*</span>
                </label>
                <!-- <InputText id="idCountry" class="offer--input" type="text" v-model="shop.idCountry" required /> -->

                <Dropdown
                  id="idCountry"
                  class="offer--input"
                  v-model="shop.idCountry"
                  :options="countries"
                  optionLabel="countryName"
                  optionValue="id"
                />
                <div
                  v-if="submitted && $v.shop.idCountry.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.idCountry.required">{{
                    $t("strShopCountryRequired")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-11"
          style="border-bottom: 1px solid #707070; padding: 0; margin: 15px"
        ></div>
      </div>

      <div class="row">
        <div class="col-lg-11" style="padding: 0">
          <div class="row" style="margin: 0">
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="taxid">
                  {{ $t("strShopTaxId") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="taxId"
                  class="offer--input"
                  type="text"
                  v-model="shop.nip"
                />
                <div
                  v-if="submitted && $v.shop.nip.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.nip.required">{{
                    $t("strShopTaxIdRequired")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="city">
                  {{ $t("strShopPhone") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="city"
                  class="offer--input"
                  type="tel"
                  v-model="shop.phone"
                  @keypress="validateNumber(event)"
                />
                <div
                  v-if="submitted && $v.shop.phone.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.phone.required">{{
                    $t("strShopPhoneRequired")
                  }}</span>
                  <span v-if="!$v.shop.phone.minLength">{{
                    $t("strShopPhoneMinLength")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="code">
                  {{ $t("strShopEmail") }}
                  <span style="color: red">*</span>
                </label>
                <InputText
                  id="code"
                  class="offer--input"
                  type="text"
                  v-model="shop.email"
                />
                <div
                  v-if="submitted && $v.shop.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.email.required">{{
                    $t("strShopEmailRequired")
                  }}</span>
                  <span v-if="!$v.shop.email.email">{{
                    $t("strShopEmailFormat")
                  }}</span>
                </div>
              </div>
            </div>
            <div
              class="col-lg-12"
              style="border-bottom: 1px solid #707070; padding: 0; margin: 15px"
            ></div>
          </div>

          <div class="row" style="margin: 0">
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="deliveryDay">
                  {{ $t("strShopDeliveryDay") }}
                </label>
                <Dropdown
                  id="deliveryDay"
                  class="offer--input"
                  v-model="shop.idDeliveryDay"
                  :options="deliveryDays"
                  optionLabel="name"
                  optionValue="id"
                />
                <div
                  v-if="submitted && $v.shop.idDeliveryDay.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.shop.idDeliveryDay.required">{{
                    $t("strShopDeliveryDayRequired")
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="bank">
                  {{ $t("strBankNumber") }}
                </label>
                <InputText
                  id="bank"
                  class="offer--input"
                  type="text"
                  v-model="shop.bank"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="www">
                  {{ $t("strWww") }}
                </label>
                <InputText
                  id="www"
                  class="offer--input"
                  type="text"
                  v-model="shop.www"
                />
              </div>
            </div>
            <div
              class="col-lg-12"
              style="border-bottom: 1px solid #707070; padding: 0; margin: 15px"
            ></div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-11" style="padding: 0">
          <div class="row" style="margin: 0">
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="logo">
                  {{ $t("strlogoSalon") }}
                </label>
                <br />
                <img
                  v-if="
                    shop !== undefined &&
                      shop.logoSalon !== undefined &&
                      shop.logoSalon !== null
                  "
                  id="logo"
                  class="offer--input"
                  v-bind:src="'data:image/jpeg;base64,' + shop.logoSalon"
                />
                <input
                  type="file"
                  ref="inputLogo"
                  style="display: none"
                  accept="image/jpeg"
                  @change="loadLogoFromFile"
                />
                <VButton
                  class="btn-outline-red mr-2"
                  type="button"
                  @click="$refs.inputLogo.click()"
                  strKey="strPhotoSelect"
                />

                <VButton
                  v-if="shop.logoSalon !== null && shop.logoSalon !== ''"
                  type="button"
                  class="btn-outline-red mr-2"
                  @click="deleteImage(true)"
                  strKey="strDelete"
                />
              </div>
            </div>
            <div class="col-lg-4">
              <div class="my-2">
                <label class="offer--input-label" for="graphic">
                  {{ $t("strGraphic") }}
                </label>
                <br />
                <img
                  v-if="
                    shop !== undefined &&
                      shop.graphicSalon !== undefined &&
                      shop.graphicSalon !== null
                  "
                  id="graphic"
                  class="offer--input"
                  v-bind:src="'data:image/jpeg;base64,' + shop.graphicSalon"
                />
                <input
                  type="file"
                  ref="inputGraphic"
                  style="display: none"
                  accept="image/jpeg"
                  @change="loadGraphicFromFile"
                />
                <VButton
                  type="button"
                  class="btn-outline-red mr-2"
                  @click="$refs.inputGraphic.click()"
                  strKey="strPhotoSelect"
                />
                <VButton
                  v-if="shop.graphicSalon !== null && shop.graphicSalon !== ''"
                  type="button"
                  class="btn-outline-red mr-2"
                  @click="deleteImage(false)"
                  strKey="strDelete"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 offer-footer toRight d-flex mt-2">
        <VButton
          type="button"
          class="btn-outline-red mr-2"
          style="border: 2px solid #68bab5; color: #68bab5"
          @click="cancel"
          strKey="strCancel"
        />
        <VButton
          type="button"
          class="btn-red"
          style="background: #68bab5; border: 2px solid #68bab5"
          @click="save"
          strKey="strSave"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { adminService } from "../../services/admin.service";
import { required, minLength, email } from "vuelidate/lib/validators";
import VButton from "../global/VButton.vue";
// import { orderService } from "../../services/order.service.js";
// import { productService } from "../../services/product.service.js";
// import VariantItem from "@/components/offers/VariantItem";
// import ProductHierarchySelect from "@/components/product/ProductHierarchySelect";
// import ProductEdit from "@/components/product/ProductEdit";

export default {
  name: "ShopEdit",
  props: ["cancel"],
  data() {
    return {
      isNew: false,
      submitted: false,
      shop: {
        firstName: "",
        secondName: "",
        alias: "",
        street: "",
        zipCode: "",
        place: "",
        phone: "",
        phone2: "",
        fax: "",
        name: "",
        nip: "",
        discount: 0,
        regon: "",
        note: "",
        email: "",
        company: "",
        idCountry: "",
        rodo: 0,
        www: "",
        bank: "",
        logoSalon: "",
        graphic: "",
      },
      countries: [],
      deliveryDays: [],
    };
  },
  validations: {
    shop: {
      name: { required, minLength: minLength(3) },
      street: { required, minLength: minLength(2) },
      zipCode: { required, minLength: minLength(5) },
      place: { required, minLength: minLength(2) },
      phone: { required, minLength: minLength(9) },
      idDeliveryDay: { required },
      email: { required, email },
      nip: { required },
      idCountry: { required },
      // idDeliveryDay: {required }
    },
  },
  methods: {
    addNew: function() {
      this.loadCountries();
      this.loadDeliveryDays();
      this.isNew = true;
      this.shop = {
        firstName: "",
        secondName: "",
        alias: "",
        street: "",
        zipCode: "",
        place: "",
        phone: "",
        phone2: "",
        fax: "",
        name: "",
        nip: "",
        discount: 0,
        regon: "",
        note: "",
        email: "",
        company: "",
        idCountry: 0,
      };
    },
    edit: function(item) {
      this.isNew = false;
      this.shop = item;
      const thisVue = this;
      adminService.getShop(item.id).then((response) => {
        thisVue.shop = response.data[0];
      });
      this.loadCountries();
      this.loadDeliveryDays();
    },
    loadCountries() {
      const thisVue = this;
      adminService.getCountries().then((response) => {
        thisVue.countries = response.data;
      });
    },
    loadDeliveryDays() {
      const thisVue = this;
      adminService.getDeliveryDays().then((response) => {
        thisVue.deliveryDays = response.data;
      });
    },
    save: function() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      //   if(this.selectedCompany === 'isCompany'){
      //     let companyValid = this.validCompany();
      //     if(!companyValid)
      //       return;
      //   }
      if (this.isNew === true) {
        adminService
          .addShop(this.shop)
          .then((response) => {
            if (response.isSuccess === true) {
              this.$emit("shop-added", response.data[0]);
            } else {
              this.$toast.add({
                severity: "error",
                summary: this.$t("strShop"),
                detail: response.statusMessage,
              });
            }
          })
          .catch((error) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("strShop"),
              detail: error,
            });
          });
      } else {
        adminService.updateShop(this.shop).then((response) => {
          if (response.isSuccess === true) {
            this.$emit("shop-updated", response.data[0]);
          }
        });
      }
    },
    loadLogoFromFile(ev) {
      const thisVue = this;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        thisVue.shop.logoSalon = e.target.result.replace(
          "data:image/jpeg;base64,",
          ""
        );
      };
      reader.readAsDataURL(file);
    },
    loadGraphicFromFile(ev) {
      const thisVue = this;
      const file = ev.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        thisVue.shop.graphicSalon = e.target.result.replace(
          "data:image/jpeg;base64,",
          ""
        );
      };
      reader.readAsDataURL(file);
    },
    deleteImage(isLogo = false) {
      if (isLogo) {
        this.shop.logoSalon = "";
      } else {
        this.shop.graphicSalon = "";
      }
    },
  },
  watch: {},
  components: { VButton },
};
</script>
