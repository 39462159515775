<template>
  <div class="col-xs-12" style="margin-top:20px;padding-right:7%">
    <div class="col-md-1"></div>
    <div
      class="col-md-11"
      style="box-shadow: 0px 3px 13px #00000022;min-height:175px;display:flex;align-items:center"
    >
      <div style="display:flex;width:100%">
        <div style="width:140px;">
          <img
            :id="'ExtraProductsItemA_' + accessory.id"
            :src="accessory.graphicURL"
            alt=""
            style="width:100%;height:140px;"
            @click="toggleImg($event, accessory.id)"
          />
          <OverlayPanel
            :ref="'opia_' + accessory.id"
            :appendTo="'#ExtraProductsItemA_' + accessory.id"
            :showCloseIcon="true"
            id="overlay_panel"
            style="width: 350px; height: auto;background: #fff;z-index: 1000;"
          >
            <img
              :src="accessory.graphicURL + '?' + Date.now()"
              style="width: 100%; height: auto"
            />
          </OverlayPanel>
        </div>
        <div
          style="margin-left:19px;flex:1;display: flex;flex-flow: column;justify-content: space-between;"
        >
          <div>
            <div class="col-md-11;padding-left:0">
              <div>
                <span style="font-size:12px;font-weight:bold">{{
                  accessory.name
                }}</span>
              </div>
            </div>
            <!-- <div class="col-xs-6">
                             <span style="font-size:16px;font-weight:bold"></span>
                        </div> -->
          </div>
          <div class="row">
            <div
              class="col-xs-6"
              style="height:100%;display:flex;justify-content:flex-end;flex-flow:column"
            >
              <div v-if="accessory.isCountable === 0">
                <div style="display:flex;flex-flow: column">
                  <label style="font-size: 11px;font-weight: 500;">{{
                    $t("strSize")
                  }}</label>
                  <input
                    type="number"
                    v-model="size"
                    class="offer--input"
                    style="width:95px;padding:.5rem .5rem;font-size:12px"
                  />
                </div>
                <!-- <div style="background:#212429;border-radius:4px;color:#fff;font-size:16px;text-align:center;height:31px;line-height:31px;width:95px;margin-top:.5rem;">
                                    {{ accessory.price}}
                                </div> -->
              </div>
            </div>
            <div class="col-xs-6" style="text-align:right">
              <product-input ref="productInput"></product-input>
              <VButton
                style="border:2px solid #ececec;border-radius: 4px;background:#fff;font-size:16px;width:95px;margin-top:.5rem;min-height:31px;color:#777777"
                @click="addAccesory(accessory)"
                strKey="strAdd"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductInput from "../configurator/ProductInput.vue";
import VButton from "../global/VButton.vue";
export default {
  components: { ProductInput, VButton },
  name: "ExtraProductsItem",
  props: {
    accessory: {},
  },
  data() {
    return {
      size: 1,
    };
  },
  methods: {
    addAccesory(item) {
      //console.log('id:');
      //console.log(this.$refs.productInput);
      //alert(this.$refs.productInput.inputValue);
      this.$emit("newAccessory", {
        item: item,
        quantity: this.$refs.productInput.inputValue,
        size: this.size,
        isKit: item.isKit,
      });
    },
    toggleImg(event, id) {
      const el = this.$refs["opia_" + id];
      el.toggle(event);
    },
  },
};
</script>

<style></style>
